import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  getDeviceOsBreakdown,
  getErrorCrashRateTrend,
  getIncidentTrend,
  getNetworkPerformance,
  getIncidentMonitoringTableView,
  getHealthAndStabilityOverview,
  getHealthAndStabilityBarChart,
  getIncidentMonitoringSlaAnalysis,
  getIncidentMonitoringOpenAndClose,
  getIncidentMonitoringPriorityLevelGraph,
  getIncidentMonitoringOverview,
  getHealthAndStabilityLineChart,
  getUniqueUserTrend,
  getAppCrashReportTrend,
  getBounceRateSTTrend,
  getActiveUserTrend,
  getAvgSessionTime,
  getAppPerformanceScoreCardQuery,
  getNetworkLatencyTrend,
  getAPIResponseTrend,
  getTopSlowestAPI,
  getErrorRateTrend,
  getApdexScore,
  getServerSideCalendarGenRunTimeTrend,
} from '../graphql/queries';
import {
  getIncidentMonitoring,
  getNRMAppPerformanceScoreCard,
  getCanvasAppPerformanceScoreCard,
  getApiCallsTrend,
  getBrowserErrorTrend,
  getPageResponseTrend,
  getDtcAssignmentGroupFilter,
} from '../graphql/applicationPerformanceQueries';
import { constants } from '../constants/Constants';

export const useApplicationPerformance = () => {
  const [deviceOsBreakdownData, setDeviceOsBreakdownData] = useState({});
  const [errorCrashRateTrendData, setErrorCrashRateTrendData] = useState({});
  const [incidentMonitoringTableData, setIncidentMonitoringTableData] =
    useState([]);
  const [healthAndStability, setHealthAndStability] = useState({});
  const [healthAndStabilityLineChart, setHealthAndStabilityLineChart] =
    useState({});
  const [incidentMonitoringOverviewData, setIncidentMonitoringOverviewData] =
    useState({});
  const [healthAndStabilityBarChart, setHealthAndStabilityBarChart] = useState(
    {}
  );
  const [incidentTrendData, setIncidentTrendData] = useState({});
  const [networkPerformanceData, setNetworkPerformanceData] = useState({});
  const [
    incidentMonitoringSlaAnalysisData,
    setIncidentMonitoringSlaAnalysisData,
  ] = useState({});
  const [
    incidentMonitoringOpenAndCloseData,
    setIncidentMonitoringOpenAndCloseData,
  ] = useState({});
  const [
    incidentMonitoringPriorityLevelGraphData,
    setIncidentMonitoringPriorityLevelGraphData,
  ] = useState({});

  const [uniqueUserTrendData, setUniqueUserTrendData] = useState({});
  const [appCrashReportTrendData, setAppCrashReportTrendData] = useState({});
  const [bounceRateSTTrendData, setBounceRateSTTrendData] = useState({});
  const [activeUserTrendData, setActiveUserTrendData] = useState({});
  const [averageSessionTimeData, setAverageSessionTimeData] = useState({});

  const [appPerformScoreCardData, setAppPerformScoreCardData] = useState({});
  const [networkLatencyTrendData, setNetworkLatencyTrendData] = useState({});
  const [apiResponseTrendData, setAPIResponseTrendData] = useState({});
  const [topSlowestAPIData, setTopSlowestAPIData] = useState({});
  const [errorRateTrendData, setErrorRateTrendData] = useState({});
  const [errorRateTrendDataForBackend, setErrorRateTrendDataForBackend] =
    useState([]);
  const [apdexScoreData, setApdexScoreData] = useState({});
  const [incidentMonitoringData, setIncidentMonitoringData] = useState(null);
  const [apiCallTrendData, setAPICallTrendData] = useState([]);
  const [browserErrorTrendData, setBrowserErrorTrend] = useState([]);
  const [nrmAppPerfScoreCardData, setNrmAppPerfScoreCardData] = useState(null);
  const [dtcAssignmentGroupFilter, setDtcAssignmentGroupFilter] = useState([]);
  const [canvasAppPerfScoreCardData, setCanvasAppPerfScoreCardData] = useState(
    {}
  );
  const [pageResponseTrendData, setPageResponseTrendData] = useState({});
  const [serverSideCalendarData, setServerSideCalendarData] = useState({});
  const restructureHealthStabilityBarChartData = (barChart) => {
    const reStructuredData = {
      dates: [],
      low: [],
      high: [],
      medium: [],
    };

    let averageNetworkTime = 0;

    if (barChart.length > 0) {
      for (const data of barChart) {
        const formattedDate = formatDateToShortMonthDay(data?.eventTimestamp);
        reStructuredData.dates.push(formattedDate);
      }

      const totalNetworkTime = barChart.reduce(
        (total, data) => total + data.networkRequestTimeInMs,
        0
      );
      averageNetworkTime = Math.round(totalNetworkTime / barChart.length);

      for (const data of barChart) {
        if (data.networkRequestTimeInMs > 2 * averageNetworkTime) {
          reStructuredData.medium.push(null);
          reStructuredData.high.push(data.networkRequestTimeInMs);
          reStructuredData.low.push(null);
        } else if (data.networkRequestTimeInMs > 1.5 * averageNetworkTime) {
          reStructuredData.medium.push(data.networkRequestTimeInMs);
          reStructuredData.high.push(null);
          reStructuredData.low.push(null);
        } else {
          reStructuredData.high.push(null);
          reStructuredData.medium.push(null);
          reStructuredData.low.push(data.networkRequestTimeInMs);
        }
      }
    }

    return {
      reStructuredData,
      averageNetworkTime: averageNetworkTime || 0,
      maxNetworkRequestTime:
        barChart.length > 0
          ? Math.max(...barChart.map((data) => data.networkRequestTimeInMs))
          : 1000,
    };
  };

  const restructureErrorCrashRateTrendData = (data) => {
    const reStructuredData = {
      dates: [],
      ios: [],
      android: [],
      other: [],
    };
    let androidFlag = false,
      iosFlag = false,
      otherFlag = false;
    data?.forEach((element) => {
      reStructuredData.dates.push(
        formatDateForErrorCrashRateTrend(element.dateTime)
      );

      element?.osDetail?.forEach((os) => {
        if (os.osName === 'Android') {
          reStructuredData.android.push(os.errorCrashRateTrend);
          androidFlag = true;
        } else if (os.osName === 'iOS') {
          reStructuredData.ios.push(os.errorCrashRateTrend);
          iosFlag = true;
        } else {
          reStructuredData.other.push(os.errorCrashRateTrend);
          otherFlag = true;
        }
      });
      if (!androidFlag) {
        reStructuredData.android.push(null);
      }
      if (!iosFlag) {
        reStructuredData.ios.push(null);
      }
      if (!otherFlag) {
        reStructuredData.other.push(null);
      }

      androidFlag = false;
      iosFlag = false;
      otherFlag = false;
    });

    return reStructuredData;
  };

  const formatDateForErrorCrashRateTrend = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  };

  function transformDataForErrorRateBackend(originalData) {
    const transformedData = [];

    originalData.forEach((item) => {
      const dateTime = item.dateTime;
      const errorRate = item.osDetails[0].errorRate;

      const transformedItem = {
        dateTime: dateTime,
        value: errorRate,
      };

      transformedData.push(transformedItem);
    });

    return transformedData;
  }

  const [getDeviceOsBreakdownData] = useLazyQuery(getDeviceOsBreakdown, {
    onCompleted: (res) => {
      if (res) {
        const {
          getDeviceOsBreakdown: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setDeviceOsBreakdownData(data);
        } else {
          setDeviceOsBreakdownData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError', err);
    },
  });

  function formatDateToShortMonthDay(date) {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  }

  function transformData(apiResponse) {
    const apiNames = new Set();
    apiResponse?.forEach(({ apiDetails }) => {
      apiDetails?.forEach(({ apiName }) => {
        apiNames.add(apiName);
      });
    });

    const finalData = {
      dateTime: apiResponse?.map(({ dateTime }) => dateTime),
      apiDetails: [],
    };

    apiNames?.forEach((apiName) => {
      const responseTimeInMs = [];
      apiResponse?.forEach(({ apiDetails }) => {
        const apiDetail = apiDetails.find(
          (detail) => detail.apiName === apiName
        );
        responseTimeInMs.push(apiDetail ? apiDetail.responseTimeInMs : null);
      });

      finalData.apiDetails.push({
        apiName: apiName,
        responseTimeInMs: responseTimeInMs,
      });
    });

    return finalData;
  }

  const [getErrorCrashRateTrendData] = useLazyQuery(getErrorCrashRateTrend, {
    onCompleted: (res) => {
      if (res) {
        const {
          getErrorCrashRateTrend: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setErrorCrashRateTrendData(restructureErrorCrashRateTrendData(data));
        } else {
          setErrorCrashRateTrendData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError', err);
    },
  });

  const [getIncidentTrendData, { loading: incidentTrendLoading }] =
    useLazyQuery(getIncidentTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getIncidentTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setIncidentTrendData(data);
          } else {
            setIncidentTrendData({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    });

  const [
    getIncidentMonitoringSlaAnalysisData,
    { loading: slaAnalysisLoading },
  ] = useLazyQuery(getIncidentMonitoringSlaAnalysis, {
    onCompleted: (res) => {
      if (res) {
        const {
          getIncidentMonitoringSlaAnalysis: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setIncidentMonitoringSlaAnalysisData(data);
        } else {
          setIncidentMonitoringSlaAnalysisData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError', err);
    },
  });

  const [
    getIncidentMonitoringOpenAndCloseData,
    { loading: openAndCloseDataLoading },
  ] = useLazyQuery(getIncidentMonitoringOpenAndClose, {
    onCompleted: (res) => {
      if (res) {
        const {
          getIncidentMonitoringOpenAndClose: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setIncidentMonitoringOpenAndCloseData(data);
        } else {
          setIncidentMonitoringOpenAndCloseData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError', err);
    },
  });

  const [
    getIncidentMonitoringPriorityLevelGraphData,
    { loading: priorityLevelLoading },
  ] = useLazyQuery(getIncidentMonitoringPriorityLevelGraph, {
    onCompleted: (res) => {
      if (res) {
        const {
          getIncidentMonitoringPriorityLevelGraph: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setIncidentMonitoringPriorityLevelGraphData(data);
        } else {
          setIncidentMonitoringPriorityLevelGraphData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError', err);
    },
  });

  const [getNetworkPerformanceData] = useLazyQuery(getNetworkPerformance, {
    onCompleted: (res) => {
      if (res) {
        const {
          getNetworkPerformance: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setNetworkPerformanceData(data);
        } else {
          setNetworkPerformanceData({});
        }
      }
    },
    onError: (err) => {
      console.log('Network Performance Error', err);
    },
  });

  const [
    getIncidentMonitoringTableData,
    { loading: incidentTableDataLoading },
  ] = useLazyQuery(getIncidentMonitoringTableView, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getIncidentMonitoringTableView: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setIncidentMonitoringTableData(data);
        } else {
          setIncidentMonitoringTableData([]);
        }
      }
    },
    onError: (err) => {
      console.log('onError', err);
    },
  });

  const [getHealthAndStabilityData, { loading: healthAndStabilityLoading }] =
    useLazyQuery(getHealthAndStabilityOverview, {
      onCompleted: (res) => {
        if (res) {
          const {
            getHealthAndStability: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setHealthAndStability(data[0]);
          } else {
            setHealthAndStability({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    });

  const [getHealthAndStabilityBarChartData] = useLazyQuery(
    getHealthAndStabilityBarChart,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getHealthAndStabilityBarChart: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setHealthAndStabilityBarChart(
              restructureHealthStabilityBarChartData(data)
            );
          } else {
            setHealthAndStabilityBarChart({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    }
  );

  const [
    getIncidentMonitoringOverviewData,
    { loading: incidentOverviewLoading },
  ] = useLazyQuery(getIncidentMonitoringOverview, {
    onCompleted: (res) => {
      if (res) {
        const {
          getIncidentMonitoringOverview: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setIncidentMonitoringOverviewData(data);
        } else {
          setIncidentMonitoringOverviewData({});
        }
      }
    },
    onError: (err) => {
      console.log('Incident Monitoring Overview Error', err);
    },
  });

  const loadCountLineData = {
    values: [],
    dates: [],
  };
  function formatLoadCountLineData(data) {
    data?.length > 0 &&
      data?.forEach((element) => {
        loadCountLineData?.values?.push(element?.networkRequestTimeInMs);
        loadCountLineData?.dates?.push(
          formatDateToShortMonthDay(element?.eventTimestamp)
        );
      });
    return loadCountLineData;
  }

  const [getHealthAndStabilityLineChartData] = useLazyQuery(
    getHealthAndStabilityLineChart,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getHealthAndStabilityLineChart: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setHealthAndStabilityLineChart(formatLoadCountLineData(data));
          } else {
            setHealthAndStabilityLineChart({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    }
  );

  const [getUniqueUserTrendData, { loading: uniqueUserLoading }] = useLazyQuery(
    getUniqueUserTrend,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getUniqueUserTrend: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            const structuredData = {
              time: [],
              ios: [],
              android: [],
            };
            data.map((item) => {
              structuredData.time.push(item?.dateTime);
              let iosValue = null;
              let androidValue = null;
              item.osDetails.map((osDetailItem) => {
                if (
                  osDetailItem?.osName?.toUpperCase() ===
                  constants?.platform?.ios.toUpperCase()
                ) {
                  iosValue = osDetailItem?.totalUser;
                } else if (
                  osDetailItem.osName.toUpperCase() ===
                  constants?.platform?.android.toUpperCase()
                ) {
                  androidValue = osDetailItem?.totalUser;
                }
              });
              structuredData.ios.push(iosValue ?? null);
              structuredData.android.push(androidValue ?? null);
            });
            setUniqueUserTrendData(structuredData);
          } else {
            setUniqueUserTrendData({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    }
  );

  const [getAppCrashReportTrendData, { loading: appCrashReportLoading }] =
    useLazyQuery(getAppCrashReportTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getAppCrashReportTrend: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            const structuredData = {
              time: [],
              ios: [],
              android: [],
            };
            data.map((item) => {
              structuredData.time.push(item?.dateTime);
              let iosValue = null;
              let androidValue = null;
              item.osDetails.map((osDetailItem) => {
                if (
                  osDetailItem?.osName?.toUpperCase() ===
                  constants?.platform?.ios.toUpperCase()
                ) {
                  iosValue = osDetailItem?.errorCrashRate;
                } else if (
                  osDetailItem.osName.toUpperCase() ===
                  constants?.platform?.android.toUpperCase()
                ) {
                  androidValue = osDetailItem?.errorCrashRate;
                }
              });
              structuredData.ios.push(iosValue ?? null);
              structuredData.android.push(androidValue ?? null);
            });
            setAppCrashReportTrendData(structuredData);
          } else {
            setAppCrashReportTrendData({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    });

  const [getBounceRateSTTrendData, { loading: bounceRateLoading }] =
    useLazyQuery(getBounceRateSTTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getBounceRateSTTrend: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            const structuredData = {
              time: [],
              ios: [],
              android: [],
            };
            data.map((item) => {
              structuredData.time.push(item?.dateTime);
              let iosValue = null;
              let androidValue = null;
              item.osDetails.map((osDetailItem) => {
                if (
                  osDetailItem?.osName?.toUpperCase() ===
                  constants?.platform?.ios.toUpperCase()
                ) {
                  iosValue = osDetailItem?.bounceRateInPercentage;
                } else if (
                  osDetailItem.osName.toUpperCase() ===
                  constants?.platform?.android.toUpperCase()
                ) {
                  androidValue = osDetailItem?.bounceRateInPercentage;
                }
              });
              structuredData.ios.push(iosValue ?? null);
              structuredData.android.push(androidValue ?? null);
            });
            setBounceRateSTTrendData(structuredData);
          } else {
            setBounceRateSTTrendData({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    });

  const [getActiveUserTrendData, { loading: activeUserLoading }] = useLazyQuery(
    getActiveUserTrend,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getActiveUserTrend: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            const structuredData = {
              time: [],
              ios: [],
              android: [],
            };
            data.map((item) => {
              structuredData.time.push(item?.dateTime);
              let iosValue = null;
              let androidValue = null;
              item?.osDetails?.map((osDetailItem) => {
                if (
                  osDetailItem?.osName?.toUpperCase() ===
                  constants?.platform?.ios.toUpperCase()
                ) {
                  iosValue = osDetailItem?.totalUser;
                } else if (
                  osDetailItem?.osName?.toUpperCase() ===
                  constants?.platform?.android.toUpperCase()
                ) {
                  androidValue = osDetailItem?.totalUser;
                }
              });
              structuredData.ios.push(iosValue ?? null);
              structuredData.android.push(androidValue ?? null);
            });
            setActiveUserTrendData(structuredData);
          } else {
            setActiveUserTrendData({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    }
  );

  const [getAvgerageSessionTimeData, { loading: avgSessionTimeLoading }] =
    useLazyQuery(getAvgSessionTime, {
      onCompleted: (res) => {
        if (res) {
          const {
            getAvgSessionTime: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            const structuredData = {
              time: [],
              ios: [],
              android: [],
            };
            data.map((item) => {
              structuredData.time.push(item?.dateTime);
              let iosValue = null;
              let androidValue = null;
              item?.osDetails?.map((osDetailItem) => {
                if (
                  osDetailItem?.osName?.toUpperCase() ===
                  constants?.platform?.ios.toUpperCase()
                ) {
                  iosValue = osDetailItem?.sessionTimeInMs;
                } else if (
                  osDetailItem?.osName?.toUpperCase() ===
                  constants?.platform?.android.toUpperCase()
                ) {
                  androidValue = osDetailItem?.sessionTimeInMs;
                }
              });
              structuredData.ios.push(iosValue ?? null);
              structuredData.android.push(androidValue ?? null);
            });
            setAverageSessionTimeData(structuredData);
          } else {
            setAverageSessionTimeData({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    });

  const [getAppPerformanceScoreCardData, { loading: performanceLoading }] =
    useLazyQuery(getAppPerformanceScoreCardQuery, {
      onCompleted: (res) => {
        if (res) {
          const { getAppPerformanceScorecard, getIncidentScoreCard } = res;
          const isAppPerformanceDataSuccess =
            getAppPerformanceScorecard?.data &&
            getAppPerformanceScorecard?.responseCode === 200;
          const isIncidentScoreCardDataSuccess =
            getIncidentScoreCard?.data &&
            getIncidentScoreCard?.responseCode === 200;
          if (isAppPerformanceDataSuccess || isIncidentScoreCardDataSuccess) {
            const structuredData = {
              appPerformance: getAppPerformanceScorecard?.data,
              incidentData: getIncidentScoreCard?.data,
            };
            setAppPerformScoreCardData(structuredData);
          } else {
            setAppPerformScoreCardData({});
          }
        }
      },
      onError: (error) => {
        console.log('setAppPerformScoreCardData error : ', error);
        if (
          error.networkError &&
          error.networkError.statusCode >= 500 &&
          error.networkError.statusCode < 600
        ) {
          setAppPerformScoreCardData({});
        }
      },
    });

  const [getNetworkLatencyTrendData, { loading: networkLatencyLoading }] =
    useLazyQuery(getNetworkLatencyTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getNetworkLatencyTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            const structuredData = {
              time: [],
              ios: [],
              android: [],
            };
            data.map((item) => {
              structuredData.time.push(item?.dateTime);
              let iosValue = null;
              let androidValue = null;
              item.osDetails.map((osDetailItem) => {
                if (
                  osDetailItem?.osName?.toUpperCase() ===
                  constants?.platform?.ios.toUpperCase()
                ) {
                  iosValue = osDetailItem?.loadInMs;
                } else if (
                  osDetailItem.osName.toUpperCase() ===
                  constants?.platform?.android.toUpperCase()
                ) {
                  androidValue = osDetailItem?.loadInMs;
                }
              });
              structuredData.ios.push(iosValue ?? null);
              structuredData.android.push(androidValue ?? null);
            });
            setNetworkLatencyTrendData(structuredData);
          } else {
            setNetworkLatencyTrendData({});
          }
        }
      },
      onError: (err) => {
        console.log('getNetworkLatencyTrendData error', err);
      },
    });

  const [getAPIResponseTrendData, { loading: apiResponseLoading }] =
    useLazyQuery(getAPIResponseTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getAPIResponseTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            const finalData = transformData(data);
            setAPIResponseTrendData(finalData);
          } else {
            setAPIResponseTrendData({});
          }
        }
      },
      onError: (err) => {
        console.log('getAPIResponseTrendData error : ', err);
      },
    });

  const [getTopSlowestAPIData, { loading: slowestAPILoading }] = useLazyQuery(
    getTopSlowestAPI,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getTopSlowestAPI: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            const structuredData = {
              apiName: [],
              responseTime: [],
            };

            data.map((item) => {
              structuredData.apiName.push(item?.name);
              structuredData.responseTime.push(item?.val);
            });
            setTopSlowestAPIData(structuredData);
          } else {
            setTopSlowestAPIData({});
          }
        }
      },
      onError: (error) => {
        console.log('getTopSlowestAPIData error : ', error);
        if (
          error.networkError &&
          error.networkError.statusCode >= 500 &&
          error.networkError.statusCode < 600
        ) {
          setTopSlowestAPIData({});
        }
      },
    }
  );

  const [getErrorRateTrendData] = useLazyQuery(getErrorRateTrend, {
    onCompleted: (res) => {
      if (res) {
        const {
          getErrorRateTrend: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          const structuredData = {
            time: [],
            ios: [],
            android: [],
          };
          data.map((item) => {
            structuredData.time.push(item?.dateTime);
            let iosValue = null;
            let androidValue = null;
            item.osDetails.map((osDetailItem) => {
              if (
                osDetailItem?.osName?.toUpperCase() ===
                constants?.platform?.ios.toUpperCase()
              ) {
                iosValue = osDetailItem?.errorRate;
              } else if (
                osDetailItem.osName.toUpperCase() ===
                constants?.platform?.android.toUpperCase()
              ) {
                androidValue = osDetailItem?.errorRate;
              }
            });
            structuredData.ios.push(iosValue ?? null);
            structuredData.android.push(androidValue ?? null);
          });
          setErrorRateTrendData(structuredData);
        } else {
          setErrorRateTrendData({});
        }
      }
    },
    onError: (err) => {
      console.log('getErrorRateTrendData error : ', err);
    },
  });

  const [getErrorRateTrendDataForBackend, { loading: errorRateLoading }] =
    useLazyQuery(getErrorRateTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getErrorRateTrend: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            const updatedData = transformDataForErrorRateBackend(data);
            setErrorRateTrendDataForBackend(updatedData);
          } else {
            setErrorRateTrendDataForBackend([]);
          }
        }
      },
      onError: (err) => {
        console.log('setErrorRateTrendDataForBackend error : ', err);
      },
    });

  const [getApdexScoreData, { loading: apdexScoreLoading }] = useLazyQuery(
    getApdexScore,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getApdexScore: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setApdexScoreData(data);
          } else {
            setApdexScoreData({});
          }
        }
      },
      onError: (error) => {
        console.log('getApdexScoreData error : ', error);
        if (
          error.networkError &&
          error.networkError.statusCode >= 500 &&
          error.networkError.statusCode < 600
        ) {
          setApdexScoreData({});
        }
      },
    }
  );

  const [getIncidentMonitoringData, { loading: incidentMonitoringLoading }] =
    useLazyQuery(getIncidentMonitoring, {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getIncidentTrend,
            getIncidentMonitoringOverview,
            getIncidentMonitoringPriorityLevelGraph,
            getIncidentMonitoringOpenAndClose,
            getIncidentMonitoringSlaAnalysis,
          } = res;
          const incidentMonitoringData = {};
          if (
            getIncidentTrend?.data &&
            getIncidentTrend?.responseCode === 200
          ) {
            incidentMonitoringData.incidentTrendData = getIncidentTrend?.data;
          }
          if (
            getIncidentMonitoringOverview?.data &&
            getIncidentMonitoringOverview?.responseCode === 200
          ) {
            incidentMonitoringData.incidentMonitoringOverviewData =
              getIncidentMonitoringOverview?.data;
          }
          if (
            getIncidentMonitoringPriorityLevelGraph?.data &&
            getIncidentMonitoringPriorityLevelGraph?.responseCode === 200
          ) {
            incidentMonitoringData.incidentMonitoringPriorityLevelGraphData =
              getIncidentMonitoringPriorityLevelGraph?.data;
          }
          if (
            getIncidentMonitoringOpenAndClose?.data &&
            getIncidentMonitoringOpenAndClose?.responseCode === 200
          ) {
            incidentMonitoringData.incidentMonitoringOpenAndCloseData =
              getIncidentMonitoringOpenAndClose?.data;
          }
          if (
            getIncidentMonitoringSlaAnalysis?.data &&
            getIncidentMonitoringSlaAnalysis?.responseCode === 200
          ) {
            incidentMonitoringData.incidentMonitoringSlaAnalysisData =
              getIncidentMonitoringSlaAnalysis?.data;
          }
          setIncidentMonitoringData(incidentMonitoringData);
        }
      },
      onError: (err) => {
        console.log('getIncidentMonitoringData error : ', err);
      },
    });

  const [getApiCallsTrendData, { loading: apiCallsTrendLoading }] =
    useLazyQuery(getApiCallsTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getApiCallsTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setAPICallTrendData(data);
          } else {
            setAPICallTrendData([]);
          }
        }
      },
      onError: (error) => {
        console.log('getApiCallsTrendData error : ', error);
        if (
          error.networkError &&
          error.networkError.statusCode >= 500 &&
          error.networkError.statusCode < 600
        ) {
          setAPICallTrendData([]);
        }
      },
    });

  const [
    getNRMAppPerformanceScoreCardData,
    { loading: nrmAppPerformanceScoreCardLoading },
  ] = useLazyQuery(getNRMAppPerformanceScoreCard, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getAppPerformanceScorecard: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setNrmAppPerfScoreCardData(data);
        } else {
          setNrmAppPerfScoreCardData({});
        }
      }
    },
    onError: (err) => {
      console.log('getNRMAppPerformanceScoreCard error : ', err);
    },
  });

  const [getDtcAssignmentGroup, { loading: getDtcAssignmentGroupLoading }] =
    useLazyQuery(getDtcAssignmentGroupFilter, {
      onCompleted: (res) => {
        if (res) {
          const {
            getAssignmentGroupFilter: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setDtcAssignmentGroupFilter(data);
          } else {
            setDtcAssignmentGroupFilter([]);
          }
        }
      },
      onError: (err) => {
        console.log('getNRMAppPerformanceScoreCard error : ', err);
      },
    });

  const [
    getCanvasAppPerformanceScoreCardData,
    { loading: canvasAppPerformanceScoreCardLoading },
  ] = useLazyQuery(getCanvasAppPerformanceScoreCard, {
    onCompleted: (res) => {
      if (res) {
        const {
          getAppPerformanceScorecard: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setCanvasAppPerfScoreCardData(data);
        } else {
          setCanvasAppPerfScoreCardData({});
        }
      }
    },
    onError: (err) => {
      console.log('getNRMAppPerformanceScoreCard error : ', err);
    },
  });

  const [getBrowserErrorTrendData, { loading: browserErrorTrendLoading }] =
    useLazyQuery(getBrowserErrorTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getBrowserErrorTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            const finalData = data;
            setBrowserErrorTrend(finalData);
          } else {
            setBrowserErrorTrend([]);
          }
        }
      },
      onError: (error) => {
        console.log('getBrowserErrorTrendData error : ', error);
        if (
          error.networkError &&
          error.networkError.statusCode >= 500 &&
          error.networkError.statusCode < 600
        ) {
          setBrowserErrorTrend([]);
        }
      },
    });

  const [getPageResponseTrendData, { loading: pageResponseLoading }] =
    useLazyQuery(getPageResponseTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getPageResponseTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            const finalData = transformData(data);
            setPageResponseTrendData(finalData);
          } else {
            setPageResponseTrendData({});
          }
        }
      },
      onError: (error) => {
        console.log('pageResponseTrendData error : ', error);
        if (
          error.networkError &&
          error.networkError.statusCode >= 500 &&
          error.networkError.statusCode < 600
        ) {
          setPageResponseTrendData({});
        }
      },
    });

  const [
    getServerSideCalendarGenRunTimeTrendData,
    { loading: serverSideCalendarLoading },
  ] = useLazyQuery(getServerSideCalendarGenRunTimeTrend, {
    onCompleted: (res) => {
      if (res) {
        const {
          getServerSideCalendarGenRunTimeTrend: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setServerSideCalendarData(data);
        } else {
          setServerSideCalendarData({});
        }
      }
    },
    onError: (err) => {
      console.log('getServerSideCalendarGenRunTimeTrend error : ', err);
    },
  });

  return {
    getDeviceOsBreakdownData,
    deviceOsBreakdownData,
    getErrorCrashRateTrendData,
    errorCrashRateTrendData,
    getIncidentTrendData,
    incidentTrendData,
    getNetworkPerformanceData,
    networkPerformanceData,
    getIncidentMonitoringTableData,
    incidentMonitoringTableData,
    incidentTableDataLoading,
    getHealthAndStabilityData,
    healthAndStability,
    healthAndStabilityLoading,
    getHealthAndStabilityBarChartData,
    healthAndStabilityBarChart,
    getIncidentMonitoringSlaAnalysisData,
    incidentMonitoringSlaAnalysisData,
    getIncidentMonitoringOpenAndCloseData,
    incidentMonitoringOpenAndCloseData,
    openAndCloseDataLoading,
    getIncidentMonitoringPriorityLevelGraphData,
    incidentMonitoringPriorityLevelGraphData,
    priorityLevelLoading,
    getIncidentMonitoringOverviewData,
    incidentMonitoringOverviewData,
    incidentOverviewLoading,
    getHealthAndStabilityLineChartData,
    healthAndStabilityLineChart,
    restructureErrorCrashRateTrendData,
    restructureHealthStabilityBarChartData,
    formatLoadCountLineData,
    getUniqueUserTrendData,
    uniqueUserTrendData,
    uniqueUserLoading,
    getAppCrashReportTrendData,
    appCrashReportTrendData,
    appCrashReportLoading,
    getBounceRateSTTrendData,
    bounceRateSTTrendData,
    bounceRateLoading,
    getActiveUserTrendData,
    activeUserTrendData,
    activeUserLoading,
    getAvgerageSessionTimeData,
    averageSessionTimeData,
    avgSessionTimeLoading,
    getAppPerformanceScoreCardData,
    appPerformScoreCardData,
    performanceLoading,
    incidentTrendLoading,
    slaAnalysisLoading,
    getNetworkLatencyTrendData,
    networkLatencyTrendData,
    networkLatencyLoading,
    getAPIResponseTrendData,
    apiResponseTrendData,
    apiResponseLoading,
    getTopSlowestAPIData,
    topSlowestAPIData,
    slowestAPILoading,
    getErrorRateTrendData,
    errorRateTrendData,
    getErrorRateTrendDataForBackend,
    errorRateTrendDataForBackend,
    errorRateLoading,
    apdexScoreData,
    getApdexScoreData,
    apdexScoreLoading,
    incidentMonitoringData,
    getIncidentMonitoringData,
    incidentMonitoringLoading,
    getApiCallsTrendData,
    apiCallsTrendLoading,
    apiCallTrendData,
    getBrowserErrorTrendData,
    browserErrorTrendLoading,
    browserErrorTrendData,
    getNRMAppPerformanceScoreCardData,
    nrmAppPerformanceScoreCardLoading,
    nrmAppPerfScoreCardData,
    getCanvasAppPerformanceScoreCardData,
    canvasAppPerformanceScoreCardLoading,
    canvasAppPerfScoreCardData,
    getPageResponseTrendData,
    pageResponseLoading,
    pageResponseTrendData,
    getDtcAssignmentGroup,
    getDtcAssignmentGroupLoading,
    dtcAssignmentGroupFilter,
    getServerSideCalendarGenRunTimeTrendData,
    serverSideCalendarLoading,
    serverSideCalendarData,
  };
};
