import {
  formatValueWithMiddlewares,
  checkForNA,
  applySuffix,
  createKpiTileItem,
} from '../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    const { activeUser, activeUsersPercentageDelta } =
      data.getUserAdoptionScorecard;

    return createKpiTileItem(
      'Active Users',
      formatValueWithMiddlewares(activeUser, [checkForNA, applySuffix]),
      activeUsersPercentageDelta !== undefined
        ? {
            isPositiveValue: activeUsersPercentageDelta >= 0,
            text: formatValueWithMiddlewares(activeUsersPercentageDelta, [
              checkForNA,
              applySuffix,
            ]).toString(),
            sideLabel: '',
            showArrow: true,
          }
        : null
    );
  },
};
