import React from 'react';
import Loader from 'components/Loader/Loader';
import { CardProps } from './types';
import { StyledCard, Header, LoaderWrapper } from './style';

const KpiContainer: React.FC<CardProps> = ({
  title,
  children,
  withBorder = true,
  withPadding = true,
  isLoading = false,
  height,
}) => {
  return (
    <StyledCard
      withBorder={withBorder}
      withPadding={withPadding}
      data-testid="kpi-container"
      height={height}
    >
      {title && <Header data-testid="kpi-container-heading">{title}</Header>}
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </StyledCard>
  );
};

export default KpiContainer;
