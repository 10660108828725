import styled from 'styled-components';
import { Tab, Tabs, Grid } from '@mui/material';

export const TabContainer = styled(Tabs)`
  & .MuiTabs-flexContainer {
    background: ${({ mode }) => (mode === 'light' ? '#F3F7FD' : '#131313')};
    box-shadow: 0px -1px 0px 0px #3a3a3a inset;
  }
  && {
    & .MuiButtonBase-root {
    }

    & .MuiTabs-scrollButtons {
      color: #fff !important;
    }
    & .Mui-selected {
      color: ${({ mode }) => (mode === 'light' ? '#616161' : '#d0d0d0')};
      font-style: normal;
      font-weight: 700;
    }
  }
`;

export const GridContainer = styled(Grid)`
  background: #0a0a0a;
  margin: 0;
  padding: 0;
  width: 100%;

  & .contentTitle {
    align-self: flex-start;
    color: #d2d2d2;
    flex-shrink: 0;
    /* position: absolute; */
    /* width: 320px; */
    /* height: 51px; */
    left: 0px;
    top: 0px;

    /* Heading/H4 */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 160%;
    color: ${({ mode }) => (mode === 'light' ? '#131313' : '#d0d0d0')};
  }

  & .subHeaderContainer {
    margin: 20px 0px 10px 0px;
  }
  & .subHeader {
    width: 100%;
    backgroundcolor: black;
    color: #efefef;
    font-family: Inter;
    font-size: 25px;
    font-weight: 700;
  }
  & .css-jpln7h-MuiTabs-scroller {
    // overflow-x: scroll !important;
    // display:none;
  }
`;
export const TabItems = styled(Tab)`
  && {
    color: #efefef;
    text-transform: none;
    font-family: 'Inter';
    font-size: 14px;
    // border-bottom: 1px solid #666666;
    // color: var(--dark-theme-text-color-text-secondary, #d0d0d0);
    color: ${({ mode }) => (mode === 'light' ? '#131313' : '#d0d0d0')};
    /* Small/Regular */
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const TabsDiv = styled.div`
  @media (max-width: 600px) {
    height: 67vh;
  }
  & .tabPanel {
    overflow-y: scroll;
    height: calc(100vh - 310px);
    padding-bottom: 30px;
    min-height: 100%;
  }
`;
