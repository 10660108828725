import { gql } from '@apollo/client';

const getActiveAndTotalUsersChart = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getActiveAndTotalUsersChart(psdCriteria: $psdCriteria) {
      data {
        activeUser
        totalUser
        dateTime
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getTopAPIRequest = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getTopAPIRequest(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        name
        val
        valInPer
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getTopErrorAPI = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getTopErrorAPI(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        name
        val
        valInPer
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getNRMPricingCoreWebVitals = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getNRMPricingCoreWebVitals(psdCriteria: $psdCriteria) {
      data {
        lcp {
          dateTime
          responseTime
        }
        fid {
          dateTime
          responseTime
        }
        cls {
          dateTime
          responseTime
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getScenarios = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getScenarios(psdCriteria: $psdCriteria) {
      data {
        numberOfCreatedScenarios
        scenariosCreated {
          dateTime
          numberOfScenariosCreated
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getCalendarGenerations = gql`
  query ($overviewMetricCriteria: OverviewMetricesRequestDTOInput!) {
    getCalendarGenerations(overviewMetricCriteria: $overviewMetricCriteria) {
      data {
        totalCreated
        trend {
          dateTime
          successfulGenerations
          failedGenerations
          totalGenerations
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getValuableActionsTakenChart = gql`
  query ($overviewMetricCriteria: OverviewMetricesRequestDTOInput!) {
    getValuableActionsTakenChart(
      overviewMetricCriteria: $overviewMetricCriteria
    ) {
      data {
        totalActions
        actionTakenData {
          dateTime
          actions {
            actionName
            percentageValue
          }
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getTimeSpentByWorkFlow = gql`
  query ($overviewMetricCriteria: OverviewMetricesRequestDTOInput!) {
    getTimeSpentByWorkFlow(overviewMetricCriteria: $overviewMetricCriteria) {
      data {
        workflowName
        avgTimeTaken
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRGMAPPValueCreationScorecard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRGMAPPValueCreationScorecard(psdCriteria: $psdCriteria) {
      data {
        promotionsSaved
        promotionsSavedDelta
        scenariosEdited
        scenariosEditedDelta
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

export {
  getActiveAndTotalUsersChart,
  getTopAPIRequest,
  getTopErrorAPI,
  getNRMPricingCoreWebVitals,
  getScenarios,
  getCalendarGenerations,
  getValuableActionsTakenChart,
  getTimeSpentByWorkFlow,
  getRGMAPPValueCreationScorecard,
};
