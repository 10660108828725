import styled from "styled-components";
import { Box, Button } from "@mui/material";
import { color } from "../../theme/Color";

export const ButtonContainer = styled(Button)`
  margin: 0;
`;
export const MainContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 97vw;
  height: 97vh;
  background: ${({ mode }) =>
    mode === "light"
      ? `${color._F3F7FD} !important`
      : `${color._1d1d1d} !important`};
  box-shadow: 24px;
  border: ${({ mode }) =>
    mode === "light" ? `1px solid ${color._D7E5F8}` : " "};
  color: ${({ mode }) =>
    mode === "light"
      ? `${color._616161} !important`
      : `${color._ffffff}!important`};
  padding: 50px 32px 32px 32px;

  & .dropDownContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }

  & .headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: ${({ mode }) =>
      mode === "light"
        ? `${color._FBFCFE} !important`
        : `${color._272727} !important`};
    margin-bottom: 10px;
    padding: 2px;
  }

  & .header {
    text-transform: uppercase;
    width: 20%;
  }

  & .backButton {
    font-size: 12px;
    border-radius: 24px;
    border: ${({ mode }) =>
      mode === "light" ? `1px solid ${color._D7E5F8}` : `1px solid #3387df`};
    background: transparent;
    text-transform: none;
    color: ${({ mode }) => (mode === "light" ? `${color._616161}` : `white`)};
    height: 25px;
  }
  & .backButton:hover {
    background-color: inherit;
  }

  & .custom-data-grid {
    color: white;
    border: none;
    & .muidatagrid-cell: {
      border-bottom: 0.2px solid red;
    }
  }

  & a.custom-link {
    color: #3387df !important;
    cursor: pointer;
  }
  & a.custom-link:visited {
    color: #3387df !important;
  }

  & .incidentFilterContainer {
    padding-left: 10px;
  }

  & .incidentFilterText {
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161}` : `${color._d0d0d0}`};
    font-size: 12px;
    margin-bottom: 8px;
  }
`;
