import { breakpoints } from '../constants/Constants';

export interface ColorModeTokens {
  textPrimary: string;
  textSecondary: string;
  icon: string;
  border: string;
  cardBorder: string;
  backgroundLayer1: string;
  verticalDivider: string;
  horizontalDivider: string;
  infoLabelText: string;
  heatMapMinRange: string;
  heatMapMaxRange: string;
  heatMapLowValue: string;
  heatMapHighValue: string;
  // ...other color tokens as needed...
}

export interface ColorTokens {
  light: ColorModeTokens;
  dark: ColorModeTokens;
}

export const colorTokens: ColorTokens = {
  light: {
    textPrimary: '#3A3A3A',
    textSecondary: '#616161',
    icon: '#3A3A3A',
    border: '#D7E5F8',
    cardBorder: '#D7E5F8',
    backgroundLayer1: '#FBFCFE',
    verticalDivider: '#DFDFDF',
    horizontalDivider: '#DFDFDF',
    infoLabelText: '#005CBC',
    heatMapMinRange: '#E6F5FA',
    heatMapMaxRange: '#33ABDA',
    heatMapLowValue: '#D40020',
    heatMapHighValue: '#FFBF1C',
    // ...other color tokens...
  },
  dark: {
    textPrimary: '#EFEFEF',
    textSecondary: '#D0D0D0',
    icon: '#EFEFEF',
    border: '#3A3A3A',
    cardBorder: '',
    backgroundLayer1: '#1D1D1D',
    verticalDivider: '#3A3A3A',
    horizontalDivider: '#3A3A3A',
    infoLabelText: '#3387DF',
    heatMapMinRange: '#E5F5FC',
    heatMapMaxRange: '#33B1E8',
    heatMapLowValue: '#D40020',
    heatMapHighValue: '#FFBF1C',
    // ...other color tokens...
  },
};

// Spacing tokens

export interface SpacingTokens {
  spacing_0: string;
  spacing_2: string;
  spacing_4: string;
  spacing_8: string;
  spacing_12: string;
  spacing_16: string;
  spacing_24: string;
  spacing_32: string;
  spacing_40: string;
  spacing_48: string;
  spacing_64: string;
  spacing_80: string;
  spacing_96: string;
  spacing_160: string;
}

export const spacing: SpacingTokens = {
  spacing_0: '0px',
  spacing_2: '2px',
  spacing_4: '4px',
  spacing_8: '8px',
  spacing_12: '12px',
  spacing_16: '16px',
  spacing_24: '24px',
  spacing_32: '32px',
  spacing_40: '40px',
  spacing_48: '48px',
  spacing_64: '64px',
  spacing_80: '80px',
  spacing_96: '96px',
  spacing_160: '160px',
};

// Border tokens

export interface BorderTokens {
  border_01: string;
  border_02: string;
  border_03: string;
  border_04: string;
  border_05: string;
  border_06: string;
  border_07: string;
  border_08: string;
  border_09: string;
}

export const borderLight: BorderTokens = {
  border_01: 'box-shadow: inset 0px -2px 0px #D7E5F8;',
  border_02: 'box-shadow: inset 0px -1px 0px #DFDFDF;',
  border_03: 'box-shadow: inset 0px -1px 0px #3387DF;',
  border_04: 'box-shadow: inset 0px -2px 0px #005CBC;',
  border_05: 'box-shadow: inset 5px 0px 0px #005CBC;',
  border_06: 'box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);',
  border_07: 'box-shadow: inset -1px 0px 0px #D7E5F8;',
  border_08:
    'box-shadow: inset 0px 1px 0px #005CBC, inset 0px -1px 0px #005CBC;',
  border_09:
    'box-shadow: inset 0px 1px 0px #005CBC, inset 0px -1px 0px #005CBC;',
};

export const borderDark: BorderTokens = {
  border_01: 'box-shadow: inset 0px -2px 0px #D7E5F8;',
  border_02: 'box-shadow: inset 0px -1px 0px #DFDFDF;',
  border_03: 'box-shadow: inset 0px -1px 0px #3387DF;',
  border_04: 'box-shadow: inset 0px -2px 0px #005CBC;',
  border_05: 'box-shadow: inset 5px 0px 0px #005CBC;',
  border_06: 'box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);',
  border_07: 'box-shadow: inset -1px 0px 0px #D7E5F8;',
  border_08:
    'box-shadow: inset 0px 1px 0px #005CBC, inset 0px -1px 0px #005CBC;',
  border_09:
    'box-shadow: inset 0px 1px 0px #005CBC, inset 0px -1px 0px #005CBC;',
};

// Corner radius tokens

export interface CornerRadiusTokens {
  xxsmall: string;
  xsmall: string;
  small: string;
  medium: string;
  large: string;
}

export const cornerRadius: CornerRadiusTokens = {
  xxsmall: '2px',
  xsmall: '4px',
  small: '8px',
  medium: '16px',
  large: '24px',
};

// Typography tokens
// Since the typography tokens share a similar structure, you can use the same interface for desktop and mobile.

export interface TypographyTokens {
  fontHeadingH1Bold: string;
  fontHeadingH2Bold: string;
  fontHeadingH3Bold: string;
  fontHeadingH4Bold: string;
  fontHeadingH4Medium: string;
  fontHeadingH5Bold: string;
  fontHeadingH5Medium: string;
  fontHeadingH6Bold: string;
  fontHeadingH6Medium: string;
  fontBodyBold: string;
  fontBodyMedium: string;
  fontBodyRegular: string;
  fontBodyBoldCaps: string;
  fontSmallBold: string;
  fontSmallMedium: string;
  fontSmallRegular: string;
  fontSmallBoldCaps: string;
  fontXsmallBold: string;
  fontXsmallMedium: string;
  fontXsmallRegular: string;
  fontXsmallBoldCaps: string;
  fontXXsmallBold: string;
  fontXXsmallMedium: string;
  fontXXsmallRegular: string;
  fontXXsmallBoldCaps: string;
}

export const typographyDesktop: TypographyTokens = {
  fontHeadingH1Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 48px; line-height: 140%;",
  fontHeadingH2Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 40px; line-height: 140%;",
  fontHeadingH3Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 32px; line-height: 140%;",
  fontHeadingH4Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 28px; line-height: 140%;",
  fontHeadingH4Medium:
    "font-family: 'Inter'; font-weight: 500; font-size: 28px; line-height: 140%;",
  fontHeadingH5Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 24px; line-height: 140%;",
  fontHeadingH5Medium:
    "font-family: 'Inter'; font-weight: 500; font-size: 24px; line-height: 140%;",
  fontHeadingH6Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 18px; line-height: 140%;",
  fontHeadingH6Medium:
    "font-family: 'Inter'; font-weight: 500; font-size: 20px; line-height: 140%;",
  fontBodyBold:
    "font-family: 'Inter'; font-weight: bold; font-size: 16px; line-height: 140%;",
  fontBodyMedium:
    "font-family: 'Inter'; font-weight: 500; font-size: 16px; line-height: 140%;",
  fontBodyRegular:
    "font-family: 'Inter'; font-weight: 400; font-size: 16px; line-height: 140%;",
  fontBodyBoldCaps:
    "font-family: 'Inter'; font-weight: bold; font-size: 16px; text-transform: uppercase; line-height: 140%;",
  fontSmallBold:
    "font-family: 'Inter'; font-weight: bold; font-size: 14px; line-height: 140%;",
  fontSmallMedium:
    "font-family: 'Inter'; font-weight: 500; font-size: 14px; line-height: 140%;",
  fontSmallRegular:
    "font-family: 'Inter'; font-weight: 400; font-size: 14px; line-height: 140%;",
  fontSmallBoldCaps:
    "font-family: 'Inter'; font-weight: bold; font-size: 14px; text-transform: uppercase; line-height: 140%;",
  fontXsmallBold:
    "font-family: 'Inter'; font-weight: bold; font-size: 12px; line-height: 140%;",
  fontXsmallMedium:
    "font-family: 'Inter'; font-weight: 500; font-size: 12px; line-height: 140%;",
  fontXsmallRegular:
    "font-family: 'Inter'; font-weight: 400; font-size: 12px; line-height: 140%;",
  fontXsmallBoldCaps:
    "font-family: 'Inter'; font-weight: bold; font-size: 12px; text-transform: uppercase; line-height: 140%;",
  fontXXsmallBold:
    "font-family: 'Inter'; font-weight: bold; font-size: 10px; line-height: 140%;",
  fontXXsmallMedium:
    "font-family: 'Inter'; font-weight: 500; font-size: 10px; line-height: 140%;",
  fontXXsmallRegular:
    "font-family: 'Inter'; font-weight: 400; font-size: 10px; line-height: 140%;",
  fontXXsmallBoldCaps:
    "font-family: 'Inter'; font-weight: bold; font-size: 10px; text-transform: uppercase; line-height: 140%;",
};

export const typographyMobile: TypographyTokens = {
  fontHeadingH1Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 32px; line-height: 140%;",
  fontHeadingH2Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 28px; line-height: 140%;",
  fontHeadingH3Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 24px; line-height: 140%;",
  fontHeadingH4Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 22px; line-height: 140%;",
  fontHeadingH4Medium:
    "font-family: 'Inter'; font-weight: 500; font-size: 22px; line-height: 140%;",
  fontHeadingH5Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 20px; line-height: 140%;",
  fontHeadingH5Medium:
    "font-family: 'Inter'; font-weight: 500; font-size: 20px; line-height: 140%;",
  fontHeadingH6Bold:
    "font-family: 'Inter'; font-weight: bold; font-size: 18px; line-height: 140%;",
  fontHeadingH6Medium:
    "font-family: 'Inter'; font-weight: 500; font-size: 18px; line-height: 140%;",
  fontBodyBold:
    "font-family: 'Inter'; font-weight: bold; font-size: 16px; line-height: 140%;",
  fontBodyMedium:
    "font-family: 'Inter'; font-weight: 500; font-size: 16px; line-height: 140%;",
  fontBodyRegular:
    "font-family: 'Inter'; font-weight: 400; font-size: 16px; line-height: 140%;",
  fontBodyBoldCaps:
    "font-family: 'Inter'; font-weight: bold; font-size: 16px; line-height: 140%;",
  fontSmallBold:
    "font-family: 'Inter'; font-weight: bold; font-size: 14px; line-height: 140%;",
  fontSmallMedium:
    "font-family: 'Inter'; font-weight: 500; font-size: 14px; line-height: 140%;",
  fontSmallRegular:
    "font-family: 'Inter'; font-weight: 400; font-size: 14px; line-height: 140%;",
  fontSmallBoldCaps:
    "font-family: 'Inter'; font-weight: bold; font-size: 14px; line-height: 140%;",
  fontXsmallBold:
    "font-family: 'Inter'; font-weight: bold; font-size: 12px; line-height: 140%;",
  fontXsmallMedium:
    "font-family: 'Inter'; font-weight: 500; font-size: 12px; line-height: 140%;",
  fontXsmallRegular:
    "font-family: 'Inter'; font-weight: 400; font-size: 12px; line-height: 140%;",
  fontXsmallBoldCaps:
    "font-family: 'Inter'; font-weight: bold; font-size: 12px; line-height: 140%;",
  fontXXsmallBold:
    "font-family: 'Inter'; font-weight: bold; font-size: 10px; line-height: 140%;",
  fontXXsmallMedium:
    "font-family: 'Inter'; font-weight: 500; font-size: 10px; line-height: 140%;",
  fontXXsmallRegular:
    "font-family: 'Inter'; font-weight: 400; font-size: 10px; line-height: 140%;",
  fontXXsmallBoldCaps:
    "font-family: 'Inter'; font-weight: bold; font-size: 10px; line-height: 140%;",
};

/**
 * Merge typography tokens into one object that:
 * - applies mobile tokens by default
 * - applies desktop tokens when the viewport width meets the tablet breakpoint or higher
 */
export const mergedTypography: TypographyTokens = Object.keys(
  typographyDesktop
).reduce((acc, key) => {
  // Type assertion for key being keyof TypographyTokens
  const tokenKey = key as keyof TypographyTokens;

  // Construct a string with the mobile style and a media query for desktop (and tablet)
  acc[tokenKey] = `
    ${typographyMobile[tokenKey]}
    @media (min-width: ${breakpoints?.tablet}) {
      ${typographyDesktop[tokenKey]}
    }
  `;
  return acc;
}, {} as TypographyTokens);
