import { useState, useEffect } from 'react';
import { breakpoints } from 'constants/Constants';

export type Breakpoint = 'mobile' | 'tablet' | 'desktop';

const useBreakpoint = (): Breakpoint => {
  const getBreakpoint = (): Breakpoint => {
    if (window.innerWidth >= parseInt(breakpoints?.desktop, 10)) {
      return 'desktop';
    } else if (window.innerWidth >= parseInt(breakpoints?.tablet, 10)) {
      return 'tablet';
    } else {
      return 'mobile';
    }
  };

  const [breakpoint, setBreakpoint] = useState<Breakpoint>(getBreakpoint());

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getBreakpoint());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

export default useBreakpoint;
