import React, { useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import * as Styled from './style';
import Select from 'react-select';
import { constants, screenSizes } from '../../constants/Constants';
import useCustomMediaQuery from '../../utils/useCustomMediaQuery';
import { useLayoutNavigation } from '../../hooks/layout-navigation-hook';
import { useApplicationPerformance } from '../../hooks/application-performance-hook';
import { useMetricsCriteria } from '../../hooks/useMetricsCriteria';
import { FilterContext } from '../../components/FilterBar/FilterProvider';
import { ThemeContext } from '../../context/ThemeContext';

const AssignmentGroupFilter = ({ ...localContext }) => {
  const {
    selectedAssignmentGroup,
    setSelectedAssignmentGroup,
    selectedFrequency,
    setSelectedFrequency,
  } = localContext;
  // const [selectedAssignmentGroup, setSelectedAssignmentGroup] = useState("All");
  // const [selectedFrequency, setSelectedFrequency] = useState("All");
  const { selectCustomStyles } = useLayoutNavigation();
  const isMobileView = useCustomMediaQuery(screenSizes.mobile);
  const {
    selectedProgram,
    selectedProduct,
    // selectedSector,
    // selectedMarket,
    // startUTCDate,
    // endUTCDate,
    // categories,
  } = useContext(FilterContext);
  const { mode } = useContext(ThemeContext);
  const { operationalMetricCriteria } = useMetricsCriteria();
  const { getDtcAssignmentGroup, dtcAssignmentGroupFilter } =
    useApplicationPerformance();

  const filteredAssignmentGroupDetails = dtcAssignmentGroupFilter
    ?.filter((item) => item.productId === selectedProduct.productId)
    ?.flatMap((item) => item.assignmentGroupDetails);

  const dtcAssignmentGroupOptions = [
    { groupId: 9999, groupName: 'All' },
    ...filteredAssignmentGroupDetails,
  ];
  const timeRangeOptions = [
    // { value: 7, label: "Daily" },
    { value: 28, label: 'Weekly' },
    { value: 182, label: 'Monthly' },
    // { value: 365, label: "Yearly" },
  ];

  const handleAssignmentGroupChange = (selectedOption) => {
    setSelectedAssignmentGroup(selectedOption);
  };
  const handleFrequencyChange = (selectedOption) => {
    setSelectedFrequency(selectedOption);
  };

  useEffect(() => {
    if (selectedProgram?.programId === constants?.programs?.DTC) {
      getDtcAssignmentGroup({
        variables: {
          productIds: selectedProduct?.productId,
        },
      });
    }
  }, [
    selectedProgram,
    selectedProduct,
    operationalMetricCriteria,
    getDtcAssignmentGroup,
  ]);

  return (
    <Styled.AssignmentGroupContainer
      data-testid="assignment-group-container"
      mode={mode}
    >
      <Grid
        container
        sm={12}
        spacing={0}
        justifyContent={isMobileView ? 'flex-start' : 'flex-end'}
        alignItems={'flex-start'}
      >
        <Grid
          container
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className="filterContainer"
        >
          <Grid xs={12} className="filterContainerText">
            {constants?.applicationPerformanceRedesigned?.assignmentGroup}
          </Grid>
          <Grid xs={12}>
            <Select
              options={dtcAssignmentGroupOptions?.map((item) => ({
                value: item.groupId,
                label: item.groupName,
              }))}
              isSearchable={false}
              placeholder={'All'}
              value={selectedAssignmentGroup || { value: 9999, label: 'All' }}
              styles={{
                ...selectCustomStyles,
                container: (provided) => ({ ...provided, maxWidth: '150px' }),
              }}
              onChange={handleAssignmentGroupChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          xs={5}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className="filterContainer"
        >
          <Grid xs={12} className="filterContainerText">
            {constants?.applicationPerformanceRedesigned?.frequency}
          </Grid>
          <Grid xs={12}>
            <Select
              options={timeRangeOptions}
              isSearchable={false}
              placeholder={'Monthly'}
              value={
                selectedFrequency ||
                timeRangeOptions.find((option) => option.label === 'Monthly')
              }
              styles={{
                ...selectCustomStyles,
                container: (provided) => ({ ...provided, maxWidth: '150px' }),
              }}
              onChange={handleFrequencyChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Styled.AssignmentGroupContainer>
  );
};

export default AssignmentGroupFilter;
