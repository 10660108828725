import styled from "styled-components";
import { color } from "../../theme/Color";

export const VerticalCardWithTrendContainer = styled.div`
  font-weight: 400;
  font-family: Inter;

  & .metricsValue {
    font-size: ${(props) =>
      props.istopkpi ? "32px !important" : "24px !important"};

    font-weight: 700;
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161}` : `${color._efefef}`};

    @media (max-width: 600px) {
      font-size: 24px !important;
    }

    @media (min-width: 600px) and (max-width: 1024px) {
      font-size: 24px !important;
    }
  }

  & .metricsDescription {
    font-size: 12px;
    font-weight: 700;
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161}` : `${color._d0d0d0}`};
    font-family: Inter;
    font-style: normal;
    line-height: 133%; /* 15.96px */
    text-transform: uppercase;
  }

  & .trendPositive {
    font-size: 12px;
    color: ${color._efefef};
    display: inline-block;
    background-color: ${color._006841};
    border-radius: 4px;
    padding: 2px 4px;
  }

  & .trendNegative {
    font-size: 12px;
    color: ${color._efefef};
    display: inline-block;
    background-color: ${color._A80000};
    border-radius: 4px;
    padding: 2px 4px;
  }
  & .font18 {
    font-size: 18px;
  }
`;
