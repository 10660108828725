import { gql } from '@apollo/client';

const getDigitalOrderingBehavior = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getAverageNetRevenueStore(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalNetRevenue
        digitalNetRevenue
        netRevenueInPercentage
      }
      errors
      responseCode
      responseMessage
    }
    getAverageOrderStore(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalOrderStore
        digitalOrderStore
        orderStoreInPercentage
      }
      errors
      responseCode
      responseMessage
    }
    getAverageOrderStoreSize(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        digitalOrderStore
        orderStoreInPercentage
        totalOrderStore
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getStoreAndUsage = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getStoreAndUsage(operationalMetricCriteria: $operationalMetricCriteria) {
      data {
        engagedStore
        totalStore
        rewardsRedeemingPercentage
        engagedStorePercentage
        redeemReward
        totalReward
      }
      errors
      responseCode
      responseMessage
    }
    getStoreAndUsageChart(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        totalPointsEarned
        totalPointsRedeemed
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getSalesAndDA = gql`
  query ($operationalMetricCriteria: OperationalMetricesRequestDTOInput!) {
    getSalesAndDALoyaltyTable(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        dprAndAmrAccrued
        dprAndAmrRealized
        dprAndAmrIncrementalNRAccrued
        dprAndAmrIncrementalNRRealized
      }
      errors
      responseCode
      responseMessage
    }
    getSalesAndDA(operationalMetricCriteria: $operationalMetricCriteria) {
      data {
        totalNetRevenue
        avgTimeInPlatform
      }
      errors
      responseCode
      responseMessage
    }
    getStoreSalesRevenueImpactDtl(
      operationalMetricCriteria: $operationalMetricCriteria
    ) {
      data {
        current {
          activeStores
          controlStores
        }
        ytd {
          activeStores
          controlStores
        }
        totalIncrementalCmYtd
        totalIncrementalCmImpactMonth
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getCanvasMarkets = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getCanvasMarkets(psdCriteria: $psdCriteria) {
      data {
        noOfMarkets
        noOfActiveMarkets
        trend {
          dateTime
          totalMarkets
          activeMarkets
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getCanvasBrands = gql`
  query ($psdCriteria: OperationalMetricesRequestDTOInput!) {
    getCanvasBrands(psdCriteria: $psdCriteria) {
      data {
        noOfBrands
        noOfActiveBrands
        trend {
          dateTime
          totalBrands
          activeBrands
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getCanvasScenarios = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getCanvasScenarios(psdCriteria: $psdCriteria) {
      data {
        noOfCreatedScenarios
        noOfOptimizedScenarios
        noOfUsersLvgScenariosPln
        trend {
          dateTime
          createdScenarios
          optimizedScenarios
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getCanvasOPScoreCard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getCanvasOPScoreCard(psdCriteria: $psdCriteria) {
      data {
        totalUserCount
        noOfMarkets
        noOfBrands
        noOfCreatedScenarios
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRegistersByInitiatives = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRegistersByInitiatives(psdCriteria: $psdCriteria) {
      data {
        campaignName
        registeredCount
        registeredPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getTotalInstallsTrend = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getTotalInstallsTrend(psdCriteria: $psdCriteria) {
      data {
        totalInstalls
        installsTargetYearly
        installsTrend {
          date
          target
          installs
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getTotalRegistersTrend = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getTotalRegistersTrend(psdCriteria: $psdCriteria) {
      data {
        totalRegisters
        registersTargetYearly
        registerTrend {
          date
          registers
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getDTCBusinessMetrics = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getDTCScoreCard(psdCriteria: $psdCriteria) {
      data {
        totalInstalls
        totalRegisters
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRegistersMix = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRegistersMix(psdCriteria: $psdCriteria) {
      data {
        totalRegByLeadGenPercentage
        totalRegByAppPercentage
        totalRegByWhatsAppPercentage
        totalRegByWebOrF4JPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getDtvScmMetrices = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getGapImproved(psdCriteria: $psdCriteria) {
      data {
        dateTime
        gapImprovedPercentage
        noOfSKUs
      }
      errors
      responseCode
      responseMessage
    }
    getTotalOpportunity(psdCriteria: $psdCriteria) {
      data {
        dateTime
        totalOpportunity
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getPersonaMappingToUsers = gql`
  query ($mctCriteria: MCTMetricsRequestDTOInput!) {
    getPersonaMappingToUsers(mctCriteria: $mctCriteria) {
      data {
        personaName
        mappedUsersPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAlertsByState = gql`
  query ($mctCriteria: MCTMetricsRequestDTOInput!) {
    getAlertsByState(mctCriteria: $mctCriteria) {
      data {
        openAlertsPercentage
        closedAlertsPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAlertsAssignmentGraph = gql`
  query ($mctCriteria: MCTMetricsRequestDTOInput!) {
    getAlertsAssignmentGraph(mctCriteria: $mctCriteria) {
      data {
        dateTime
        assignedAlerts
        unassignedAlerts
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAlertsBySeverityGraph = gql`
  query ($mctCriteria: MCTMetricsRequestDTOInput!) {
    getAlertsBySeverityGraph(mctCriteria: $mctCriteria) {
      data {
        dateTime
        severityDetail {
          severityType
          alertsCount
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getMCTVCScoreCard = gql`
  query ($mctCriteria: MCTMetricsRequestDTOInput!) {
    getMCTVCScoreCard(mctCriteria: $mctCriteria) {
      data {
        totalAlerts
        assignedAlertsPercentage
        criticalAlertsPercentage
        openAlertsPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getBusinessMetricsScoreCard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getBusinessMetricsScoreCard(psdCriteria: $psdCriteria) {
      data {
        restaurantsOnboarded
        highScoreRestaurantsPercentage
        restaurantsWithPepsiBeverage
        newMenusToAudit
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRestaurantsWithMenuScore = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRestaurantsWithMenuScore(psdCriteria: $psdCriteria) {
      data {
        dateTime
        restaurantsWithLowScore
        restaurantsWithMediumScore
        restaurantsWithHighScore
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRestaurantsWithBeverages = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRestaurantsWithBeverages(psdCriteria: $psdCriteria) {
      data {
        dateTime
        restaurantsWithPepsiBeverage
        restaurantsWithColaBeverage
        restaurantsWithBothBeverages
        restaurantsWithNone
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getDTVSCMValueCreationScoreCard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getDTVSCMValueCreationScoreCard(psdCriteria: $psdCriteria) {
      data {
        baselinesUploaded
        baselinesTemplatesDownloaded
        uploadedToDownloadRatio
        numberOfSKUsPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getMCTEfficiencyTrend = gql`
  query ($mctCriteria: MCTMetricsRequestDTOInput!) {
    getMCTEfficiencyTrend(mctCriteria: $mctCriteria) {
      data {
        trueEfficiencyLowerLimit
        trueEfficiencyUpperLimit
        dateTime
        trueEfficiencyPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getMCTUtilityTrend = gql`
  query ($mctCriteria: MCTMetricsRequestDTOInput!) {
    getMCTUtilityTrend(mctCriteria: $mctCriteria) {
      data {
        electricityConsumption
        waterConsumption
        dateTime
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getMCTQualityTrend = gql`
  query ($mctCriteria: MCTMetricsRequestDTOInput!) {
    getMCTQualityTrend(mctCriteria: $mctCriteria) {
      data {
        dateTime
        metric {
          metricName
          value
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGSNBATaskResponded = gql`
  query ($nbaCriteria: NBAMetricsRequestDTOInput!) {
    getGSNBATaskResponded(nbaCriteria: $nbaCriteria) {
      data {
        totalTaskResponded
        taskRespondedTrend {
          dateTime
          taskResponded
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getNBAValueCreationFilter = gql`
  query {
    getNBAValueCreationFilter {
      data {
        marketId
        nbaLocalFilterList {
          useCaseName
          useCaseDisplayName
          categoryListToDisplayMap
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGPGVCScorecard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getGPGVCScorecard(psdCriteria: $psdCriteria) {
      data {
        totalTransactions
        totalAuthRatePercentage
        averageCompletedPaymentValue
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getGPGLocalFilters = gql`
  query {
    getGPGLocalFilters {
      data {
        products {
          productId
          productName
          sectors {
            sectorId
            sectorName
            markets {
              marketId
              marketName
            }
          }
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getPaymentAuthRate = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getPaymentAuthRate(psdCriteria: $psdCriteria) {
      data {
        totalAuthRatePercentage
        trend {
          dateTime
          authRatePercentage
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRefundRate = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRefundRate(psdCriteria: $psdCriteria) {
      data {
        totalRefundRatePercentage
        refundTrend {
          dateTime
          refundRatePercentage
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getGPGTransactionMetrics = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getTotalTransactionTrend(psdCriteria: $psdCriteria) {
      data {
        dateTime
        totalTransactionCompleted
        totalTransactionValue
        currency
      }
      errors
      responseCode
      responseMessage
    }

    getAverageTransactionValue(psdCriteria: $psdCriteria) {
      data {
        totalCompletedPayment
        totalCompletedPaymentValue
        averageCompletedPaymentValue
        averageTransactionValueTrend {
          dateTime
          averageTransactionValuePercentage
          currency
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getProcessingDaysSavedData = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getProcessingDaysSaved(psdCriteria: $psdCriteria) {
      data {
        dateTime
        processingDays
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getArchletValueCreationScorecardData = gql`
  query ($procurementCriteria: ProcurementRequestDTOInput!) {
    getArchletScoreCard(procurementCriteria: $procurementCriteria) {
      data {
        totalBaselineSpend
        totalBaselineSpendPeriodComparison
        valueOpportunityIdentified
        valueOpportunityIdentifiedPeriodComparison
        totalProjectsCreated
        totalProjectsCreatedPeriodComparison
        totalHoursSaved
        totalHoursSavedPeriodComparison
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getArchletLocalFiltersData = gql`
  query ($sectorId: Int) {
    getArchletLocalFilters(sectorId: $sectorId) {
      data {
        sectorId
        sectorName
        categories {
          categoryName
          subCategories {
            subCategoryName
            owners
          }
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getArchletValueSummaryData = gql`
  query ($procurementCriteria: ProcurementRequestDTOInput!) {
    getArchletValueCreationSummary(procurementCriteria: $procurementCriteria) {
      data {
        averagePriceChangeR1toR2
        averagePriceChangeR1toR2PeriodComparison
        averagePriceChangeR2toR3
        averagePriceChangeR2toR3PeriodComparison
        averageBiddingRounds
        averageBiddingRoundsPeriodComparison
        totalScenariosCreated
        totalScenariosCreatedPeriodComparison
        averageScenariosCreated
        averageScenariosCreatedPeriodComparison
        valueOpportunityTrend {
          valueOpportunitiesIdentified
          totalProjectsCreated
          dateTime
        }
        timeSavedTrend {
          averageScenarioCreated
          totalHoursSaved
          dateTime
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getProjectSources = gql`
  query ($procurementCriteria: ProcurementRequestDTOInput!) {
    getProjectSources(procurementCriteria: $procurementCriteria) {
      data {
        dateTime
        totalValue
        metric {
          metricName
          value
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getProjectsCreatedByStatus = gql`
  query ($procurementCriteria: ProcurementRequestDTOInput!) {
    getProjectsCreatedByStatus(procurementCriteria: $procurementCriteria) {
      data {
        dateTime
        totalValue
        metric {
          metricName
          value
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getProjectsReachingAwardedBid = gql`
  query ($procurementCriteria: ProcurementRequestDTOInput!) {
    getProjectsReachingAwardedBid(procurementCriteria: $procurementCriteria) {
      data {
        dateTime
        totalValue
        metric {
          metricName
          value
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getProjectsCreatedByCategory = gql`
  query ($procurementCriteria: ProcurementRequestDTOInput!) {
    getProjectsCreatedByCategory(procurementCriteria: $procurementCriteria) {
      data {
        category
        totalValue
        metric {
          metricName
          value
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

export {
  getDigitalOrderingBehavior,
  getStoreAndUsage,
  getSalesAndDA,
  getCanvasMarkets,
  getCanvasBrands,
  getCanvasScenarios,
  getCanvasOPScoreCard,
  getRegistersByInitiatives,
  getTotalInstallsTrend,
  getTotalRegistersTrend,
  getDTCBusinessMetrics,
  getRegistersMix,
  getPersonaMappingToUsers,
  getAlertsByState,
  getAlertsAssignmentGraph,
  getAlertsBySeverityGraph,
  getMCTVCScoreCard,
  getDtvScmMetrices,
  getBusinessMetricsScoreCard,
  getRestaurantsWithMenuScore,
  getRestaurantsWithBeverages,
  getDTVSCMValueCreationScoreCard,
  getMCTEfficiencyTrend,
  getGSNBATaskResponded,
  getNBAValueCreationFilter,
  getGPGVCScorecard,
  getGPGTransactionMetrics,
  getGPGLocalFilters,
  getPaymentAuthRate,
  getRefundRate,
  getMCTQualityTrend,
  getMCTUtilityTrend,
  getProcessingDaysSavedData,
  getArchletValueCreationScorecardData,
  getArchletLocalFiltersData,
  getArchletValueSummaryData,
  getProjectSources,
  getProjectsCreatedByStatus,
  getProjectsReachingAwardedBid,
  getProjectsCreatedByCategory,
};
