import styled from 'styled-components';
import { Container } from '@mui/system';
import { color } from '../../theme/Color';

interface ThemeProps {
  themeMode: string;
}

export const NoDataFoundContainer = styled(Container)<ThemeProps>`
  padding: 0 !important;

  & .noDataFound {
    border: ${({ themeMode }) =>
      themeMode === 'light'
        ? `1px solid ${color._D7E5F8}`
        : `1px solid rgba(255, 255, 255, 0.2)`};
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 270px;
  }
`;
