import React, { useContext } from "react";
import { Grid } from "@mui/material";
import * as Styled from "./style";
import { ThemeContext } from "../../context/ThemeContext";
import { color } from "../../theme/Color";

interface NoDataFoundProps {}

const NoDataFound: React.FC<NoDataFoundProps> = () => {
  const { mode } = useContext(ThemeContext);
  return (
    <Styled.StyledGridContainer
      item
      container
      alignItems="center"
      justifyContent="center"
      data-testid="no-data-found"
      mode={mode}
    >
      <Grid item>
        <Styled.StyledBox>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="41"
            viewBox="0 0 41 41"
            style={{ marginBottom: "16px" }}
          >
            <path
              d="M30 9H36L28 1L20 9H26V17.34L30 21.34V9Z"
              fill={mode === "light" ? `${color._3a3a3a}` : `${color._D0D0D0}`}
            />
            <path
              d="M3.62003 0.619141L0.780029 3.43914L14 16.6591V29.0191L8.00003 29.0391L16 36.9991L24 28.9991L18 29.0191V20.6591L37.56 40.2191L40.38 37.3791L3.62003 0.619141Z"
              fill={mode === "light" ? `${color._3a3a3a}` : `${color._D0D0D0}`}
            />
          </svg>
          <Styled.StyledErrorText>No data found</Styled.StyledErrorText>
        </Styled.StyledBox>
      </Grid>
    </Styled.StyledGridContainer>
  );
};

export default NoDataFound;
