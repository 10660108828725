import styled from 'styled-components';

export const InvisibleDivider = styled.div`
  height: 1px;
  margin: 16px 0;
  visibility: hidden;
`;

export const GridContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme?.colors?.backgroundLayer1};
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px; /* spacing between cells */
`;

interface CellProps {
  showBorder: boolean;
  isLastCell: boolean;
}

export const Cell = styled.div<CellProps>`
  flex: 1;
  padding: 8px; /* each cell has 8px padding */
  position: relative;
  border-right: ${({ showBorder, isLastCell, theme }) =>
    showBorder && !isLastCell ? `1px solid ${theme?.colors?.border}` : 'none'};
`;

// New prop for the HorizontalDivider to remove bottom margin if needed.
interface HorizontalDividerProps {
  lastRowDivider?: boolean;
}

export const HorizontalDivider = styled.div<HorizontalDividerProps>`
  height: 1px;
  background-color: ${({ theme }) => theme?.colors?.horizontalDivider};
  margin: ${({ lastRowDivider }) => (lastRowDivider ? '16px 0 0 0' : '16px 0')};
`;
