import React, {
  Suspense,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react';
import {
  Routes as Router,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  getScreenResolution,
  setCustomPageName,
  setCustomUserData,
} from '../utils/AppDUtil';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import Dashboard from '../containers/Dashboard/Dashboard';
import { useLayoutNavigation } from '../hooks/layout-navigation-hook';
import OnboardingPage from '../containers/OnboardingPage/OnboardingPage';
import Layout from '../layout/Layout';
import { constants } from '../constants/Constants';
import { helperFunctions } from '../utils/helperFunctions';
import { useOkta } from 'hooks/okta-hooks';
import PowerBIDashboard from 'containers/PowerBI/PowerBIDashboard';
import { FilterContext } from '../components/FilterBar/FilterProvider';
import { AppProvider } from 'context/AppContext';
import KpiDefinitionDrawer from 'components/redesign/kpiDefinitionDrawer';
// import KpiWidgetTest from 'v2/components/KpiWidgetTest';

// Import Redux hooks and auth slice actions
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials, clearCredentials } from '../store/slices/authSlice';

const Routes = () => {
  const { betaFeatureToggle } = useContext(FilterContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const { authenticate } = useOkta();
  const [idleTimer, setIdleTimer] = useState(null);
  const { getUserRoles } = useLayoutNavigation();
  const { debounce } = helperFunctions();
  const dispatch = useDispatch();

  // (Optional) Retrieve auth state from Redux if needed.
  const reduxAuth = useSelector((state) => state.auth);

  // This function checks the Okta token and updates Redux state accordingly.
  const getOktaStorageToken = () => {
    oktaAuth.tokenManager.get('accessToken').then((accessToken) => {
      const accessTokenExpiresAt = accessToken?.expiresAt;
      const epochTime = Math.floor(new Date().getTime() / 1000);
      if (accessTokenExpiresAt && accessTokenExpiresAt <= epochTime) {
        localStorage.clear();
        dispatch(clearCredentials());
        oktaAuth.signOut();
        window.location.href = '/';
      } else {
        getUserRoles();
        if (accessToken && accessToken.claims) {
          dispatch(
            setCredentials({
              user: accessToken.claims,
              token: accessToken.accessToken,
            })
          );
          return accessToken.claims;
        }
      }
    });
  };

  const debouncedHandleIdle = useCallback(
    debounce(() => {
      // Record the timestamp when the user is idle
      setIdleTimer(Date.now());
    }, 1000),
    [debounce]
  );

  useEffect(() => {
    const checkIdleAndToken = () => {
      oktaAuth.tokenManager.get('accessToken').then((accessToken) => {
        const accessTokenExpiresAt = accessToken?.expiresAt;
        const epochTime = Math.floor(new Date().getTime() / 1000);
        if (accessTokenExpiresAt && accessTokenExpiresAt <= epochTime) {
          localStorage.clear();
          dispatch(clearCredentials());
          oktaAuth.signOut();
          window.location.href = '/';
        } else if (idleTimer && authState.isAuthenticated) {
          const idleTime = Date.now() - idleTimer;
          if (idleTime >= constants?.IDLE_TIME) {
            localStorage.clear();
            dispatch(clearCredentials());
            oktaAuth.signOut();
            window.location.href = '/';
          }
        }
      });
    };

    // Set up event listeners for user activity
    document.addEventListener('click', debouncedHandleIdle);
    document.addEventListener('wheel', debouncedHandleIdle);
    document.addEventListener('keydown', debouncedHandleIdle);

    // Check idle status and token expiry every minute
    const timerInterval = setInterval(checkIdleAndToken, 60000);

    return () => {
      document.removeEventListener('click', debouncedHandleIdle);
      document.removeEventListener('wheel', debouncedHandleIdle);
      document.removeEventListener('keydown', debouncedHandleIdle);
      clearInterval(timerInterval);
    };
  }, [idleTimer, authState, oktaAuth, debouncedHandleIdle, dispatch]);

  useEffect(() => {
    console.log('AppD: page has been navigated to:', pathname);
    const oktaTokenStorageData = getOktaStorageToken();
    setCustomUserData({
      gpid: oktaTokenStorageData?.gpid || '',
      email: oktaTokenStorageData?.email || '',
      FirstName: oktaTokenStorageData?.FirstName || '',
      LastName: oktaTokenStorageData?.LastName || '',
      screenResolution: getScreenResolution(),
      language: navigator?.language,
    });

    setCustomPageName(pathname);
  }, [pathname, authState, navigate]);

  // Redirect from /psdlite if betaFeatureToggle is disabled
  useEffect(() => {
    if (!betaFeatureToggle && pathname === '/psdlite') {
      navigate('/dashboard');
    }
  }, [betaFeatureToggle, pathname, navigate]);

  // Use Redux state (or fallback to local storage) to determine if the user is authenticated
  const isAuthenticated = () => {
    return (
      authState?.isAuthenticated ||
      reduxAuth?.isAuthenticated ||
      JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken
    );
  };

  const renderPage = (Component) => {
    if (!isAuthenticated()) {
      authenticate();
      return null; // Prevent rendering until authenticated
    } else {
      return (
        <AppProvider>
          <Layout>
            <Component />
            <KpiDefinitionDrawer />
          </Layout>
        </AppProvider>
      );
    }
  };

  const renderDashboardPage = () => renderPage(Dashboard);
  const renderPSDLitePage = () => {
    if (!isAuthenticated()) {
      authenticate();
      return null; // Prevent rendering until authenticated
    } else if (betaFeatureToggle) {
      return renderPage(PowerBIDashboard);
    } else {
      return null;
    }
  };

  return (
    <Suspense>
      <Router>
        <Route path="/login" title="Login" element={<LoginCallback />} />
        <Route
          path="/logout"
          title="Logout"
          element={<OnboardingPage showLogout={true} />}
        />
        <Route
          path="/dashboard"
          title="Dashboard"
          element={renderDashboardPage()}
        />
        <Route
          path="/psdlite"
          title="PSD Lite Dashboard"
          element={renderPSDLitePage()}
        />
        {/* <Route
          path="/kpi-test"
          title="PSD KPI Test Page"
          element={<KpiWidgetTest />}
        /> */}
        <Route
          path="/"
          title="ExecutiveOverView"
          exact={true}
          element={renderDashboardPage()}
        />
      </Router>
    </Suspense>
  );
};

export default Routes;
