import React, { useContext } from "react";
import * as Styled from "./style";
import { ThemeContext } from "../../context/ThemeContext";


const VerticalDividerCard = ({
  numbers,
  description,
  abbreviation,
  optionalSubtext = "",
  isFullStory = false,
  isMCT = false
}) => {
  const { mode } = useContext(ThemeContext);
  return (
    <>
      <Styled.NumberContainer
        variant="h5"
        className="quantityContainer"
        data-accessible={true}
        data-testid="quantity-container"
        isFullStory={isFullStory}
        mode={mode} 
      >
        {/* Existing code */}
        {!isFullStory && (
          <>
            <span>{numbers}</span>
            <span>{abbreviation}</span>
          </>
        )}

        {isFullStory && (
          <>
            <span>{numbers}</span>
            <span className="abbreviation">{abbreviation}</span>
          </>
        )}
      </Styled.NumberContainer>
      <Styled.DescriptionContainer
        variant="span"
        data-accessible={true}
        data-testid="desc-container"
        mode={mode} 
        isMCT={isMCT}
      >
        {description}
      </Styled.DescriptionContainer>

      <Styled.SubTextContainer
        variant="span"
        data-accessible={true}
        data-testid="desc-container"
        mode={mode} 
      >
        {optionalSubtext}
      </Styled.SubTextContainer>
    </>
  );
};

export default VerticalDividerCard;
