export enum LogLevel {
  DEBUG = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4,
}

export interface LoggerConfig {
  /** Minimum level to log (logs with a lower level will be ignored) */
  level: LogLevel;
  /** If true, remote logging is enabled */
  remoteLoggingEnabled?: boolean;
  /** The URL to POST log messages to */
  remoteLoggingUrl?: string;
  /** Tag or label for the current environment (e.g., development or production) */
  environment?: string;
  /**
   * Alternatively, you can provide a custom function to handle remote logging.
   * This function should accept the log data and return a Promise.
   */
  remoteLoggerFn?: (logData: any) => Promise<void>;
}

export class Logger {
  private readonly config: LoggerConfig;

  constructor(config: LoggerConfig) {
    this.config = config;
    this.setupGlobalErrorHandlers();
  }

  /**
   * Checks if the given log level should be logged based on the configuration.
   */
  private shouldLog(level: LogLevel): boolean {
    return level >= this.config.level;
  }

  /**
   * Formats a log message into a standard object.
   */
  private formatMessage(level: LogLevel, message: string, data?: any): any {
    return {
      timestamp: new Date().toISOString(),
      level: LogLevel[level], // converts enum value to string (e.g., "DEBUG")
      message,
      data,
      environment: this.config.environment || 'development',
    };
  }

  /**
   * Sends the log entry to a remote server or custom function, if configured.
   */
  private async sendRemote(logEntry: any): Promise<void> {
    if (this.config.remoteLoggingEnabled && this.config.remoteLoggingUrl) {
      try {
        await fetch(this.config.remoteLoggingUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(logEntry),
        });
      } catch (error) {
        // Fall back to console.error if remote logging fails.
        console.error('Remote logging failed:', error);
      }
    } else if (this.config.remoteLoggerFn) {
      try {
        await this.config.remoteLoggerFn(logEntry);
      } catch (error) {
        console.error('Remote logging (custom function) failed:', error);
      }
    }
  }

  /**
   * Outputs the log to the browser console.
   */
  private output(logEntry: any): void {
    switch (logEntry.level) {
      case 'DEBUG':
        console.debug(logEntry);
        break;
      case 'INFO':
        console.info(logEntry);
        break;
      case 'WARN':
        console.warn(logEntry);
        break;
      case 'ERROR':
        console.error(logEntry);
        break;
      default:
        console.log(logEntry);
    }
  }

  /**
   * The main log function that all level-specific methods use.
   */
  public async log(
    level: LogLevel,
    message: string,
    data?: any
  ): Promise<void> {
    if (!this.shouldLog(level)) {
      return;
    }

    const logEntry = this.formatMessage(level, message, data);
    this.output(logEntry);
    await this.sendRemote(logEntry);
  }

  // Convenience methods for each log level:
  public async debug(message: string, data?: any): Promise<void> {
    await this.log(LogLevel.DEBUG, message, data);
  }

  public async info(message: string, data?: any): Promise<void> {
    await this.log(LogLevel.INFO, message, data);
  }

  public async warn(message: string, data?: any): Promise<void> {
    await this.log(LogLevel.WARN, message, data);
  }

  public async error(message: string, data?: any): Promise<void> {
    await this.log(LogLevel.ERROR, message, data);
  }

  /**
   * Attach global error handlers to capture unhandled errors and promise rejections.
   */
  private setupGlobalErrorHandlers(): void {
    // Capture unhandled JavaScript errors
    window.addEventListener('error', (event) => {
      this.error('Unhandled error captured by global handler', {
        message: event.message,
        filename: event.filename,
        lineno: event.lineno,
        colno: event.colno,
        error: event.error,
      });
    });

    // Capture unhandled promise rejections
    window.addEventListener('unhandledrejection', (event) => {
      this.error('Unhandled promise rejection captured by global handler', {
        reason: event.reason,
      });
    });
  }
}
