import { gql } from '@apollo/client';

const getIncidentMonitoring = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getIncidentTrend(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        dateTime
        incidentCount
        resolvedWithInSla
        responseWithInSla
      }
      errors
      responseCode
      responseMessage
    }

    getIncidentMonitoringOverview(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        incidentCount
        responseTime
        vsPlanResponseTime
        resolutionTime
        vsPlanResolutionTime
      }
      errors
      responseCode
      responseMessage
    }

    getIncidentMonitoringPriorityLevelGraph(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dateTime
        sumOfIncidentCount
        priorityDetail {
          priority
          incidentCount
        }
      }
      errors
      responseCode
      responseMessage
    }

    getIncidentMonitoringOpenAndClose(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        openIncident
        closedIncident
      }
      errors
      responseCode
      responseMessage
    }

    getIncidentMonitoringSlaAnalysis(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        slaType
        incidentCount
        incidentPercentage
        incidentOverallAvgPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getNRMAppPerformanceScoreCard = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getAppPerformanceScorecard(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        avgApiAppResponseTime
        avgApiAppResponseTimeDelta
        totalNoOfErrors
        totalNoOfErrorsDelta
        applicationErrors
        applicationErrorsDelta
        calendarGenerationRunTime
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getDtcAssignmentGroupFilter = gql`
  query getAssignmentGroupFilter($productIds: [Int!]!) {
    getAssignmentGroupFilter(productIds: $productIds) {
      data {
        productId
        assignmentGroupDetails {
          groupId
          groupName
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getApiCallsTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getApiCallsTrend(applicationMetricCriteria: $applicationMetricCriteria) {
      data {
        dateTime
        value
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getBrowserErrorTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getBrowserErrorTrend(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dateTime
        value
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getPageResponseTrend = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getPageResponseTrend(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        dateTime
        apiDetails {
          apiName
          responseTimeInMs
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;
const getCanvasAppPerformanceScoreCard = gql`
  query ($applicationMetricCriteria: ApplicationMetricsRequestDTOInput!) {
    getAppPerformanceScorecard(
      applicationMetricCriteria: $applicationMetricCriteria
    ) {
      data {
        avgApiAppResponseTime
        avgApiAppResponseTimeDelta
        totalNoOfErrors
        totalNoOfErrorsDelta
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

export {
  getIncidentMonitoring,
  getNRMAppPerformanceScoreCard,
  getCanvasAppPerformanceScoreCard,
  getApiCallsTrend,
  getBrowserErrorTrend,
  getPageResponseTrend,
  getDtcAssignmentGroupFilter,
};
