import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import TableView from '../../components/TableView/TableView';
import KpiDefinitionDrawer from '../../components/KpiDefinitionDrawer/KpiDefinitionDrawer';
import KpiDefinitionData from '../../service/KpiDefinitionData';
import SLAAnalysis from '../../components/SLAAnalysis/SLAAnalysis';
import IncidentTrend from '../../components/IncidentTrend/IncidentTrend';
import IncidentMonitoringDetails from '../../components/IncidentMonitoringDetails/IncidentMonitoringDetails';
import { constants, screenSizes } from '../../constants/Constants';
import Loader from '../../components/Loader/Loader';
import ToggleButtonContainer from './ToggleButtonContainer';
import useCustomMediaQuery from '../../utils/useCustomMediaQuery';
import ApdexScoreCard from '../../components/ApdexScoreCard';
import { helperFunctions } from '../../utils/helperFunctions';
import AssignmentGroupFilter from './AssignmentGroupFilter';
import { color } from '../../theme/Color';
import { ThemeContext } from '../../context/ThemeContext';

const IncidentMonitoringView = ({
  selectedProduct,
  applicationNames,
  setAssignedGroup,
  application,
  setApplications,
  incidentMonitoringTableData,
  incidentTrendData,
  incidentMonitoringSlaAnalysisData,
  incidentMonitoringOverviewData,
  incidentMonitoringOpenAndCloseData,
  incidentMonitoringPriorityLevelGraphData,
  apdexScoreData,
  slaAnalysisLoading,
  incidentTrendLoading,
  incidentMonitoringDetailsLoading,
  apdexScoreLoading,
  dataDefinition = null,
  showApdexScore = false,
  showIncidentMonitoringDetails = true,
  appPerformanceProps,
  showIncidentMonitoringHeader = true,
  setLocalFilters = (filter) => {},
}) => {
  const { mode } = useContext(ThemeContext);
  const defaultAssignmentGroup = { value: 9999, label: 'All' };
  const defaultFrequency = { value: 182, label: 'Monthly' };
  const [selectedAssignmentGroup, setSelectedAssignmentGroup] = useState(
    defaultAssignmentGroup
  );
  const [selectedFrequency, setSelectedFrequency] = useState(defaultFrequency);
  const { calculateLgForThreeBlocks } = helperFunctions();
  const isMobileView = useCustomMediaQuery(screenSizes.mobile);
  const ipadMiniView = useCustomMediaQuery(screenSizes.ipadMini);
  const ipaAirView = useCustomMediaQuery(screenSizes.ipadAir);
  let showIncidentTrend = appPerformanceProps?.getIncidentTrend;
  let showSLAAnalysis = appPerformanceProps?.getIncidentMonitoringSlaAnalysis;
  let showIncidentMonitoringTableView =
    appPerformanceProps?.getIncidentMonitoringTableView;

  // handle the visiblility on the basis of products which are not config based yet
  if (
    selectedProduct?.productId === constants.products.ShipmentVisibilty ||
    selectedProduct?.productId === constants.products.Canvas ||
    selectedProduct?.productId == constants.products.PepsiConnect
  ) {
    showIncidentTrend = true;
    showSLAAnalysis = true;
    showIncidentMonitoringTableView = true;
  }

  const handleApplicationChange = (event, newAlignment) => {
    const appNames = newAlignment === null ? applicationNames : [newAlignment];
    setAssignedGroup(appNames);
    setApplications(newAlignment);
  };

  const [localContext, setLocalContext] = useState({
    selectedAssignmentGroup,
    setSelectedAssignmentGroup,
    selectedFrequency,
    setSelectedFrequency,
  });

  useEffect(() => {
    setLocalContext({
      selectedAssignmentGroup,
      setSelectedAssignmentGroup,
      selectedFrequency,
      setSelectedFrequency,
    });
  }, [selectedAssignmentGroup, selectedFrequency]);

  useEffect(() => {
    setLocalFilters(localContext);
  }, [localContext]);

  const renderSlaAnalysis = () => (
    <SLAAnalysis
      incidentMonitoringSlaAnalysisData={incidentMonitoringSlaAnalysisData}
      paddingTop={'20px'}
      paddingBottomInnerContainer={'10px'}
    />
  );
  const renderIncidentMonitoringDetails = () =>
    incidentMonitoringDetailsLoading ? (
      <Loader />
    ) : (
      <IncidentMonitoringDetails
        incidentMonitoringOverviewData={incidentMonitoringOverviewData}
        incidentMonitoringOpenAndCloseData={incidentMonitoringOpenAndCloseData}
        incidentMonitoringPriorityLevelGraphData={
          incidentMonitoringPriorityLevelGraphData
        }
        mixLineChartHeight={'200px'}
        legendBottom={'1%'}
        selectedFrequency={selectedFrequency}
      />
    );

  const { block1Lg: incidentTrendLg, block3Lg: apdexScoreLg } =
    calculateLgForThreeBlocks(
      showIncidentTrend,
      showSLAAnalysis,
      showApdexScore
    );

  const headerStyle = (mode, color) => ({
    fontFamily: 'Inter',
    color: mode === 'light' ? color._616161 : color._efefef,
    fontWeight: 700,
    fontSize: '18px',
    width: '100%',
    marginTop: '20px',
    '@media (max-width: 768px)': {
      fontSize: '16px',
    },
  });

  return (
    <Grid
      container
      spacing={2}
      justifyContent={'space-between'}
      alignItems={'center'}
      data-testid="incident-monitoring-view-container"
    >
      {isMobileView ? (
        <>
          <Grid item xs={12}>
            {showIncidentMonitoringHeader && (
              <Typography style={headerStyle(mode, color)}>
                {
                  constants?.applicationPerformanceRedesigned
                    ?.incidentMonitoring
                }
                <KpiDefinitionDrawer
                  title={constants?.applicationPerformance?.incidentMonitoring}
                  KpiDefinitionList={
                    dataDefinition ??
                    KpiDefinitionData[selectedProduct?.productId]
                      ?.applicationPerformance?.incidentMonitoring ??
                    []
                  }
                />
              </Typography>
            )}
          </Grid>
          {(selectedProduct?.productId === constants.products.DTC ||
            selectedProduct?.productId ===
              constants.products.PepsiTastyRewards ||
            selectedProduct?.productId === constants.products.Kazandirio) &&
            showIncidentMonitoringHeader && (
              <Grid xs={12} item>
                <AssignmentGroupFilter {...localContext} />
              </Grid>
            )}
          {!isMobileView && showIncidentMonitoringTableView && (
            <Grid
              item
              xs={5}
              className="placeholder"
              container
              justifyContent={'end'}
            >
              <TableView
                IncidentMonitoringTableData={incidentMonitoringTableData}
              />
            </Grid>
          )}
          {selectedProduct?.productId === constants.products.PepsiConnect && (
            <Grid item xs={12} className="placeholder">
              <ToggleButtonContainer
                value={application}
                onChange={handleApplicationChange}
                isMobileView={isMobileView}
                incidentToggle={true}
                names={applicationNames}
              />
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid item xs={12} sm={ipadMiniView ? 4.5 : 4} md={5}>
            {showIncidentMonitoringHeader && (
              <Typography style={headerStyle(mode, color)}>
                {
                  constants?.applicationPerformanceRedesigned
                    ?.incidentMonitoring
                }
                <KpiDefinitionDrawer
                  title={constants?.applicationPerformance?.incidentMonitoring}
                  KpiDefinitionList={
                    dataDefinition ??
                    KpiDefinitionData[selectedProduct?.productId]
                      ?.applicationPerformance?.incidentMonitoring ??
                    []
                  }
                />
              </Typography>
            )}
          </Grid>
          {selectedProduct?.productId === constants.products.PepsiConnect && (
            <Grid
              item
              xs={12}
              sm={ipadMiniView ? 4.5 : 2}
              md={2}
              className="placeholder"
            >
              <ToggleButtonContainer
                value={application}
                onChange={handleApplicationChange}
                names={applicationNames}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={ipadMiniView || ipaAirView ? 7 : 3}
            md={5}
            className="placeholder"
            container
            justifyContent={'end'}
            alignItems={'end'}
          >
            {(selectedProduct?.productId === constants.products.DTC ||
              selectedProduct?.productId ===
                constants.products.PepsiTastyRewards ||
              selectedProduct?.productId === constants.products.Kazandirio) &&
              showIncidentMonitoringHeader && (
                <Grid xs={12} sm={8} item>
                  <AssignmentGroupFilter {...localContext} />
                </Grid>
              )}
            {showIncidentMonitoringTableView && (
              <TableView
                IncidentMonitoringTableData={incidentMonitoringTableData}
              />
            )}
          </Grid>
        </>
      )}
      <Grid
        container
        spacing={2}
        style={{ marginTop: '20px', marginLeft: '1px' }}
        xs={12}
      >
        {showIncidentTrend && (
          <Grid item xs={12} lg={incidentTrendLg} style={{ flexGrow: 1 }}>
            <Stack className="card-panel">
              {incidentTrendLoading ? (
                <Loader />
              ) : (
                <IncidentTrend
                  incidentTrendData={incidentTrendData}
                  selectedFrequency={selectedFrequency}
                />
              )}
            </Stack>
          </Grid>
        )}
        {showSLAAnalysis && (
          <Grid
            item
            xs={12}
            sm={showApdexScore ? 8 : 12}
            lg={6}
            style={{ flexGrow: 1, display: 'flex' }}
          >
            <Stack className="card-panel" style={{ flexGrow: 1 }}>
              {slaAnalysisLoading ? <Loader /> : renderSlaAnalysis()}
            </Stack>
          </Grid>
        )}
      </Grid>

      {showApdexScore && (
        <Grid item xs={12} sm={4} lg={apdexScoreLg}>
          <Stack className="card">
            <ApdexScoreCard
              apdexScoreData={apdexScoreData}
              titleTextStyle={false}
              apdexScoreLoading={apdexScoreLoading}
            />
          </Stack>
        </Grid>
      )}
      {showIncidentMonitoringDetails && (
        <Grid item xs={12}>
          <Stack className="card-panel">
            {renderIncidentMonitoringDetails()}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default IncidentMonitoringView;
