import { useContext, useMemo } from "react";
import { FilterContext } from "../components/FilterBar/FilterProvider";
export const useMetricsCriteria = () => {
  const {
    selectedProgram,
    selectedProduct,
    selectedSector,
    selectedMarket,
    startUTCDate,
    endUTCDate,
    categories,
    ytdCheckbox,
    selectedCustomer
  } = useContext(FilterContext);
  const operationalMetricCriteria = useMemo(
    () => ({
      programId: selectedProgram?.programId || 1,
      productId: selectedProduct?.productId || 1,
      sectorId: selectedSector?.sectorId || 1,
      marketId: selectedMarket?.marketId || null,
      startDate: startUTCDate,
      endDate: endUTCDate,
      year: ytdCheckbox ? new Date(startUTCDate).getUTCFullYear() : null,
      customerId: selectedCustomer?.id || null
    }),
    [
      selectedProgram,
      selectedProduct,
      selectedSector,
      selectedMarket,
      startUTCDate,
      endUTCDate,
      ytdCheckbox,
    ]
  );

  return { operationalMetricCriteria, categories };
};
