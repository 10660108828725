import styled from 'styled-components';
import { GridContainerProps } from './types';
import { breakpoints } from '../../../constants/Constants';

export const StyledGridContainer = styled.div<GridContainerProps>`
  display: grid;
  /* Mobile by default */
  grid-template-columns: repeat(${(props) => props.columnsMobile || 1}, 1fr);
  gap: ${(props) => props.gap || '16px'};

  /* Tablet */
  @media (min-width: ${breakpoints?.tablet}) {
    grid-template-columns: repeat(${(props) => props.columnsTablet || 2}, 1fr);
  }

  /* Desktop */
  @media (min-width: ${breakpoints?.desktop}) {
    grid-template-columns: repeat(${(props) => props.columnsDesktop || 2}, 1fr);
  }
`;
