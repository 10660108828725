import React, { useEffect, useRef, useState } from "react";
import { useFeatureIsOn, useGrowthBook } from "@growthbook/growthbook-react";
import { featureFlags } from "constants/Constants.js";
import { useOktaAuth } from "@okta/okta-react";

// const getOktaAccessToken = () => {
//   return JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken;
// };

export const GrowthbookWrapper = ({ children }) => {
  const growthBook = useGrowthBook();
  const maintenance = useFeatureIsOn(featureFlags?.MAINTENANCE || false);
  const retryTimeoutId = useRef();
  const {oktaAuth} = useOktaAuth();
  const retryCount = useRef(0);
  
  const getOktaAccessToken = async() => {
  const accessToken = await oktaAuth.tokenManager.get('accessToken');
  return accessToken? accessToken : null;
};
  const [accessToken] = useState(
    getOktaAccessToken()?.accessToken
  );

//   useEffect(() => {
//     const handleChange = (e) =>
//       setAccessToken(e.detail?.accessToken?.accessToken);
//     // Keeps track of Okta changes on the access token so we can trigger a
//     // reload of features when it happens. This allows us to more quickly
//     // cover cases in which the loading is failing due to an expired token,
//     // instead of waiting for a retry to happen after the token is refreshed.
//     document.addEventListener(oktaEvents.tokenChange, handleChange);
//     return () =>
//       document.removeEventListener(oktaEvents.tokenChange, handleChange);
//   }, []);

//   useEffect(() => {
//     const tokenClaims = getOktaAccessToken()?.claims;
//     updateGrowthBookAttributes({
//       id: tokenClaims?.gpid,
//       accountId: commonReducer?.customerId,
//       gtmuId: commonReducer.gtmuId,
//     });
//   }, [commonReducer?.customerId]);

  useEffect(() => {
    const abortController = new AbortController();

    const loadFeatures = async () => {
      try {
        log('Loading features');
        await growthBook.loadFeatures({ skipCache: true, autoRefresh: true });
        if (Object.keys(growthBook.getFeatures()).length === 0) {
          throw new Error('No features loaded');
        }
        retryCount.current = 0;
        log('Features loaded');
        log('growthbook features'+JSON.stringify(growthBook.getFeatures()));
      } catch (error) {
        log(`Loading failed, retry #${retryCount.current + 1}`);
        retry();
      }
    };

    const retry = () => {
      const baseDelay = 2000;
      const maxDelay = 300000;
      const delay = Math.min(baseDelay * Math.pow(3, retryCount.current), maxDelay);
      retryCount.current++;
      retryTimeoutId.current = setTimeout(loadFeatures, delay);
    };

    loadFeatures();

    return () => {
      clearTimeout(retryTimeoutId.current);
      abortController.abort();
      log('Retry timeout cleared, effect cleanup');
    };
  }, [growthBook, accessToken, log]);

  return maintenance ? (
    <>
      Application Down
    </>
  ) : (
    children
  );
};

function log(message) {
  console.log(`GrowthBook: ${message}`);
}
