import React, { useContext, useEffect, useState } from 'react';
import KpiDefinitionData from 'service/KpiDefinitionData';
import { useOverview } from '../../../../hooks/overview-hook';
import { useMetricsCriteria } from '../../../../hooks/useMetricsCriteria';
import { FilterContext } from '../../../../components/FilterBar/FilterProvider';
import CardContainer from '../../../../components/redesign/CardContainer';
import { ScoreCardItemData } from '../../../../components/redesign/ScoreCard/ScoreCardItem/types';
import KpiGrid from '../../../../components/redesign/KpiGrid';
import KpiTile from 'components/redesign/KpiTile';
import totalUsers from '../../../../v2/kpis/rgm/totalUsers';
import activeUsers from '../../../../v2/kpis/rgm/activeUsers';
import adoptionRate from '../../../../v2/kpis/rgm/adoptionRate';

interface ValueCreationNrmProps {
  slug: string;
  title: string;
}
const SummaryMetricsRgmApp: React.FC<ValueCreationNrmProps> = ({
  slug,
  title,
}) => {
  const [overViewConfig, setOverviewConfig] = useState<ScoreCardItemData[]>([]);
  const {
    rgmAppUserAdoptionScorecard,
    getRgmAppUserAdoption,
    getRgmAppUserAdoptionLoading,
  } = useOverview() as any;
  const { operationalMetricCriteria } = useMetricsCriteria();
  const { selectedProduct } = useContext(FilterContext);

  useEffect(() => {
    const overviewMetricCriteria = { ...operationalMetricCriteria };
    getRgmAppUserAdoption({
      variables: { overviewMetricCriteria },
    });
  }, [getRgmAppUserAdoption, operationalMetricCriteria]);

  useEffect(() => {
    if (rgmAppUserAdoptionScorecard) {
      const data = {
        getUserAdoptionScorecard: { ...rgmAppUserAdoptionScorecard },
      };
      // const data = { ...rgmAppUserAdoptionScorecard };
      setOverviewConfig([
        totalUsers.handler(data),
        activeUsers.handler(data),
        adoptionRate.handler(data),
      ]);
    }
  }, [rgmAppUserAdoptionScorecard]);

  return (
    <CardContainer
      isLoading={getRgmAppUserAdoptionLoading}
      slug={slug}
      title={title}
      showInfoIcon={true}
      dataDefinitions={{
        sections:
          KpiDefinitionData[selectedProduct?.productId]
            ?.userBehaviorAndEngagement?.userAdoption ?? [],
      }}
      isAccordion={false}
    >
      <KpiGrid columnsDesktop={3} columnsTablet={3} columnsMobile={1}>
        {overViewConfig.map((item, index) => (
          <KpiTile key={index} data={item} />
        ))}
      </KpiGrid>
    </CardContainer>
  );
};

export default SummaryMetricsRgmApp;
