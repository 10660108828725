import styled from 'styled-components';

export const StyledCard = styled.div`
  background-color: ${(props) => props.theme?.colors?.backgroundLayer1};
  border-radius: 8px;
  gap: 16px;
  padding: 24px;
  box-shadow:
    0 2px 8px -1px rgba(0, 0, 0, 0.1),
    0 4px 12px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionHeaderWrapper = styled(HeaderWrapper)`
  cursor: pointer;
`;

export const LeftHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px; /* Adjust spacing as needed */
`;

export const CardHeader = styled.h5`
  color: ${(props) => props.theme?.colors?.textPrimary};
  ${(props) => props.theme?.mergedTypography?.fontHeadingH6Bold};
  margin: 0;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 32px;
    color: ${(props) => props.theme?.colors?.icon};
    transition: transform 0.3s ease;
  }
`;

export const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 24px;
    color: ${(props) => props.theme?.colors?.icon};
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    border-radius: 50%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

export const AccordionIconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 32px;
    color: ${(props) => props.theme?.colors?.icon};
    transition: transform 0.3s ease;
  }
`;

export const AccordionCardContentOpen = styled(CardContent)`
  max-height: 100%;
  opacity: 1;
  margin-top: 16px;

  @media (max-width: 1024px) {
    max-height: none;
  }

  @media (max-width: 768px) {
    max-height: none;
  }
`;

export const AccordionCardContentClosed = styled(CardContent)`
  max-height: 0;
  opacity: 0;
  margin-top: 0;

  @media (max-width: 1024px) {
    max-height: 0;
  }

  @media (max-width: 768px) {
    max-height: 0;
  }
`;

export const ChildWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
