import React from 'react';
import { OverviewComponentType, ProductOverviewRegistry } from '../../types';
import UserBehaviorScorecard from 'containers/UserBehaviorEngagement/RGM/SummaryMetricsRgmApp';
import ValueCreationScoreCard from 'containers/ValueCreationMetrics/RGMAppValueCreation/ScoreCard';

const scorecards: OverviewComponentType[] = [
  () => <UserBehaviorScorecard slug="user-adoption" title="Summary" />,
  () => <ValueCreationScoreCard />,
];

const customOverview: OverviewComponentType | null = null;

const appRegistry: ProductOverviewRegistry = {
  scorecards,
  customOverview,
};

export default appRegistry;
