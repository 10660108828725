import React, { useContext } from 'react';
import * as Styled from './style';
import { Drawer, Stack, Typography, Divider } from '@mui/material';
import { constants } from 'constants/Constants';
import { FilterContext } from 'components/FilterBar/FilterProvider';
import { ThemeContext } from '../../../context/ThemeContext';
import { useAppContext } from '../../../context/AppContext';
import { MdOutlineClose } from 'react-icons/md';

export default function KpiDefinitionDrawer() {
  const { mode } = useContext(ThemeContext);
  const { selectedModel, selectedSector, selectedTool } =
    useContext(FilterContext);
  const { kpiDrawer } = useAppContext();
  const { isDrawerShown, toggleDrawer, kpiDrawerProps } = kpiDrawer;
  const { title, sections, subHeading } = kpiDrawerProps;

  const handleDrawerClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleDrawer();
  };

  const getUniqueKey = (prefix: string, index: number) => `${prefix}-${index}`;

  const getSubHeading = () => {
    if (selectedModel?.id === constants?.modals?.productPairing) {
      return constants?.productPairing?.subHeading;
    } else if (selectedModel?.id === constants?.modals?.shareWise) {
      return constants?.shareWise?.subHeading;
    } else {
      return '';
    }
  };

  const definitionList = sections?.map((section, sectionIndex) => {
    return (
      <div key={getUniqueKey('section', sectionIndex)} className="kpiSection">
        <Typography className="sectionName">{section?.sectionName}</Typography>
        {section?.sectionKpiList?.map((kpi, kpiIndex) => {
          let updatedDefinition = kpi?.definition;

          // Check if {sector} is present before replacing
          if (updatedDefinition.includes('{sector}')) {
            updatedDefinition = updatedDefinition.replace(
              /{sector}/g,
              selectedSector?.sectorName
            );
          }

          // in Automation mapping selectedTool to kpi category
          if (
            selectedTool?.value !== '' &&
            kpi.kpiNameCategory !== selectedTool?.value
          ) {
            return null;
          }

          return (
            <React.Fragment key={getUniqueKey('kpi', kpiIndex)}>
              <Typography className="kpiName">{kpi?.kpiName}</Typography>
              <Typography className="kpiDefinition">
                {updatedDefinition.split('\\n').map((line, index) => (
                  <React.Fragment key={getUniqueKey('line', index)}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
              {kpiIndex < section?.sectionKpiList.length - 1 && (
                <Divider orientation="horizontal" className="kpiDivider" />
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  });

  return (
    <Drawer
      anchor="right"
      open={isDrawerShown}
      onClose={handleDrawerClose}
      sx={{ width: '500px' }}
      container={document.body}
    >
      <Styled.DrawerMainContainer
        data-testid="kpi-definition-drawer-container"
        themeMode={mode}
      >
        <Styled.DrawerHeaderContainer
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
          }}
        >
          <Stack sx={{ padding: '16px' }}>
            <Styled.CloseButton
              themeMode={mode}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                toggleDrawer();
              }}
              data-testid="btn-close-drawer"
            >
              <MdOutlineClose />
            </Styled.CloseButton>
            <Typography
              variant="h2"
              sx={{
                fontSize: '18px',
                padding: '8px 0px',
                marginRight: '30px',
                fontWeight: '700',
              }}
            >
              {constants?.dataDefinitions}
            </Typography>
          </Stack>
        </Styled.DrawerHeaderContainer>
        <Styled.KpiDetailContainer
          themeMode={mode}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
          }}
        >
          <Stack>
            {subHeading && (
              <Typography className="kpiName">
                {getSubHeading()
                  .split('\n')
                  .map((line) => (
                    <span key={line} style={{ display: 'block' }}>
                      {line}
                    </span>
                  ))}
              </Typography>
            )}
            <Typography className="definitionSectionTitle">{title}</Typography>
            {definitionList}
          </Stack>
        </Styled.KpiDetailContainer>
      </Styled.DrawerMainContainer>
    </Drawer>
  );
}
