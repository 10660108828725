import React, {useContext} from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import * as Styled from "./style";
import Stack from "@mui/material/Stack";
import StackedHorizontalBar from "../StackedHorizontalBar/StackedHorizontalBar";
import { color as themeColor } from "../../../src/theme/Color";
import { constants } from "../../constants/Constants";
import { helperFunctions } from "../../utils/helperFunctions";
import { ThemeContext } from "../../context/ThemeContext";

const SLAAnalysis = ({
  incidentMonitoringSlaAnalysisData,
  paddingTop,
  paddingBottomInnerContainer,
  isAppPerform
}) => {
  const {
    getRoundedValueForDecimal,
    getNAForInvalidValue,
    getNAForPercentageValue
  } = helperFunctions();
  const { mode } = useContext(ThemeContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const slaAnalysisChartData = incidentMonitoringSlaAnalysisData;

  const finalData = [];

  function createDataList(chartData) {
    let i = 0;

    if (chartData != null) {
      const arr = Array.from(chartData || "");

      if (arr != "undefined" || arr != null) {
        arr.forEach((data) => {
          i = i + 1;
          const obj = {
            id: i,
            title: getTitleForGraph(data.slaType),
            subtitle: getSubTitleForGraph(data.slaType),
            quantity: data.incidentCount,
            quantityPercentage: getRoundedValueForDecimal(
              data.incidentPercentage
            ),
            color: getColorForGraph(data.slaType)
          };

          finalData.push(obj);
        });
      }
    }

    return finalData;
  }

  function getTitleForGraph(slaType) {
    if (slaType === "IncidentMeetingSLA") {
      return constants.applicationPerformance
        .incidentsMeetingSLAPercentageHeader;
    } else if (slaType === "IncidentAtRiskOfSLABreach") {
      return constants.applicationPerformance
        .incidentsAtRiskOfSLABreachPercentageHeader;
    } else {
      return constants.applicationPerformance
        .incidentsOfSLABreachPercentageHeader;
    }
  }

  function getSubTitleForGraph(slaType) {
    if (slaType === "IncidentMeetingSLA") {
      return constants.applicationPerformance
        .incidentsMeetingSLAPercentageSubHeader;
    } else if (slaType === "IncidentAtRiskOfSLABreach") {
      return constants.applicationPerformance
        .incidentsAtRiskOfSLABreachPercentageSubHeader;
    } else {
      return constants.applicationPerformance
        .incidentsOfSLABreachPercentageSubHeader;
    }
  }

  function getColorForGraph(slaType) {
    if (slaType === "IncidentMeetingSLA") {
      return themeColor._007EB5;
    } else if (slaType === "IncidentAtRiskOfSLABreach") {
      return themeColor._FFB933;
    } else {
      return themeColor._D20000;
    }
  }

  createDataList(slaAnalysisChartData);

  const tooltip = isDesktop ? { show: true } : { show: false };

  return (
    <Styled.MainContainer
      data-accessible={true}
      data-testid="sla-analysis-main-container"
      mode={mode}
      isAppPerform={isAppPerform}
    >
      <Stack direction="row">
        <Typography
          sx={{
            fontSize: "16px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 700,
            paddingTop: paddingTop,
            color:
              mode === "light"
                ? `${themeColor._616161}`
                : `${themeColor._efefef}`
          }}
        >
          {constants.applicationPerformance.slaAnalysis}
        </Typography>
      </Stack>

      {finalData.map((item) => {
        return (
          <Styled.InnerContainer
            key={finalData.id}
            container
            data-accessible={true}
            data-testid="inner-container"
            paddingBottomInnerContainer={paddingBottomInnerContainer}
            mode={mode}
          >
            <Stack sx={{ width: "100%", fontSize: "14px" }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "space-between", md: "flex-start" },
                  width: "100%",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400
                }}
              >
                <Typography
                  align="left"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: { md: "20%" },
                    textAlign: "left",
                    fontSize: 12,
                    justifyContent: "left",
                    color:
                      mode === "light" ? themeColor._616161 : themeColor._D0D0D0
                  }}
                >
                  {item.title}
                  <br />
                  {item.subtitle}
                </Typography>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: { xs: "55%", md: "70%" }
                  }}
                >
                  <StackedHorizontalBar
                    barValue={item.quantityPercentage}
                    maxValue={"100"}
                    width={"80%"}
                    primaryColor={item.color}
                    barWidth={40}
                    secondaryColor={themeColor._1d1d1d}
                    eChartHeight={"60px"}
                    tooltipHeader={item.title + " " + item.subtitle}
                    seriesDataCount="Single"
                    tooltipType={"SLAAnalysis"}
                    tooltip={tooltip}
                  />
                </Stack>
                <Stack
                  sx={{
                    width: "10%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                      color:
                        mode === "light"
                          ? themeColor._616161
                          : themeColor._efefef
                    }}
                  >
                    {getNAForInvalidValue(item.quantity)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      fontFamily: "Inter",
                      color:
                        mode === "light"
                          ? themeColor._616161
                          : themeColor._D0D0D0
                    }}
                  >
                    {getNAForPercentageValue(item.quantityPercentage)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Styled.InnerContainer>
        );
      })}
      <Stack direction="row">
        <Typography
          sx={{
            fontSize: "12px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            paddingBottom: "10px",
            paddingTop: "20px",
            color: mode === "light" ? themeColor._616161 : themeColor._D0D0D0
          }}
        >
          {constants.applicationPerformance.serviceLevelAgreement}
        </Typography>
      </Stack>
    </Styled.MainContainer>
  );
};

export default SLAAnalysis;
