import React, { useContext } from 'react';
import * as Styled from './style';
import { Divider, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import VerticalDividerCard from '../VerticalDividerCard';
import { helperFunctions } from '../../utils/helperFunctions';
import useCustomMediaQuery from '../../utils/useCustomMediaQuery';
import { screenSizes } from '../../constants/Constants';
import { ThemeContext } from '../../context/ThemeContext';

// Helper components moved outside
const RenderItem = ({ value, unit, mode, isMobile, isTablet }) => {
  const { getNAForInvalidValue } = helperFunctions();
  let calculatedValue = getNAForInvalidValue(value);
  let alignmentValues = '';

  if (isMobile || isTablet) {
    alignmentValues = 'center';
  }

  if (calculatedValue !== 'NA') {
    if (calculatedValue == 0.0 || calculatedValue == 0.0) {
      calculatedValue = 0;
    }
  } else {
    unit = '';
  }

  return (
    <Stack
      direction="row"
      alignItems={alignmentValues}
      justifyContent={alignmentValues}
      width="100%"
      sx={{ height: isMobile || isTablet ? '40px' : undefined }}
    >
      <Styled.ValueText mode={mode}>{calculatedValue}</Styled.ValueText>
      {unit && <Styled.UnitText mode={mode}>{unit}</Styled.UnitText>}
    </Stack>
  );
};

const RenderTime = ({ value, customWidth, mode, isMobile, isTablet }) => {
  let alignmentValues = '';

  if (isMobile || isTablet) {
    alignmentValues = 'center';
  }

  return (
    <Stack
      direction="row"
      alignItems={alignmentValues}
      justifyContent={alignmentValues}
      width={customWidth || '100%'}
      sx={{ height: isMobile || isTablet ? '40px' : undefined }}
    >
      <Styled.ValueText mode={mode}>{value}</Styled.ValueText>
    </Stack>
  );
};

const RenderDescriptionText = ({
  title,
  subTitle,
  customWidth,
  mode,
  isMobile,
}) => {
  const getMarginTop = (title) => {
    if (isMobile) {
      if (title == 'INCIDENT COUNT') {
        return '-7px';
      }
      return '0px';
    }
  };

  const getMarginLeft = (title) => {
    if (isMobile) {
      if (title != 'INCIDENT COUNT') {
        return '-8px';
      }
      return '0px';
    }
  };

  return (
    <div
      style={{
        width: customWidth || '130%',
        display: !isMobile ? 'flex' : 'block',
        alignItems: 'center',
        marginTop: isMobile ? '4px' : ' ',
      }}
    >
      <Styled.RenderItemText
        mode={mode}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: getMarginTop(title),
          marginLeft: getMarginLeft(title),
        }}
      >
        {title}
      </Styled.RenderItemText>
      {!isMobile && <div style={{ width: '2%' }} />}
      <Styled.RenderItemText
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginLeft: isMobile ? '-10px' : '0px',
        }}
        mode={mode}
      >
        {subTitle}
      </Styled.RenderItemText>
    </div>
  );
};

// Metrics components moved outside
const ResolutionTimeMetrics = ({
  incidentMonitoringOverviewData,
  mode,
  isMobile,
  isTablet,
}) => {
  const { formatTimeWithSeconds } = helperFunctions();
  return (
    <VerticalDividerCard
      numbers={
        <RenderTime
          value={formatTimeWithSeconds(
            incidentMonitoringOverviewData?.resolutionTime
          )}
          mode={mode}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      }
      description={
        <RenderDescriptionText
          title="MEAN TIME TO"
          subTitle="RESOLVE"
          mode={mode}
          isMobile={isMobile}
        />
      }
    />
  );
};

const ResponsTimeMetrics = ({
  incidentMonitoringOverviewData,
  mode,
  isMobile,
  isTablet,
}) => {
  const { formatTimeWithSeconds } = helperFunctions();
  return (
    <VerticalDividerCard
      numbers={
        <RenderTime
          value={formatTimeWithSeconds(
            incidentMonitoringOverviewData?.responseTime
          )}
          customWidth={isMobile ? '160%' : '100%'}
          mode={mode}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      }
      description={
        <RenderDescriptionText
          title="MEAN TIME TO"
          subTitle="RESPOND"
          customWidth={isMobile ? '178%' : '130%'}
          mode={mode}
          isMobile={isMobile}
        />
      }
    />
  );
};

const IncidentCountMetrics = ({
  incidentMonitoringOverviewData,
  mode,
  isMobile,
  isTablet,
}) => {
  return (
    <VerticalDividerCard
      numbers={
        <RenderItem
          value={incidentMonitoringOverviewData?.incidentCount}
          unit=""
          mode={mode}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      }
      description={
        <RenderDescriptionText
          title="INCIDENT COUNT"
          subTitle=""
          mode={mode}
          isMobile={isMobile}
        />
      }
    />
  );
};

const IncidentMonitoringHeader = ({ incidentMonitoringOverviewData }) => {
  const { mode } = useContext(ThemeContext);
  const isMobile = useCustomMediaQuery(screenSizes.mobile);
  const isTablet = useCustomMediaQuery(screenSizes.tablet);

  return (
    <Styled.MainContainer
      data-testid="incident-header-mainContainer"
      mode={mode}
    >
      <Styled.InnerContainer
        container
        data-testid="incident-header-innerContainer"
        mode={mode}
      >
        <Grid
          item
          data-testid="incident-header-incidentCount"
          md={3}
          sm={3}
          xs={5}
          sx={{
            paddingRight: isMobile ? '10px' : '0px',
            display: 'grid ',
            justifyContent: 'center',
          }}
        >
          <IncidentCountMetrics
            incidentMonitoringOverviewData={incidentMonitoringOverviewData}
            mode={mode}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className="firstVerticalBarContainer"
        />
        <Grid
          item
          data-testid="incident-header-respondTime"
          md={3}
          sm={3}
          xs={5}
          sx={{
            paddingRight: isMobile ? 4 : 0,
            display: 'grid',
            justifyContent: 'center',
          }}
        >
          <ResponsTimeMetrics
            incidentMonitoringOverviewData={incidentMonitoringOverviewData}
            mode={mode}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className="secondVerticalBarContainer"
        />
        <Grid
          item
          data-testid="incident-header-resolutionTime"
          md={3}
          sm={3}
          xs={12}
          sx={{
            display: 'grid ',
            justifyContent: 'center',
            marginTop: isMobile ? '15px' : '0px',
          }}
        >
          <ResolutionTimeMetrics
            incidentMonitoringOverviewData={incidentMonitoringOverviewData}
            mode={mode}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </Grid>
      </Styled.InnerContainer>
    </Styled.MainContainer>
  );
};

export default IncidentMonitoringHeader;
