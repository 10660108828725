export const color = {
  _black: '#000',
  _white: '#fff',
  _transparent: 'transparent',
  _000000: '#000000',
  _ffff: '#ffff',
  _ffffff: '#ffffff',
  _3a3a3a: '#3a3a3a',
  _e3e7eb: '#e3e7eb',
  _ced4da: '#ced4da',
  _dee2e6: '#dee2e6',
  _6b88a4: '#6b88a4',
  _f4f4f4: '#f4f4f4',
  _669dd7: '#669dd7',
  _d0d0d0: '#d0d0d0',
  _D7E5F8: '#D7E5F8',
  _1d1d1d: '#1d1d1d',
  _a52636: '#a52636',
  _e8f4ee: '#e8f4ee',
  _d9d9d9: '#d9d9d9',
  _359ed5: '#359ed5',
  _a0a0a0: '#a0a0a0',
  _00e676: '#00e676',
  _ff1744: '#ff1744',
  _2196f3: '#2196f3',
  _cdcdcdcf: '#cdcdcdcf',
  _616161: '#616161',
  _313131: '#313131',
  _E8E8E8: '#E8E8E8',
  _445565: '#445565',
  _008240: '#008240',
  _818181: '#818181',
  _primary_color: '#005CBC',
  _dbd8d8: '#dbd8d8',
  _0096D1: '#0096D1',
  _C0C0C0: '#C0C0C0',
  _FBFCFE: '#FBFCFE',
  _EFF5FC: '#EFF5FC',
  _272727: '#272727',
  _E7EFFB: '#E7EFFB',
  _F3F7FD: '#F3F7FD',
  _003771: '#003771',
  _B2CAEA: '#B2CAEA',
  _0085CA: '#0085CA',
  _F6CCD2: '#F6CCD2',
  _DFEAF9: '#DFEAF9',
  _cce4ee: '#cce4ee',
  _90EE90: '#90EE90',
  _efefef: '#efefef',
  _c0c0c0: '#c0c0c0',
  _D32F2F: '#d32f2f',
  _ED6C02: '#ed6c02',
  _0288D1: '#0288d1',
  _2E7D32: '#2e7d32',
  _D20000: '#D20000',
  _005CBC: '#005CBC',
  _deebff: '#deebff',
  _005A7D: '#005A7D',
  _CCE4EE: '#CCE4EE',
  _F7F8F9: '#f7f8f9',
  _DFDFDF: '#DFDFDF',
  _50C878: '#50C878',
  _E37383: '#E37383',
  _A35079: '#A35079',
  _0097BD: '#0097BD',
  _006841: '#006841',
  _FFA800: '#FFA800',
  _0078A7: '#0078A7',
  _66C4EE: '#66C4EE',
  _66C0D7: '#66C0D7',
  _666666: '#666666',
  _DB3333: '#DB3333',
  _007997: '#007997',
  _33B1E8: '#33B1E8',
  _005E88: '#005E88',
  _B992EB: '#B992EB',
  _4E4E4E: '#4E4E4E',
  _824061: '#824061',
  _007EB5: '#007EB5',
  _FFB933: '#FFB933',
  _131313: '#131313',
  _131216: '#131216',
  _252525: '#252525',
  _0069D7: '#0069D7',
  _E6F5FC: '#E6F5FC',
  _EDDCE4: '#EDDCE4',
  _339B74: '#339B74',
  _A80000: '#A80000',
  _6E3BB2: '#6E3BB2',
  _D0D0D0: '#D0D0D0',
  _c4c4c4: '#c4c4c4',
  _cccc40: '#cccccc40',
  _545f6f: '#545f6f',
  _A370F7: '#A370F7',
  _FD9843: '#FD9843',
  _3387DF: '#3387DF',
  _994900: '#994900',
  _33ABDA: '#33ABDA',
  // Red shades
  _7F0013: '#7F0013',
  _AA001A: '#AA001A',
  _D40020: '#D40020',
  _DD334D: '#DD334D',
  _E56679: '#E56679',
  _EE99A6: '#EE99A6',
  // Pink shades
  _6A3452: '#6A3452',
  _8D466D: '#8D466D',
  _B05788: '#B05788',
  _C079A0: '#C079A0',
  _D09AB8: '#D09AB8',
  _DFBCCF: '#DFBCCF',
  // Blue shades
  _0A4B5F: '#0A4B5F',
  _0E647F: '#0E647F',
  _117D9F: '#117D9F',
  _4197B2: '#4197B2',
  _70B1C5: '#70B1C5',
  _A0CBD9: '#A0CBD9',
  // Purple shades
  _4F357A: '#4F357A',
  _6A46A3: '#6A46A3',
  _8458CC: '#8458CC',
  _9D79D6: '#9D79D6',
  _B59BE0: '#B59BE0',
  _CEBCEB: '#CEBCEB',
  // Green shades
  _005032: '#005032',
  _006A42: '#006A42',
  _008553: '#008553',
  _339D75: '#339D75',
  _66B698: '#66B698',
  _99CEBA: '#99CEBA',
  // Orange shades
  _995211: '#995211',
  _CC6E16: '#CC6E16',
  _FF891C: '#FF891C',
  _FFA149: '#FFA149',
  _FFB877: '#FFB877',
  _FFD0A4: '#FFD0A4',
};
