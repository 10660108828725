import {
  formatValueWithMiddlewares,
  checkForNA,
  appendPercentage,
  createKpiTileItem,
} from '../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    const { adoptionRatePercentage, adoptionRatePercentageDelta } =
      data.getUserAdoptionScorecard;

    return createKpiTileItem(
      'Adoption Rate',
      formatValueWithMiddlewares(adoptionRatePercentage, [
        checkForNA,
        appendPercentage,
      ]),
      adoptionRatePercentageDelta !== undefined
        ? {
            isPositiveValue: adoptionRatePercentageDelta >= 0,
            text: formatValueWithMiddlewares(adoptionRatePercentageDelta, [
              checkForNA,
              appendPercentage,
            ]).toString(),
            sideLabel: '',
            showArrow: true,
          }
        : null
    );
  },
};
