import { Grid, Box } from "@mui/material";
import styled from "styled-components";
import { color } from "../../theme/Color";

// Styled components
interface ErrorHandlingContainerProps {
  mode: string;
}

export const StyledGridContainer = styled(Grid)<ErrorHandlingContainerProps>`
  text-align: center;
  color: ${({ mode }) =>
    mode === "light" ? `${color._3a3a3a}` : `${color._D0D0D0}`};
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const StyledSVG = styled.svg`
  width: 40px;
  height: 40px;
`;

export const StyledErrorText = styled.div`
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
`;
