import React from 'react';
import { GridContainerProps } from './types';
import { StyledGridContainer } from './style';

/**
 * GridContainer Component
 *
 * @param {GridContainerProps} props - Component props
 * @returns {JSX.Element}
 */
const GridContainer: React.FC<GridContainerProps> = ({
  children,
  gap,
  columnsMobile,
  columnsTablet,
  columnsDesktop,
  ...rest
}) => {
  return (
    <StyledGridContainer
      gap={gap}
      columnsMobile={columnsMobile}
      columnsTablet={columnsTablet}
      columnsDesktop={columnsDesktop}
      {...rest}
    >
      {children}
    </StyledGridContainer>
  );
};

export default GridContainer;
