import React,{useContext} from "react";
import ReactEcharts from "echarts-for-react";
import { color as themeColor } from "../../../src/theme/Color";
import { ThemeContext } from "../../context/ThemeContext";

const StackedHorizontalBar = ({
  barValue,
  maxValue,
  barWidth,
  primaryColor,
  secondaryColor,
  borderRadius,
  eChartHeight,
  gridLeft,
  gridRight,
  tooltipHeader,
  tooltipType,
  tooltipValue,
  tooltip = {},
  showBackground,
  seriesDataCount,
  gridBottom = "20%",
  gridHeight = "60%",
}) => {
  let seriesData = "";
  const {mode} = useContext(ThemeContext);
  function getLabelData() {
    let labelData = "";
    if (tooltipType == "StoreUsage" || tooltipType == "DigitizedStores") {
      labelData = {
        show: true,
        position: "right",
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Inter",
        color: mode === 'light' ? themeColor._616161: themeColor._efefef,
        formatter: function () {
          return tooltipValue;
        },
      };
    } else {
      labelData = [
        {
          show: false,
        },
      ];
    }
    return labelData;
  }

  function getTooltipPosition() {
    if (tooltipType == "SLAAnalysis") {
      return [-40, "50%"];
    } else if (
      tooltipType == "TargetPerformanceFunnel" ||
      tooltipType == "StoreAdoptionFunnel" ||
      tooltipType == "APIRequestsErrors"
    ) {
      return [20, "10%"];
    } else {
      return null;
    }
  }

  if (seriesDataCount == "Single") {
    seriesData = [
      {
        name: "",
        type: "bar",
        stack: "total",
        showBackground: showBackground,
        backgroundStyle: {
          color: secondaryColor,
          borderRadius: borderRadius,
        },
        barWidth: barWidth,
        itemStyle: {
          borderRadius: borderRadius,
          color: primaryColor,
        },
        label: getLabelData(),
        emphasis: {
          focus: "series",
        },
        data: [barValue],
      },
    ];
  } else {
    seriesData = [
      {
        name: "Open",
        type: "bar",
        stack: "total",
        showBackground: showBackground,
        backgroundStyle: {
          color: mode === 'light' ? themeColor._B2CAEA : themeColor._272727,
        },
        barWidth: barWidth,
        itemStyle: {
          borderRadius: borderRadius,
          color: primaryColor,
        },
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: [barValue],
      },
      {
        name: "Closed",
        type: "bar",
        stack: "total",
        showBackground: showBackground,
        backgroundStyle: {
          color: mode === 'light' ? themeColor._B2CAEA : themeColor._272727
        },
        barWidth: barWidth,
        itemStyle: {
          borderRadius: borderRadius,
          color: secondaryColor,
        },
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        data: [maxValue - barValue],
      },
    ];
  }

  const option = {
    tooltip: {
      ...tooltip,
      trigger: "item",
      extraCssText: "z-index: 0;",
      axisPointer: {
        type: "shadow",
      },
      position: getTooltipPosition(),
      valueFormatter: function (value) {
        if (
          tooltipType == "DigitalOrderingBehavior" ||
          tooltipType == "StoreUsage" ||
          tooltipType == "DigitizedStores" ||
          tooltipType == "Error Rate" ||
          tooltipType == "StoreAdoptionFunnel" ||
          tooltipType == "APIRequestsErrors"
        ) {
          return tooltipValue;
        } else {
          return value;
        }
      },
    },
    legend: {
      show: false,
      textStyle: {
        color: "#EFEFEF",
        fontFamily: "Inter",
      },
    },
    grid: {
      left: gridLeft,
      right: gridRight,
      bottom: gridBottom,
      containLabel: false,
      height: gridHeight,
    },
    xAxis: {
      show: false,
      type: "value",
      max: maxValue,
    },
    yAxis: {
      show: false,
      type: "category",
      data: [tooltipHeader],
    },
    series: seriesData,
  };

  return (
    <ReactEcharts
      option={option}
      style={{ height: eChartHeight, width: "100%" }}
    />
  );
};

export default StackedHorizontalBar;
