import React, {
  useMemo,
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { Logger, LogLevel } from '../service/LoggerService';

interface Kpi {
  definition: string;
  kpiName: string;
  kpiNameCategory: string;
}

// Define the shape of a KPI Section
interface KpiSection {
  id: string;
  sectionName: string;
  sectionKpiList: Kpi[];
  // Add other relevant fields
}

// Define the properties for the KPI Drawer
interface KpiDrawerProps {
  title?: string;
  subHeading?: string;
  sections?: KpiSection[];
  // Add other relevant properties
  [key: string]: any;
}

// Define the structure of the KPI Drawer context
export interface KpiDrawerContext {
  kpiDrawerProps: KpiDrawerProps;
  setKpiDrawerProps: Dispatch<SetStateAction<KpiDrawerProps>>;
  updateKpiDrawerProps: (newProps: Partial<KpiDrawerProps>) => void;
  isDrawerShown: boolean;
  toggleDrawer: () => void;
  addKpiSection: (section: KpiSection) => void;
  setKpiDrawerTitle: (title: string) => void;
  setKpiDrawerSubHeading: (subHeading: string) => void;
}

// Extend the overall context value to include the logger
export interface AppContextValue {
  [key: string]: any;
  kpiDrawer: KpiDrawerContext;
  logger: Logger;
}

// Define the props for the AppProvider component
interface AppProviderProps {
  children: ReactNode;
  value?: Partial<AppContextValue>; // allow overriding everything including kpiDrawer
}

// Create the context with a default undefined value
export const AppContext = createContext<AppContextValue | undefined>(undefined);

export const AppProvider: React.FC<AppProviderProps> = ({
  children,
  value = {},
}) => {
  // Initialize KPI Drawer state values using provided overrides (if any)
  const [kpiDrawerProps, setKpiDrawerProps] = useState<KpiDrawerProps>(
    value.kpiDrawer?.kpiDrawerProps || {}
  );
  const [isDrawerShown, setIsDrawerShown] = useState<boolean>(
    value.kpiDrawer?.isDrawerShown !== undefined
      ? value.kpiDrawer.isDrawerShown
      : false
  );

  const updateKpiDrawerProps = (newProps: Partial<KpiDrawerProps>) => {
    setKpiDrawerProps((prevProps) => ({ ...prevProps, ...newProps }));
  };

  const toggleDrawer = () => {
    setIsDrawerShown((prev) => !prev);
  };

  const addKpiSection = (section: KpiSection) => {
    setKpiDrawerProps((prevProps) => ({
      ...prevProps,
      sections: [...(prevProps.sections || []), section],
    }));
  };

  const setKpiDrawerTitle = (title: string) => {
    setKpiDrawerProps((prevProps) => ({
      ...prevProps,
      title,
    }));
  };

  const setKpiDrawerSubHeading = (subHeading: string) => {
    setKpiDrawerProps((prevProps) => ({
      ...prevProps,
      subHeading,
    }));
  };

  // Build the KPI Drawer context with potential overrides
  const kpiDrawer: KpiDrawerContext = {
    kpiDrawerProps,
    setKpiDrawerProps,
    updateKpiDrawerProps,
    isDrawerShown,
    toggleDrawer,
    addKpiSection,
    setKpiDrawerTitle,
    setKpiDrawerSubHeading,
    ...value.kpiDrawer,
  };

  const logger = new Logger({
    level: LogLevel.ERROR,
    remoteLoggingEnabled: false,
    environment: 'development',
  });

  // Compose the overall context value
  const contextValue: AppContextValue = useMemo(
    () => ({
      ...value,
      kpiDrawer,
      logger,
    }),
    [value, kpiDrawer, logger]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

// Custom hook to use the AppContext
export const useAppContext = (): AppContextValue => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export default AppContext;
