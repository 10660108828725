import React, { useContext } from 'react';
import IncidentMonitoringHeader from '../IncidentMonitoringHeader/IncidentMonitoringHeader';
import StackedHorizontalBar from '../StackedHorizontalBar/StackedHorizontalBar';
import MixLineBarChart from '../MixLineBarChart/MixLineBarChart';
import * as Styled from './style';
import { Box, Stack, Typography, Container } from '@mui/material';
import { color as themeColor } from '../../../src/theme/Color';
import { helperFunctions } from '../../utils/helperFunctions';
import { ThemeContext } from '../../context/ThemeContext';
import useCustomMediaQuery from 'utils/useCustomMediaQuery';
import { screenSizes, constants } from '../../constants/Constants';
import { FilterContext } from '../FilterBar/FilterProvider';

const IncidentMonitoringDetails = ({
  incidentMonitoringOverviewData,
  incidentMonitoringOpenAndCloseData,
  incidentMonitoringPriorityLevelGraphData,
  mixLineChartHeight,
  legendBottom,
  isAppPerform,
  selectedFrequency,
}) => {
  const { getZeroValueForInvalidValue, getDateAndMonth, getMonthFromDate } =
    helperFunctions();
  const { selectedProgram } = useContext(FilterContext);
  const { mode } = useContext(ThemeContext);
  const isMobileForIncidentMonitoringDetails = useCustomMediaQuery(
    screenSizes.mobile
  );

  const maxValueForOpenCloseBar =
    incidentMonitoringOpenAndCloseData?.openIncident +
    incidentMonitoringOpenAndCloseData?.closedIncident;
  const selectLegend = () => {
    if (selectedProgram?.programId === constants.programs.DTC) {
      return [
        { name: 'Sum', icon: '' },
        { name: 'Regular', icon: 'circle' },
        { name: 'Standard', icon: 'circle' },
        { name: 'Moderate', icon: 'circle' },
      ];
    } else {
      return [
        { name: 'Sum', icon: '' },
        { name: 'Very Low', icon: 'circle' },
        { name: 'Low', icon: 'circle' },
        { name: 'Moderate', icon: 'circle' },
      ];
    }
  };

  let updatedList = [];
  const monthRecords = [];
  const lowRecords = [];
  const veryLowRecords = [];
  const moderateRecords = [];
  const Sum = [];

  const setSeriesData = () => {
    if (selectedProgram?.programId === constants.programs.DTC) {
      return [
        {
          name: 'Regular',
          type: 'bar',
          itemStyle: {
            color: '#007EB5',
            barBorderRadius: [10, 10, 0, 0],
          },
          data: updatedList?.VeryLow,
        },
        {
          name: 'Standard',
          type: 'bar',
          itemStyle: {
            color: '#A35079',
            borderRadius: [10, 10, 0, 0],
          },
          data: updatedList?.Low,
        },
        {
          name: 'Moderate',
          type: 'bar',
          itemStyle: {
            color: '#33B1E8',
            borderRadius: [10, 10, 0, 0],
          },
          data: updatedList?.Moderate,
        },
        {
          name: 'Sum',
          type: 'line',
          symbolSize: 7,
          symbol: 'circle',
          itemStyle: {
            color: '#FFA800',
          },
          label: {
            show: true,
            color: mode === 'light' ? themeColor._616161 : themeColor._efefef,
          },
          data: updatedList?.Sum,
        },
      ];
    } else {
      return [
        {
          name: 'Very Low',
          type: 'bar',
          itemStyle: {
            color: '#007EB5',
            barBorderRadius: [10, 10, 0, 0],
          },
          data: updatedList?.VeryLow,
        },
        {
          name: 'Low',
          type: 'bar',
          itemStyle: {
            color: '#A35079',
            borderRadius: [10, 10, 0, 0],
          },
          data: updatedList?.Low,
        },
        {
          name: 'Moderate',
          type: 'bar',
          itemStyle: {
            color: '#33B1E8',
            borderRadius: [10, 10, 0, 0],
          },
          data: updatedList?.Moderate,
        },
        {
          name: 'Sum',
          type: 'line',
          symbolSize: 7,
          symbol: 'circle',
          itemStyle: {
            color: '#FFA800',
          },
          label: {
            show: true,
            color: mode === 'light' ? themeColor._616161 : themeColor._efefef,
          },
          data: updatedList?.Sum,
        },
      ];
    }
  };

  const getLegendItemGap = () => {
    if (isMobileForIncidentMonitoringDetails) {
      return 5;
    } else {
      return 20;
    }
  };

  function createDataList(chartData) {
    const arr = Array.from(chartData || '');
    arr.forEach((data) => {
      if (
        selectedProgram?.programId === constants.programs.DTC &&
        selectedFrequency?.value === 28
      ) {
        monthRecords.push(getDateAndMonth(data.dateTime));
      } else {
        monthRecords.push(getMonthFromDate(data.dateTime));
      }

      const objLow = data.priorityDetail.find((o) => o.priority === 'LOW');
      if (objLow == undefined || objLow == 'null') {
        lowRecords.push(0);
      } else {
        lowRecords.push(objLow.incidentCount);
      }

      const objVeryLow = data.priorityDetail.find(
        (o) => o.priority === 'VERY LOW'
      );
      if (objVeryLow == undefined || objVeryLow == 'null') {
        veryLowRecords.push(0);
      } else {
        veryLowRecords.push(objVeryLow.incidentCount);
      }

      const objModerate = data.priorityDetail.find(
        (o) => o.priority === 'MODERATE'
      );

      if (objModerate == undefined || objModerate == 'null') {
        moderateRecords.push(0);
      } else {
        moderateRecords.push(objModerate.incidentCount);
      }

      Sum.push(data.sumOfIncidentCount);
    });

    const finalList = {
      months: monthRecords,
      Low: lowRecords,
      VeryLow: veryLowRecords,
      Moderate: moderateRecords,
      Sum: Sum,
    };

    updatedList = finalList;

    return finalList;
  }

  createDataList(incidentMonitoringPriorityLevelGraphData);

  return (
    <Styled.MainContainer
      maxWidth={false}
      data-testid="incident-details-mainContainer"
      mode={mode}
      isAppPerform={isAppPerform}
    >
      <Container>
        <Styled.TopContainer
          container
          data-testid="incident-details-topContainer"
        >
          <IncidentMonitoringHeader
            incidentMonitoringOverviewData={incidentMonitoringOverviewData}
          />
        </Styled.TopContainer>
      </Container>

      <Container maxWidth={'lg'}>
        <Stack className="middleContainer">
          <Box>
            <Typography>Open</Typography>
            <Typography className="firstNumContainer">
              {getZeroValueForInvalidValue(
                incidentMonitoringOpenAndCloseData?.openIncident
              )}
            </Typography>
          </Box>
          <Box>
            <Typography>Closed</Typography>
            <Typography className="secondNumContainer">
              {getZeroValueForInvalidValue(
                incidentMonitoringOpenAndCloseData?.closedIncident
              )}
            </Typography>
          </Box>
        </Stack>

        <Stack className="horizontalBarContainer">
          <StackedHorizontalBar
            barValue={incidentMonitoringOpenAndCloseData?.openIncident}
            maxValue={maxValueForOpenCloseBar}
            barWidth={'20'}
            width={'100%'}
            showBackground="false"
            primaryColor={themeColor._0078A7}
            secondaryColor={themeColor._66C4EE}
            backgroundColor={themeColor._272727}
            eChartHeight={'40px'}
            tooltipHeader="Value"
            seriesDataCount="Double"
          />
        </Stack>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{ marginTop: '28px' }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: '700',
                marginLeft: '5px',
                color:
                  mode === 'light' ? themeColor._616161 : themeColor._efefef,
                fontSize: '16px',
                marginBottom: isMobileForIncidentMonitoringDetails
                  ? '20px'
                  : '0px',
              }}
              className="subHeader1"
            >
              Priority Level
            </Typography>
          </Box>
        </Stack>

        <Styled.BottomContainer data-testid="incident-details-bottomContainer">
          <MixLineBarChart
            prioprityLevelCategoryChartData={updatedList}
            height={mixLineChartHeight}
            width={'100%'}
            legendBottom={legendBottom}
            legendData={selectLegend()}
            legendItemGap={getLegendItemGap()}
            seriesData={setSeriesData()}
          />
        </Styled.BottomContainer>
      </Container>
    </Styled.MainContainer>
  );
};

export default IncidentMonitoringDetails;
