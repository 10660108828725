import {
  OverviewComponentType,
  Filters,
  ProductOverviewRegistry,
} from './types';
import { constants } from 'constants/Constants';

import archletRegistry from './programs/dtv/archlet';
import kazandirioRegistry from './programs/dtc/kazandirio';
import appRegistry from './programs/rgm/app';

const programs = constants.programs;
const products = constants.products;

/**
 * Central registry that aggregates each product’s registry under its program.
 */
const productRegistries: {
  [program: string]: { [product: string]: ProductOverviewRegistry };
} = {
  [programs.DTV]: {
    [products.Archlet]: archletRegistry,
  },
  [programs.DTC]: {
    [products.Kazandirio]: kazandirioRegistry,
  },
  [programs.RGM]: {
    [products.APP]: appRegistry,
  },
};

export const OverviewService = {
  /**
   * Registers a new scorecard for a given program and product.
   */
  registerScoreCard(
    program: any,
    product: any,
    component: OverviewComponentType
  ): void {
    const programRegistry = productRegistries[program];
    if (programRegistry && programRegistry[product]) {
      programRegistry[product].scorecards.push(component);
    } else {
      // Optionally, create a new registry if not found:
      if (!productRegistries[program]) {
        productRegistries[program] = {};
      }
      productRegistries[program][product] = {
        scorecards: [component],
        customOverview: null,
      };
    }
  },

  /**
   * Registers (or replaces) a custom overview for a given program and product.
   */
  registerCustomOverview(
    program: number | string,
    product: number | string,
    component: OverviewComponentType
  ): void {
    const programRegistry = productRegistries[program];
    if (programRegistry && programRegistry[product]) {
      programRegistry[product].customOverview = component;
    } else {
      // Optionally, create a new registry if not found:
      if (!productRegistries[program]) {
        productRegistries[program] = {};
      }
      productRegistries[program][product] = {
        scorecards: [],
        customOverview: component,
      };
    }
  },

  /**
   * Returns an array of scorecards for the given filters.
   */
  resolveScoreCards(filters?: Filters): OverviewComponentType[] {
    if (!filters || !filters.program || !filters.product) {
      return [];
    }
    const programRegistry = productRegistries[filters.program];
    if (programRegistry && programRegistry[filters.product]) {
      return programRegistry[filters.product].scorecards;
    }
    return [];
  },

  /**
   * Returns a custom overview component for the given filters, or null if none exists.
   */
  resolveCustomOverview(filters?: Filters): OverviewComponentType | null {
    if (!filters || !filters.program || !filters.product) {
      return null;
    }
    const programRegistry = productRegistries[filters.program];
    if (programRegistry && programRegistry[filters.product]) {
      return programRegistry[filters.product].customOverview || null;
    }
    return null;
  },

  /**
   * (Optional) Clears the registries. Useful for testing or resetting state.
   */
  clearRegistries(): void {
    Object.keys(productRegistries).forEach((program) => {
      Object.keys(productRegistries[program]).forEach((product) => {
        productRegistries[program][product].scorecards = [];
        productRegistries[program][product].customOverview = null;
      });
    });
  },
};
