import React, { useEffect } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Styled from './style';
import { RiKeyFill } from 'react-icons/ri';
import { ReactComponent as PepsicoIcon } from '../../assets/images/Pepsico_Logo.svg';
import { constants } from '../../../src/constants/Constants';
import { useOkta } from '../../hooks/okta-hooks';
import Loader from 'components/Loader/Loader';
import { oktaLogout } from '../../utils/okta_auth';
import { useOktaAuth } from '@okta/okta-react';

export default function OnboardingPage({ showLogout = false }) {
  const { authenticate } = useOkta();
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the pathname includes "/dashboard"
    if (location.pathname.includes('/dashboard')) {
      navigate('/');
    }
  }, [location.pathname, navigate]);

  const login = async () => {
    authenticate();
  };

  useEffect(() => {
    const handleLogout = async () => {
      try {
        if (sessionStorage.length > 0) {
          sessionStorage.clear();
        }
        await oktaLogout(oktaAuth);
        navigate('/');
      } catch (error) {
        console.error('Logout error: ', error);
      }
    };

    handleLogout();
  }, [oktaAuth, navigate]);
  const handleHelpClick = () => {
    const pdfUrl = constants?.onboarding?.pdfLink;
    window.open(pdfUrl, '_blank');
  };
  return (
    <Styled.OnboardingMainContainer data-testid="onboarding-page-container">
      <Grid container justifyContent="center" alignItems="center">
        {!showLogout ? (
          <Grid className="mainContainer">
            <Grid item xs={12}>
              <PepsicoIcon />
              <Typography className="header">
                {constants?.onboarding?.title}
              </Typography>
              <Typography className="text">
                {constants?.onboarding?.text}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                className="onboardingBtn"
                onClick={login}
              >
                <RiKeyFill className="keyIcon" />
                <div>{constants?.onboarding?.loginWithSaml}</div>
                <div></div>
              </Button>
              <Button
                variant="contained"
                className="loginHelpBtn"
                onClick={handleHelpClick}
              >
                {constants?.onboarding?.loginHelp}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Loader />
            <Typography className="text">
              {'Metrics at rest. See you soon!'}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Styled.OnboardingMainContainer>
  );
}
