import {
  colorTokens,
  spacing,
  borderLight,
  borderDark,
  cornerRadius,
  typographyDesktop,
  typographyMobile,
  mergedTypography,
} from '../theme';

/**
 * Generates a styled-components theme object based on the provided mode.
 *
 * @param {string} mode - The current theme mode ('light' or 'dark').
 * @returns {object} The theme object for styled-components.
 */
export const getStyledTheme = (mode) => {
  const colors = mode === 'light' ? colorTokens.light : colorTokens.dark;
  const border = mode === 'light' ? borderLight : borderDark;

  return {
    colors,
    spacing,
    border,
    cornerRadius,
    typographyDesktop,
    typographyMobile,
    mergedTypography,
  };
};
