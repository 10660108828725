import { Grid } from "@mui/material";
import styled from "styled-components";
import { color } from "../../theme/Color";

interface MainConfigContainerProps {
  mode: any;
}

interface ScoreCardContainerProps {
  mode: any;
}

export const MainConfigContainer = styled(Grid)<MainConfigContainerProps>`
  color: ${color._efefef};

  & .header {
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161}` : `${color._efefef}`};
    font-family: "Inter";
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    margin-top: 20px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  & .placeholder {
    width: 100%;
    margin-top: 20px;
  }
  & .card {
    background: ${({ mode }) =>
      mode === "light"
        ? `${color._FBFCFE} !important`
        : `${color._1d1d1d} !important`};
    border: ${({ mode }) =>
      mode === "light" ? `1px solid ${color._D7E5F8}` : " "};
    border-radius: 8px;
    padding: 24px;
    margin-top: 20px;
  }
  & #ApdexScoreCard {
    min-height:365px;
    padding: 7px;
  }
  & .posRelative {
    position: relative;
  }
  & .coreWebVitalsTitle {
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161}` : `${color._efefef}`};
    font-family: "Inter";
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  & .card {
    background: ${({ mode }) =>
      mode === "light"
        ? `${color._FBFCFE} !important`
        : `${color._1d1d1d} !important`};
    border: ${({ mode }) =>
      mode === "light" ? `1px solid ${color._D7E5F8}` : " "};
    border-radius: 8px;
    padding: 24px;
    margin-top: 20px;
  }

  & .headerContainer {
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161}` : `${color._efefef} `};
    font-family: "Inter";
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
    width: 50%;
    margin-top: 20px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ScoreCardContainer = styled(Grid)<ScoreCardContainerProps>`
  & .infoIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  & .verticalBarContainer {
    border-width: 1px !important;
    height: 85px;
  }
`;
