import React, { useContext } from "react";
import { Grid, Divider } from "@mui/material";
import KpiDefinitionData from "service/KpiDefinitionData";
import Loader from "components/Loader/Loader";
import VerticalCardWithTrend from "components/VerticalCardWithTrend";
import KpiDefinitionDrawer from "components/KpiDefinitionDrawer/KpiDefinitionDrawer";
import { constants, screenSizes } from "constants/Constants";
import useCustomMediaQuery from "utils/useCustomMediaQuery";
import { helperFunctions } from "utils/helperFunctions";
import * as Styled from "./style";
import { ThemeContext } from "../../context/ThemeContext";
import { color } from "../../theme/Color";
import { FilterContext } from "components/FilterBar/FilterProvider";

interface SummaryMetricsProps {
  summaryData: any;
  isLoading?: boolean;
  showInfoIcon?: boolean;
  istopkpi?: boolean;
}
const SummaryMetrics: React.FC<SummaryMetricsProps> = ({
  summaryData,
  isLoading = false,
  showInfoIcon = false,
  istopkpi = true,
}) => {
  const { mode } = useContext(ThemeContext);
  const {
    getValueAbbreviationParser,
    percentageParser,
    formatTimeWithSeconds,
  } = helperFunctions();
  const isMobile = useCustomMediaQuery(screenSizes.mobile);
  const ipadMini = useCustomMediaQuery(screenSizes.ipadMini);
  const ipadPro = useCustomMediaQuery(screenSizes.ipadPro);
  const ipadAir = useCustomMediaQuery(screenSizes.ipadAir);

  const isNotDesktop = ipadMini || ipadPro || ipadAir || isMobile;
  const { selectedProduct } = useContext(FilterContext);
  return isLoading ? (
    <Loader />
  ) : (
    <Styled.ScoreCardContainer data-testid="summary-metrics" mode={mode}>
      <Grid
        container
        xs={12}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Grid
          item
          xs={12}
          md={5}
          sm={5}
          lg={5}
          sx={{ margin: isMobile ? "10px 0px" : "0px" }}
        >
          <VerticalCardWithTrend
            value={summaryData?.avgApiAppResponseTime?.toString() || null}
            parserConfig={{
              parser: formatTimeWithSeconds,
              params: [isNotDesktop],
            }}
            description={"AVG API RESPONSE TIME"}
            trendValue={summaryData?.avgApiAppResponseTimeDelta}
            trendParserConfig={{
              parser: percentageParser,
              params: [null, true],
            }}
            showOppSign={selectedProduct?.productId===constants.products.GPG ? true: false}
          />
        </Grid>
        {!isMobile && (
          <Grid item>
            <Divider
              flexItem={true}
              className="verticalBarContainer"
              orientation="vertical"
              sx={{
                background: mode === "light" ? color._B2CAEA : color._666666,
              }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={5}
          sm={5}
          lg={5}
          sx={{ margin: isMobile ? "10px 0px" : "0px" }}
        >
          <VerticalCardWithTrend
            value={summaryData?.totalNoOfErrors}
            parserConfig={{ parser: getValueAbbreviationParser, params: [] }}
            description={"TOTAL NUMBER OF ERRORS"}
            trendValue={summaryData?.totalNoOfErrorsDelta}
            trendParserConfig={{
              parser: percentageParser,
              params: [null, true],
            }}
            showOppSign={selectedProduct?.productId===constants.products.GPG ? true: false}
          />
        </Grid>
      </Grid>
      {showInfoIcon && (
        <div className="infoIcon">
          <KpiDefinitionDrawer
            title=""
            KpiDefinitionList={
              KpiDefinitionData[selectedProduct?.productId]
                ?.applicationPerformanceRedesigned?.scoreCard ?? []
            }
          />
        </div>
      )}
    </Styled.ScoreCardContainer>
  );
};

export default SummaryMetrics;
