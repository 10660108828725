export const formatValueWithMiddlewares = (value, middlewares) => {
  const applyMiddleware = (index, value) => {
    if (index >= middlewares.length) {
      return value;
    }
    return middlewares[index](value, (nextValue) =>
      applyMiddleware(index + 1, nextValue)
    );
  };
  return applyMiddleware(0, value);
};

export const checkForNA = (value, next) => {
  if (value === null || value === undefined || isNaN(Number(value))) {
    return 'NA';
  }
  return next ? next(value) : value;
};

export const removeTrailingZero = (value, next) => {
  const numericValue = Number(value);
  return next
    ? next(
        numericValue % 1 === 0
          ? numericValue.toFixed(0)
          : numericValue.toString()
      )
    : value;
};

export const replaceNegativeSign = (value, next) => {
  if (value === null || value === undefined) {
    return 'NA';
  }
  const stringValue = value.toString();
  const formattedValue = stringValue.replace('-', '');
  return next ? next(formattedValue) : formattedValue;
};

export const applySuffix = (value, next) => {
  const numericValue = Number(value);
  const formattedValue =
    numericValue >= 1000000
      ? { value: (numericValue / 1000000).toFixed(1), suffix: 'M' }
      : numericValue >= 1000
        ? { value: (numericValue / 1000).toFixed(1), suffix: 'K' }
        : { value: numericValue, suffix: '' };
  if (formattedValue.value % 1 === 0) {
    formattedValue.value = parseInt(formattedValue.value);
  }
  return next ? next(`${formattedValue.value}${formattedValue.suffix}`) : value;
};

export const appendPercentage = (value, next) => {
  const numericValue = Number(value);
  return next ? next(`${numericValue}%`) : `${numericValue}%`;
};

export const applyDollarSign = (value, next) => {
  const formattedValue = `$${value}`;
  return next ? next(formattedValue) : formattedValue;
};

export const roundToTwoDecimalPlaces = (value, next) => {
  if (value === null || value === undefined || isNaN(Number(value))) {
    return 'NA';
  }

  const numericValue = Number(value);
  const roundedValue = numericValue.toFixed(2);

  return next ? next(roundedValue) : roundedValue;
};

export const createKpiTileItem = (
  title,
  value,
  tag,
  subTitle = null,
  secondTitle = null
) => ({
  title,
  secondTitle,
  value,
  tag: tag && tag.text !== 'NA' ? tag : null,
  subTitle,
});

const period = {
  last24hours: 1,
  daily: 7,
  weekly: 28,
  monthly: 182,
  yearly: 365,
};

export const parseDateTimeBasedOnPeriodType = (value, p) => {
  const date = new Date(value);
  const monthNumber = date.getUTCMonth() + 1;
  const dayValue = date.getUTCDate();
  const yearValue = date.getUTCFullYear();

  switch (p) {
    case period?.last24hours:
      return utcTimeToHourAndMinute(value);
    case period?.daily:
      return getDateAndMonth(value);
    case period?.weekly:
      return `Wk ${monthNumber}/${dayValue}`;
    case period?.monthly:
      return getMonthShortFromDateString(value);
    case period?.yearly:
      return yearValue;
    default:
      return '';
  }
};

export const toPascalCase = (value, next) => {
  if (typeof value !== 'string') {
    return next ? next(value) : value;
  }
  const pascalCaseValue = value
    ?.toLowerCase()
    ?.replace(
      /(\w)(\w*)/g,
      (_, firstChar, rest) => firstChar?.toUpperCase() + rest
    );
  return next ? next(pascalCaseValue) : pascalCaseValue;
};

export const toOneDecimalPlace = (value, next) => {
  // Convert the input to a Number
  const numericValue = Number(value);

  // Round the value to one decimal place.
  // Multiplying by 10 shifts the decimal, rounding,
  // then dividing by 10 shifts the decimal back.
  const oneDecimalValue = Math.round(numericValue * 10) / 10;

  // If a next middleware function is provided, pass the one decimal value to it;
  // otherwise, simply return the one decimal value.
  return next ? next(oneDecimalValue) : oneDecimalValue;
};

//===================================
// Non  Exported Functions
//===================================

function getMonthShortFromDateString(dateTime) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    timeZone: 'UTC',
  });
  return formatter.format(new Date(dateTime));
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC',
});

function getDateAndMonth(dateTime) {
  return dateFormatter.format(new Date(dateTime));
}

const timeFormatter = new Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: '2-digit',
  timeZone: 'UTC',
});

function utcTimeToHourAndMinute(date) {
  return timeFormatter.format(new Date(date));
}
