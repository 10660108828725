import jwt_decode from 'jwt-decode';
import { constants } from '../constants/Constants';

export const helperFunctions = () => {
  function decodeUserProfile(idToken) {
    try {
      const userProfile = jwt_decode(idToken?.accessToken);
      const nameInitial =
        (userProfile?.FirstName?.[0] ?? '') +
        (userProfile?.LastName?.[0] ?? '');
      return {
        userConfig:
          userProfile?.pepapppsdashboardpermission.length > 0
            ? userProfile?.pepapppsdashboardpermission
            : ['Regular User', 'ru'],
        firstName: userProfile.FirstName,
        lastName: userProfile.LastName,
        nameInitial: nameInitial,
        email: userProfile?.email,
        gpId: userProfile?.gpid,
        name: userProfile?.FirstName + ' ' + userProfile?.LastName,
      };
    } catch (err) {
      return null;
    }
  }

  function isInvalidValue(value) {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      value === 'null' ||
      value === 'undefined'
    );
  }

  function getNAForInvalidValue(value) {
    if (isInvalidValue(value) || isNaN(value)) {
      return 'NA';
    } else if (
      typeof value === 'number' &&
      Number.isFinite(value) &&
      value % 1 !== 0
    ) {
      // Format decimal numbers with two decimal places
      return value.toFixed(2);
    } else {
      return value;
    }
  }

  function getNAForPercentageValue(number) {
    if (isInvalidValue(number) || isNaN(number)) {
      return 'NA';
    }
    return number + '%';
  }

  function getRoundedValueForDecimal(number) {
    return Math.round(number * 10) / 10;
  }

  function getRoundedValueForDecimalWithUnit(number, unit) {
    const calculatedVal = Math.round(number * 10) / 10;
    if (isNaN(calculatedVal) || isInvalidValue(calculatedVal)) {
      return 'NA';
    } else {
      let unitValue = '';
      if (unit == 'Minutes') {
        unitValue = 'min';
      }

      if (unit.toString().toLowerCase().includes('day')) {
        if (calculatedVal == 1) {
          unitValue = 'day';
        } else {
          unitValue = 'days';
        }
      }
      return calculatedVal + ' ' + unitValue;
    }
  }

  function getValueWithUnit(number, unit) {
    if (isInvalidValue(number) || isNaN(number)) {
      return 'NA';
    }

    let unitValue = '';

    if (unit != null && unit != undefined && unit == 'Minutes') {
      unitValue = 'min';
    }

    if (
      unit != null &&
      unit != undefined &&
      unit.toString().toLowerCase().includes('day')
    ) {
      if (number == 1) {
        unitValue = 'day';
      } else {
        unitValue = 'days';
      }
    }

    return number + ' ' + unitValue;
  }

  function getAbbrValueWithUnit(number, unit) {
    if (isInvalidValue(number) || isNaN(number)) {
      return 'NA';
    }

    const abrValue = getValueAbbreviationParser(number);
    const abrUnit = getAbbrUnit(unit, number);

    return abrValue + ' ' + abrUnit;
  }

  function getZeroValueForInvalidValue(value) {
    if (isNaN(value) || isInvalidValue(value)) {
      value = 0;
    }
    return value;
  }

  // value is string of integer, isShowSign is boolean define UI should show number sign or not.
  function parseSign(value, isShowSign) {
    let sign = '';
    if (isShowSign) {
      if (value > 0) {
        sign = '+';
      } else if (value < 0) {
        sign = '-';
      }
    } else {
      sign = value >= 0 ? '' : '-';
    }
    return sign;
  }

  // getValueAbbreviationParser hook parse value to value with abbreviation.
  // input (value: string, isShowSign?: boolean) ex: (135000, true) (135000) (-1200) (2.4)
  // output: string ex: (+135.0K) (135.0K)  (-1.2K) (2.4)
  function getValueAbbreviationParser(value, isShowSign) {
    if (isInvalidValue(value) || isNaN(value)) {
      return 'NA';
    }
    const sign = parseSign(Number(value), isShowSign);
    const absValue = Math.abs(Number(value));
    const abbreviation = ['T', 'B', 'M', 'K'];
    const list = [10 ** 12, 10 ** 9, 10 ** 6, 10 ** 3];
    for (let i = 0; i < list.length; i++) {
      if (absValue >= list[i]) {
        return sign + ((absValue / list[i]).toFixed(1) + abbreviation[i]);
      }
    }

    if (checkForDecimal(absValue)) {
      const result = sign + absValue.toFixed(1);
      return result.endsWith('.0') ? result.slice(0, -2) : result;
    }

    return sign + absValue;
  }

  function getAbsValueAbbreviationParser(value) {
    if (isInvalidValue(value)) {
      return 'NA';
    }
    const absValue = Math.abs(Number(value));
    const abbreviation = ['T', 'B', 'M', 'K'];
    const list = [10 ** 12, 10 ** 9, 10 ** 6, 10 ** 3];
    for (let i = 0; i < list.length; i++) {
      if (absValue >= list[i]) {
        return (Math.round(absValue / list[i]) + abbreviation[i]).toString();
      }
    }
    return absValue.toString();
  }

  function getDigitalOrdersChartOperationalMetricsCriteria(value) {
    const requestMetric = JSON.parse(JSON.stringify(value));
    return requestMetric;
  }

  function getAbbrUnit(unit, value) {
    const unitObj = {
      minutes: 'min',
      days: 'day',
      hours: 'hr',
      seconds: 'sec',
      milliseconds: 'm',
    };

    const unitMap = new Map(Object.entries(unitObj));

    unit = unit ? unit.toString().toLowerCase() : '';

    if (!unitMap.has(unit) || unit === 'milliseconds') {
      return unit;
    }

    return value == 1 ? unitMap.get(unit) : unitMap.get(unit) + 's';
  }

  function getHoursForSeconds(seconds) {
    const hours = seconds / 3600;

    const valCheck = getValueAbbreviationParser(hours);
    if (valCheck < 1) {
      const n = Number(valCheck);
      return n.toFixed(1);
    }

    return getValueAbbreviationParser(hours.toFixed(1));
  }

  function getMinutesForSeconds(seconds) {
    const minutes = seconds / 60;

    const valCheck = getValueAbbreviationParser(minutes);
    if (valCheck < 1) {
      const n = Number(valCheck);
      return n.toFixed(1);
    }
    return getValueAbbreviationParser(minutes.toFixed(1));
  }

  function getAbbrTimeWithUnit(number, unit) {
    let finalVal = getNAForInvalidValue(number);

    if (finalVal !== 'NA') {
      let abrTime = '';

      if (unit == 'hours') {
        abrTime = getHoursForSeconds(number);
      } else {
        abrTime = getMinutesForSeconds(number);
      }

      const abrUnit = getAbbrUnit(unit, number);
      finalVal = abrTime + ' ' + abrUnit;
    }

    return finalVal;
  }

  // ex: input 2022-09-25T11:07:00.000Z output: 11:07
  function utcTimeToHourAndMinute(date) {
    const dateToFormat = new Date(date);
    return (
      dateToFormat.getUTCHours() +
      ':' +
      (dateToFormat.getUTCMinutes() < 10
        ? '0' + dateToFormat.getUTCMinutes()
        : dateToFormat.getUTCMinutes())
    );
  }

  function hasCharacters(inputString, charactersArray) {
    for (const char of charactersArray) {
      if (inputString.includes(char)) {
        return true;
      }
    }
    return false;
  }

  // We don't need to show different currency types for now, so leave it flexable in case we need it in the future.
  function parseCurrencyType(currency) {
    if (currency === undefined || currency === null) {
      currency = String.fromCharCode(0x24);
    }
    return currency;
  }

  // currencyParser hook pass currency.
  // Input (value: string, currencyType: string)
  // Output formated string example: $31M, $158.6K
  const currencyParser = (value, isShowSign, currency) => {
    const currencyType = parseCurrencyType(currency);
    const numberAbbreviation = getValueAbbreviationParser(value, isShowSign);
    if (numberAbbreviation === 'NA') {
      return numberAbbreviation;
    } else {
      return currencyType + numberAbbreviation;
    }
  };

  // Parse Percentage with number.
  // If round digit need add logic here.
  const percentageParser = (number, digitToFixed, isShowSign) => {
    if (isInvalidValue(number)) {
      return 'NA';
    }
    let sign = '';
    if (isShowSign) {
      if (number > 0) {
        sign = '+';
      } else if (number < 0 && number.toString().charAt(0) !== '-') {
        // Need to remove later after checking the impact
        sign = '-';
      }
    }
    if (digitToFixed === undefined || digitToFixed === null) {
      return sign + number + '%';
    }
    return sign + Number(number).toFixed(digitToFixed) + '%';
  };

  // Function to get Month & Date from a Datetime object
  // Sample Input -> (Datetime object) -> 2023-07-01T10:00:32.440Z
  // Sample Output-> (String value) -> Jul 1
  function getDateAndMonth(dateTime) {
    const date = new Date(dateTime);
    const day = date.getUTCDate();
    const month = getMonthShortFromDateString(dateTime);
    return month + ' ' + day;
  }

  // The function will have "2023-01-01" -> with Dec in US timezone
  function getMonthFromDate(dateTime) {
    const date = new Date(dateTime);
    return date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
  }

  // Input: "2023-01-01"  Output: "Jan"
  function getMonthShortFromDateString(dateTime) {
    const monthNumber = new Date(dateTime).getUTCMonth();
    const Month = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return Month[monthNumber];
  }

  function createMetricConfig(config) {
    return config.map((item) => ({
      value: item.value,
      valuePrefix: item.valuePrefix || '',
      valueParser: item.valueParser || getValueAbbreviationParser,
      valueSuffix: item.valueSuffix || '',
      title: item.title || 'Untitled',
      checkPositive: item.checkPositive || false,
      subtitle: item.subtitle || '',
    }));
  }

  function generateMetricOverViewConfig(gsScoreData) {
    return createMetricConfig([
      {
        value: gsScoreData?.revenue,
        title: `${constants.overview.revenue}`,
        valuePrefix: '$',
      },
      {
        value: gsScoreData?.basketSize,
        title: `${constants.overview.basketSize}`,
      },
      {
        value: gsScoreData?.skusPerOrder,
        title: `${constants.overview.skuPerOrder}`,
      },
      {
        value: gsScoreData?.skusRemoved,
        title: `${constants.overview.skuRemoved}`,
      },
      {
        value: gsScoreData?.skusAdded,
        title: `${constants.overview.skuAdded}`,
      },
      {
        value: gsScoreData?.soDrivenOrders,
        valueSuffix: '%',
        title: `${constants.overview.drivenOrders}`,
      },
      {
        value: gsScoreData?.storeUniverse,
        title: `${constants.overview.storeUniverse}`,
      },
    ]);
  }

  const checkForDecimal = (value) => {
    if (value != undefined && value != null) {
      return value.toString().includes('.');
    }
  };

  function getTextPostfix(textpostfix) {
    return getValueAbbreviationParser(textpostfix) == 'NA' ? '' : '%';
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // input (dateTime: "2023-01-01T00:00:00.000Z", period: constants.period)
  // output based on the period, it will show different output.
  function parseDateTimeBasedOnPeriodType(value, period) {
    const monthNumber = new Date(value).getUTCMonth() + 1;
    const dayValue = new Date(value).getUTCDate();
    const yearValue = new Date(value).getUTCFullYear();
    switch (period) {
      case constants?.period?.last24hours:
        return utcTimeToHourAndMinute(value);
      case constants?.period?.daily:
        return getDateAndMonth(value);
      case constants?.period?.weekly:
        return 'Wk ' + monthNumber + '/' + dayValue;
      case constants?.period?.monthly:
        return getMonthShortFromDateString(value);
      case constants?.period?.yearly:
        return yearValue;
      default:
        return '';
    }
  }

  function getValueWithUnitAppended(value, unit) {
    const numberAbbreviation = getValueAbbreviationParser(value);
    if (numberAbbreviation === 'NA') {
      return numberAbbreviation;
    } else {
      return numberAbbreviation + unit;
    }
  }

  const debounce = (func, delay) => {
    let timeoutId;

    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  function minuteToHourMinute(value, inSeconds = false) {
    if (isInvalidValue(value) || typeof value === 'undefined') {
      return 'NA';
    }
    if (inSeconds) {
      const numberValue = Number(value);
      if (numberValue === 0) {
        return '0 sec';
      } else if (numberValue === 1) {
        return '1 sec';
      } else if (typeof value === 'string') {
        return value + ' secs';
      } else {
        return value + 'secs';
      }
    }
    // Round the value to handle decimal places
    value = Math.round(value);
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    // Format hour and minute strings
    let hourString = '';
    if (hours > 0) {
      hourString = `${hours} ${hours === 1 ? 'hr' : 'hrs'}`;
    }

    let minuteString = '';
    if (minutes > 0) {
      minuteString = `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
    }

    // Combine hour and minute strings with appropriate spacing
    const formattedTime = [hourString, minuteString].filter(Boolean).join(' ');

    return formattedTime || '0 min'; // Handle the case where the value is 0
  }

  function convert24hTo12h(timeStr) {
    if (isInvalidValue(timeStr)) {
      return 'NA';
    }
    const [hour, minute] = timeStr.split(':');
    const hour24 = Number(hour);
    const period = hour24 >= 12 ? 'PM' : 'AM';
    // convert 0:00 to 12:00
    const hour12 = hour24 % 12 || 12;
    const formattedHour12 = hour12 < 10 ? '0' + hour12 : '' + hour12;
    return formattedHour12 + ':' + minute + period;
  }

  function normalDistribution(x, mean, standardDeviation) {
    return (
      (1 / (standardDeviation * Math.sqrt(2 * Math.PI))) *
      Math.exp(-0.5 * ((x - mean) / standardDeviation) ** 2)
    );
  }

  const hasSingleValidNumber = (data) => {
    const validNumbers = data?.filter(
      (value) =>
        value !== null && typeof value === 'number' && Number.isFinite(value)
    );
    return validNumbers?.length === 1;
  };

  function secondsToMinuteSecond(value) {
    if (isInvalidValue(value)) {
      return 'NA';
    }

    // Check if value is a decimal with one or two decimal places
    const isDecimal = /^\d+\.\d{1,2}$/.test(value);

    // Round to nearest whole number if it's a decimal with one or two decimal places
    if (isDecimal) {
      value = Math.round(parseFloat(value));
    }
    const hours = Math.trunc(value / 3600);
    const remainingSeconds = value % 3600;
    const minutes = Math.trunc(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    if (value <= 60) {
      if (value === 60) {
        return '1m';
      } else {
        return `${seconds}s`;
      }
    }

    const hourPart = hours > 0 ? `${hours}h` : '';
    const minutePart = minutes > 0 ? `${minutes}m` : '';
    const secondPart = seconds > 0 ? `${seconds}s` : '';

    const timeParts = [hourPart, minutePart, secondPart].filter(
      (part) => part !== ''
    );

    return timeParts.length > 0 ? timeParts.join(' ') : '0s';
  }

  function secsToMinsSecs(value) {
    if (isInvalidValue(value)) {
      return 'NA';
    }
    // Check if value is a decimal with one or two decimal places
    const isDecimal = /^\d+\.\d{1,2}$/.test(value);

    // Round to nearest whole number if it's a decimal with one or two decimal places
    if (isDecimal) {
      value = Math.round(parseFloat(value));
    }
    const hours = Math.trunc(value / 3600);
    const remainingSeconds = value % 3600;
    const minutes = Math.trunc(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    if (value <= 60) {
      if (value === 60) {
        return '1m';
      } else {
        return `${seconds}s`;
      }
    }

    const hourPart = hours > 0 ? `${hours}hrs` : '';
    const minutePart = minutes > 0 ? `${minutes}mins` : '';
    const secondPart = seconds > 0 ? `${seconds}secs` : '';

    const timeParts = [hourPart, minutePart, secondPart].filter(
      (part) => part !== ''
    );

    return timeParts.length > 0 ? timeParts.join(' ') : '0s';
  }

  // check if current application environment is Devlopment or QA.
  function isDevOrQaEnv() {
    const isDevEnv =
      window.REACT_APP_CAF_URI === constants?.env?.devEnv ||
      window.REACT_APP_REDIRECT_URI === constants?.env?.devUI;
    const isQaEnv = window.REACT_APP_CAF_URI === constants?.env?.qaEnv;
    return isDevEnv || isQaEnv;
  }

  // check if current application environment is Devlopment or QA or PreProd.
  function isDevOrQaOrPreProdEnv() {
    const isDevEnv =
      window.REACT_APP_CAF_URI === constants?.env?.devEnv ||
      window.REACT_APP_REDIRECT_URI === constants?.env?.devUI;
    const isQaEnv = window.REACT_APP_CAF_URI === constants?.env?.qaEnv;
    const isPreProdEnv =
      window.REACT_APP_CAF_URI === constants?.env?.preProdEnv;
    return isDevEnv || isQaEnv || isPreProdEnv;
  }

  function isDevEnv() {
    return (
      window.REACT_APP_CAF_URI === constants?.env?.devEnv ||
      window.REACT_APP_REDIRECT_URI === constants?.env?.devUI
    );
  }

  const extractNumberAndUnits = (value) => {
    let numberPart = value;
    let unitPart = '';
    if (typeof value === 'string' && value !== 'NA') {
      const numericPart = value.match(/^\$?-?\d+(\.\d+)?/);
      const unitsPart = value.match(/[A-Za-z%]{1,10}$/);
      if (numericPart && unitsPart) {
        numberPart = numericPart[0];
        unitPart = unitsPart[0];
      }
      return { numberPart, unitPart };
    } else {
      return { numberPart, unitPart };
    }
  };

  function getToolMapProject(toolData) {
    const basicOption = { value: 'All', label: 'All' };
    const toolSet = new Set();
    const projectSet = new Set();
    const toolProjectMapping = new Map();

    for (const item of toolData) {
      toolSet.add(item.toolName);
      for (const project of item.projects) {
        projectSet.add(project);
      }
      toolProjectMapping.set(item.toolName, [
        basicOption,
        ...item.projects.map((projectItem) => ({
          value: projectItem,
          label: projectItem,
        })),
      ]);
    }

    toolProjectMapping.set('All', [
      basicOption,
      ...Array.from(projectSet).map((item) => ({ value: item, label: item })),
    ]);
    const tools = [
      basicOption,
      ...Array.from(toolSet).map((item) => ({ value: item, label: item })),
    ];
    const projects = [
      basicOption,
      ...Array.from(projectSet).map((item) => ({ value: item, label: item })),
    ];

    return { tools, projects, toolProjectMapping };
  }

  // imput is an array of {type: "xxx" , filterOption: react-select option}
  function filterByOptions(data, filterOptions) {
    for (const filter of filterOptions) {
      const { type, filterOption } = filter;
      if (filterOption && filterOption.value !== 'All') {
        data = data.filter((item) => item[type] === filterOption?.value);
      }
    }
    return data;
  }

  const convertToSelectOptions = (responseArray) => {
    if (!Array.isArray(responseArray)) {
      return [];
    }

    return responseArray?.map((item) => ({ value: item, label: item }));
  };

  const convertToSelectOptionsWithValue = (responseObject) => {
    if (typeof responseObject !== 'object' || responseObject === null) {
      return [];
    }

    return Object.entries(responseObject).map(([value, label]) => ({
      value,
      label,
    }));
  };

  const formatTimeWithSeconds = (value) => {
    let formattedTime = '';

    if (isInvalidValue(value)) {
      return 'NA';
    }

    if (value < 60) {
      value = parseFloat(value);
    } else {
      value = Math.round(value);
    }

    if (value < 60) {
      // Less than 60 seconds
      const secLabel = 's';
      formattedTime = `${value} ${secLabel}`;
    } else if (value < 3600) {
      // Between 60 seconds and 3600 seconds
      const minutes = Math.floor(value / 60);
      const seconds = value % 60;
      const minLabel = 'm';
      const secLabel = 's';
      formattedTime =
        seconds === 0
          ? `${minutes} ${minLabel}`
          : `${minutes} ${minLabel} ${seconds} ${secLabel}`;
    } else if (value >= 3601 && value <= 3659) {
      // Special case for 3601 to 3659 seconds
      const hours = 1; // Fixed as 1 hour
      const seconds = value - 3600;
      const hrLabel = 'h';
      const secLabel = 's';
      formattedTime = `${hours} ${hrLabel} ${seconds} ${secLabel}`;
    } else {
      // More than 3600 seconds
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      const hrLabel = 'h';
      const minLabel = 'm';
      formattedTime =
        minutes === 0
          ? `${hours} ${hrLabel}`
          : `${hours} ${hrLabel} ${minutes} ${minLabel}`;
    }
    return formattedTime;
  };

  const calculateLgForTwoBlocks = (showBlock1, showBlock2) => {
    let block1Lg = 12;
    let block2Lg = 12;
    const activeBlocks = [showBlock1, showBlock2].filter(Boolean).length;

    if (activeBlocks === 2) {
      if (showBlock1 && showBlock2) {
        block1Lg = block2Lg = 6;
      }
    }

    return { block1Lg, block2Lg };
  };

  const calculateLgForThreeBlocks = (showBlock1, showBlock2, showBlock3) => {
    let block1Lg = 12;
    let block2Lg = 12;
    let block3Lg = 12;

    const activeBlocks = [showBlock1, showBlock2, showBlock3].filter(
      Boolean
    ).length;

    if (activeBlocks === 3) {
      block1Lg = 4.5;
      block2Lg = 4.5;
      block3Lg = 3;
    } else if (activeBlocks === 2) {
      if (showBlock1 && showBlock2) {
        block1Lg = block2Lg = 6;
      } else if (showBlock1 && showBlock3) {
        block1Lg = block3Lg = 6;
      } else if (showBlock2 && showBlock3) {
        block2Lg = block3Lg = 6;
      }
    }

    return { block1Lg, block2Lg, block3Lg };
  };

  function getRatioValueParser(value, isShowSign) {
    if (isInvalidValue(value)) {
      return 'NA';
    }

    if (isRatioString(value)) {
      return value;
    }

    if (isNaN(value)) {
      return 'NA';
    }

    return formatValue(Number(value), isShowSign);
  }

  function isRatioString(value) {
    if (typeof value === 'string' && value.includes(':')) {
      const ratioParts = value.split(':');
      return (
        ratioParts.length === 2 &&
        !isNaN(ratioParts[0]) &&
        !isNaN(ratioParts[1])
      );
    }
    return false;
  }

  function formatValue(value, isShowSign) {
    const sign = parseSign(value, isShowSign);
    const absValue = Math.abs(value);
    const abbreviation = ['T', 'B', 'M', 'K'];
    const list = [10 ** 12, 10 ** 9, 10 ** 6, 10 ** 3];

    for (let i = 0; i < list.length; i++) {
      if (absValue >= list[i]) {
        return sign + (absValue / list[i]).toFixed(1) + abbreviation[i];
      }
    }

    return formatDecimalValue(absValue, sign);
  }

  function formatDecimalValue(absValue, sign) {
    if (checkForDecimal(absValue)) {
      const result = sign + absValue.toFixed(1);
      return result.endsWith('.0') ? result.slice(0, -2) : result;
    }
    return sign + absValue;
  }

  function getValueToCommaSeparatedFormate(value) {
    if (isInvalidValue(value)) {
      return 'NA';
    }
    return Number(value).toLocaleString('en-US');
  }

  function getValueToCommaSeparatedFormateWithDecimal(value, digit) {
    if (isInvalidValue(value)) {
      return 'NA';
    }
    return Number(Number(value).toFixed(digit)).toLocaleString('en-US');
  }

  function getDayShortFromDateString(dateTime) {
    // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
    const dayNumber = new Date(dateTime).getUTCDay();
    const Days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return Days[dayNumber];
  }

  return {
    debounce,
    decodeUserProfile,
    getNAForInvalidValue,
    getNAForPercentageValue,
    getRoundedValueForDecimal,
    getRoundedValueForDecimalWithUnit,
    getValueToCommaSeparatedFormateWithDecimal,
    getValueWithUnit,
    getZeroValueForInvalidValue,
    getValueToCommaSeparatedFormate,
    parseSign,
    getValueAbbreviationParser,
    getAbbrValueWithUnit,
    getDigitalOrdersChartOperationalMetricsCriteria,
    getAbsValueAbbreviationParser,
    getAbbrUnit,
    getHoursForSeconds,
    getMinutesForSeconds,
    getAbbrTimeWithUnit,
    hasCharacters,
    parseCurrencyType,
    currencyParser,
    percentageParser,
    getDateAndMonth,
    getMonthFromDate,
    getMonthShortFromDateString,
    createMetricConfig,
    checkForDecimal,
    getTextPostfix,
    utcTimeToHourAndMinute,
    descendingComparator,
    getComparator,
    stableSort,
    parseDateTimeBasedOnPeriodType,
    getValueWithUnitAppended,
    minuteToHourMinute,
    convert24hTo12h,
    normalDistribution,
    hasSingleValidNumber,
    secondsToMinuteSecond,
    secsToMinsSecs,
    isDevOrQaEnv,
    extractNumberAndUnits,
    getToolMapProject,
    filterByOptions,
    isDevOrQaOrPreProdEnv,
    convertToSelectOptions,
    isDevEnv,
    convertToSelectOptionsWithValue,
    formatTimeWithSeconds,
    calculateLgForTwoBlocks,
    calculateLgForThreeBlocks,
    getRatioValueParser,
    generateMetricOverViewConfig,
    isInvalidValue,
    isRatioString,
    formatValue,
    formatDecimalValue,
    getDayShortFromDateString,
  };
};

export const getActiveNavigation = (navigation, currentLocation) => {
  const visibleNavs = navigation?.filter((n) => !n.hide);
  const urlParts = currentLocation.pathname.split('/');
  // home page urlParts[1] will be empty string, so appending trailing slash
  const matchingString = urlParts[1] ? urlParts[1] : `/`;
  let activeNavigation = visibleNavs && visibleNavs[0];
  // trying to match the URL string partially, in order to get parent index
  const navIndex = visibleNavs?.findIndex((item) => {
    const path = item.path || item.basePath;
    return path.includes(matchingString);
  });
  //if somehow uRL is not found in the list then set 0 for home page
  if (navIndex >= -1) {
    activeNavigation = navIndex < 0 ? visibleNavs[0] : visibleNavs[navIndex];
  }
  return { activeNavigation, navIndex: navIndex < 0 ? 0 : navIndex };
};
