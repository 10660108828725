import styled from 'styled-components';

export const DownloadButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #0066ff;
  position: relative;

  &:hover {
    background-color: trasnsaprent;
  }
  &:disabled {
    opacity: 0.5;
    color: darkgray !important;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-85%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  transition:
    opacity 0.3s,
    visibility 0.3s;

  ${DownloadButton}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background-color: white;
  border-radius: 4px;
  width: 200px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  overflow: hidden;
`;

export const MenuItem = styled.button`
  display: grid;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 10px 16px;
  background-color: white;
  border: none;
  cursor: pointer;
  text-align: left;
  color: #1f2937;
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f3f4f6;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
`;
