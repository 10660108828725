import React, { useContext } from "react";
import * as Styled from "./style";
import { Grid, Typography } from "@mui/material";
import { ThemeContext } from "../../context/ThemeContext";

export default function VerticalCardWithTrend({
  value,
  parserConfig = { parser: (value) => value, params: [] },
  description,
  trendValue = null,
  showOppSign = false,
  trendParserConfig = { parser: (value) => value, params: [] },
  styles = {},
  istopkpi = true,
}) {
  const { mode } = useContext(ThemeContext);
  const { parser: valueParser, params: valueParserParams } = parserConfig;
  const { parser: trendValueParser, params: trendParserParams } =
    trendParserConfig;
  const getTrendClass = (trendValue, showOppSign) =>
    showOppSign
      ? trendValue >= 0
        ? "trendNegative"
        : "trendPositive"
      : trendValue >= 0
        ? "trendPositive"
        : "trendNegative";

  return (
    <Styled.VerticalCardWithTrendContainer
      data-testid="vertical-card-with-trend-container"
      mode={mode}
      istopkpi={istopkpi}
    >
      <Grid container>
        <Grid item xs={12} className={`metricsValue ${styles?.large || ""}`}>
        {valueParser(value, ...valueParserParams)}
        </Grid>
        <Grid
          item
          xs={12}
          className={`metricsDescription ${styles?.small || ""}`}
        >
          {description}
        </Grid>
        {trendValue ? (
          <Grid item xs={12}>
            <Typography className={getTrendClass(trendValue, showOppSign)}>
              {trendValueParser(trendValue, ...trendParserParams)}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Styled.VerticalCardWithTrendContainer>
  );
}
