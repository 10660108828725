import React from 'react';
import {
  GridContainer,
  Row,
  Cell,
  HorizontalDivider,
  InvisibleDivider,
} from './style';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { KpiGridProps } from './types';

const KpiGrid: React.FC<KpiGridProps> = ({
  columnsMobile = 2,
  columnsTablet = 5,
  columnsDesktop = 5,
  showBorderMobile = true,
  showBorderTablet = true,
  showBorderDesktop = true,
  showHDividerMobile = true,
  showHDividerTablet = true,
  showHDividerDesktop = true,
  showHDividerLastRow = false, // new optional prop with default
  children,
}) => {
  const breakpoint = useBreakpoint(); // returns 'mobile' | 'tablet' | 'desktop'

  // Determine settings based on the current breakpoint
  let columns = columnsMobile;
  let showHDivider = showHDividerMobile;
  let showBorder = showBorderMobile;

  if (breakpoint === 'tablet') {
    columns = columnsTablet;
    showHDivider = showHDividerTablet;
    showBorder = showBorderTablet;
  } else if (breakpoint === 'desktop') {
    columns = columnsDesktop;
    showHDivider = showHDividerDesktop;
    showBorder = showBorderDesktop;
  }

  // Convert children to an array and group them into rows with exactly "columns" cells.
  const childrenArray = React.Children.toArray(children);
  const rows = [];
  for (let i = 0; i < childrenArray.length; i += columns) {
    let row = childrenArray.slice(i, i + columns);
    // If this row is incomplete, add empty placeholders.
    if (row.length < columns) {
      row = row.concat(new Array(columns - row.length).fill(null));
    }
    rows.push(row);
  }

  return (
    <GridContainer data-testid="kpi-grid">
      {rows.map((row, rowIndex) => {
        // Determine whether we should reserve space (either via a visible divider or spacer)
        const shouldReserveGap =
          rowIndex < rows.length - 1 ||
          (rowIndex === rows.length - 1 && showHDividerLastRow);

        return (
          <React.Fragment key={rowIndex}>
            <Row data-testid="row">
              {row.map((child, cellIndex) => (
                <Cell
                  key={cellIndex}
                  data-testid="cell"
                  // Do not show border if the cell is empty.
                  showBorder={child ? showBorder : false}
                  isLastCell={cellIndex === columns - 1}
                >
                  {child}
                </Cell>
              ))}
            </Row>

            {shouldReserveGap &&
              (showHDivider ? (
                <HorizontalDivider
                  data-testid="horizontal-divider"
                  lastRowDivider={
                    rowIndex === rows.length - 1 && showHDividerLastRow
                  }
                />
              ) : (
                // Render invisible spacer to preserve gap
                <InvisibleDivider data-testid="invisible-divider" />
              ))}
          </React.Fragment>
        );
      })}
    </GridContainer>
  );
};

export default KpiGrid;
