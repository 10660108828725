import styled from "styled-components";
import { Container, Grid } from "@mui/material";
import { color } from "../../theme/Color";

export const MainContainer = styled(Container)`
  width: 100%;
  background: ${({ mode }) =>
    mode === "light"
      ? `${color._FBFCFE} !important`
      : `${color._1d1d1d} !important`};
  border: ${({ mode, isAppPerform }) =>
    mode === "light" && isAppPerform ? `1px solid ${color._D7E5F8}` : " "};
  border-radius: 8px;
  padding-bottom: 20px;
  padding-right: 20px !important;
  & .lineChartContainer {
    width: 100%;
    height: 170px;
  }
  & .latencyBarChart {
    width: 100%;
    height: 175px;
  }

  @media (max-width: 600px) {
    padding-left: 0px;
    padding-right: 0 !important;
  }
`;

export const InnerContainer = styled(Grid)`
  padding-right: 20px !important;
  background: #1d1d1d;
  & .verticalBarContainer {
    border-width: 1px;
    background: #666666;
  }
`;
