import React from 'react';
import { OverviewComponentType, ProductOverviewRegistry } from '../../types';
import Scorecard from 'containers/ApplicationPerformanceConfig/SummaryMetricsWrapper';
import IncidentMonitoringHOC from 'containers/ApplicationPerformanceRedesigned/IncidentMonitoringHOC';

const scorecards: OverviewComponentType[] = [() => <Scorecard />];

// Provide a default custom overview.
const customOverview: OverviewComponentType | null = () => (
  <IncidentMonitoringHOC />
);

const kazandirioRegistry: ProductOverviewRegistry = {
  scorecards,
  customOverview,
};

export default kazandirioRegistry;
