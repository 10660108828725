import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FilterContext } from 'components/FilterBar/FilterProvider';
import { constants } from 'constants/Constants';
import { useApplicationPerformance } from 'hooks/application-performance-hook';
import KpiDefinitionData from 'service/KpiDefinitionData';
import { useLayoutNavigation } from 'hooks/layout-navigation-hook';
import { getRealTimeOnboardingConfigProps } from 'utils/dynamicKPIs';
import IncidentMonitoringView from '../IncidentMonitoringView';

const IncidentMonitoringHOC = () => {
  const {
    selectedProgram,
    selectedProduct,
    selectedSector,
    selectedMarket,
    startUTCDate,
    endUTCDate,
    categories,
  } = useContext(FilterContext);

  const applicationNames =
    constants?.applicationPerformance?.assignedGroupNames;

  const [assignedGroups, setAssignedGroup] = useState(
    constants?.applicationPerformance?.assignedGroupNames
  );

  const [localFilters, setLocalFilters] = useState(null);
  const [application, setApplications] = useState('');
  const [appPerformanceProps, setAppPerformanceProps] = useState({});
  const [showIncidentMonitoringDetails, setShowIncidentMonitoringDetails] =
    useState(false);
  const {
    getIncidentMonitoringTableData,
    incidentMonitoringTableData,
    incidentMonitoringData,
    getIncidentMonitoringData,
    incidentMonitoringLoading,
    apdexScoreData,
    getApdexScoreData,
    apdexScoreLoading,
  } = useApplicationPerformance();
  const { getRealTimeOnboardingConfigData, realTimeOnboardingConfigData } =
    useLayoutNavigation();
  useEffect(() => {
    if (selectedProduct?.productId) {
      getRealTimeOnboardingConfigData({
        variables: { productId: selectedProduct?.productId },
      });
    }
  }, [selectedProduct?.productId, getRealTimeOnboardingConfigData]);
  useEffect(() => {
    const getVisibilityOfIncidentMonitoringView = () => {
      return (
        (appPerformanceProps as any)?.getIncidentMonitoringOpenAndClose &&
        (appPerformanceProps as any)?.getIncidentMonitoringPriorityLevelGraph &&
        (appPerformanceProps as any)?.getIncidentMonitoringOverview
      );
    };
    const visibility = getVisibilityOfIncidentMonitoringView();
    setShowIncidentMonitoringDetails(visibility);
  }, [appPerformanceProps]);
  const applicationMetricCriteria = useMemo(
    () => ({
      programId: selectedProgram === null ? 2 : selectedProgram?.programId,
      productId: selectedProduct === null ? 1 : selectedProduct?.productId,
      sectorId: selectedSector === null ? 1 : selectedSector?.sectorId,
      marketId: selectedMarket === null ? null : selectedMarket?.marketId,
      categories: categories,
      startDate: startUTCDate,
      endDate: endUTCDate,
    }),
    [
      selectedProgram,
      selectedProduct,
      selectedSector,
      selectedMarket,
      startUTCDate,
      endUTCDate,
      categories,
    ]
  );

  useEffect(() => {
    if (selectedProgram?.programId === constants.programs.SE) {
      const updatedApplicationMetrics = { ...applicationMetricCriteria };
      (updatedApplicationMetrics as any).applicationNames =
        selectedProduct?.productId === constants.products.PepsiConnect
          ? assignedGroups
          : [];

      getApdexScoreData({
        variables: {
          applicationMetricCriteria: updatedApplicationMetrics,
        },
      });
    }
  }, [
    selectedProgram,
    selectedProduct,
    getApdexScoreData,
    applicationMetricCriteria,
    assignedGroups,
  ]);

  useEffect(() => {
    if (
      selectedProgram?.programId === constants.programs.SE ||
      selectedProgram?.programId === constants.programs.DTC ||
      selectedProgram?.programId === constants.programs.DTV ||
      selectedProgram?.programId === constants.programs.IBP ||
      selectedProgram?.programId === constants.programs.GPG ||
      selectedProduct?.productId === constants?.products?.R360
    ) {
      const updatedApplicationMetrics = {
        ...applicationMetricCriteria,
        assignmentGroupId: localFilters?.selectedAssignmentGroup?.value,
        period: localFilters?.selectedFrequency?.value,
      };
      (updatedApplicationMetrics as any).applicationNames =
        selectedProduct?.productId === constants.products.PepsiConnect
          ? assignedGroups
          : [];

      getIncidentMonitoringTableData({
        variables: {
          applicationMetricCriteria: updatedApplicationMetrics,
        },
      });
      getIncidentMonitoringData({
        variables: {
          applicationMetricCriteria: updatedApplicationMetrics,
        },
      });
    }
  }, [
    selectedProgram,
    selectedProduct,
    getIncidentMonitoringTableData,
    getIncidentMonitoringData,
    applicationMetricCriteria,
    assignedGroups,
    localFilters,
  ]);
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    if (realTimeOnboardingConfigData) {
      const { appPerfProps } = getRealTimeOnboardingConfigProps(
        realTimeOnboardingConfigData
      );
      setAppPerformanceProps((prev) => ({ ...prev, ...appPerfProps }));
      setIsReady(true);
    }
  }, [realTimeOnboardingConfigData]);
  const processIncidentData = (data) => {
    if (data === undefined || data === null || data?.length === 0) {
      return data;
    }
    if (selectedProduct?.productId === constants.products.DTC) {
      return data?.map((incident) => {
        const priorityDetail = incident.priorityDetail.map((detail) => {
          if (detail.priority === 'REGULAR') {
            return { ...detail, priority: 'VERY LOW' };
          } else if (detail.priority === 'STANDARD') {
            return { ...detail, priority: 'LOW' };
          } else {
            return detail;
          }
        });

        return { ...incident, priorityDetail };
      });
    } else {
      return data;
    }
  };

  const determineApdexScoreVisibility = () => {
    if (
      selectedProgram?.programId === constants?.programs?.DTV ||
      selectedProgram?.programId === constants?.programs?.SE ||
      selectedProgram?.programId === constants?.programs?.DTC ||
      selectedProgram?.programId === constants?.programs?.IBP ||
      selectedProgram?.programId === constants?.programs?.GPG ||
      selectedProduct?.productId === constants?.products?.R360
    ) {
      return false;
    } else {
      return (appPerformanceProps as any)?.getApdexScore;
    }
  };
  return (
    <>
      {isReady && (
        <IncidentMonitoringView
          selectedProduct={selectedProduct}
          applicationNames={applicationNames}
          setAssignedGroup={(assignedGroups) =>
            setAssignedGroup(assignedGroups)
          }
          application={application}
          setApplications={setApplications}
          incidentMonitoringTableData={incidentMonitoringTableData}
          incidentTrendData={incidentMonitoringData?.incidentTrendData}
          incidentMonitoringSlaAnalysisData={
            incidentMonitoringData?.incidentMonitoringSlaAnalysisData
          }
          incidentMonitoringOverviewData={
            incidentMonitoringData?.incidentMonitoringOverviewData
          }
          incidentMonitoringOpenAndCloseData={
            incidentMonitoringData?.incidentMonitoringOpenAndCloseData
          }
          incidentMonitoringPriorityLevelGraphData={processIncidentData(
            incidentMonitoringData?.incidentMonitoringPriorityLevelGraphData
          )}
          apdexScoreData={apdexScoreData}
          slaAnalysisLoading={incidentMonitoringLoading}
          incidentTrendLoading={incidentMonitoringLoading}
          incidentMonitoringDetailsLoading={incidentMonitoringLoading}
          apdexScoreLoading={apdexScoreLoading}
          dataDefinition={
            KpiDefinitionData[selectedProduct?.productId]
              ?.applicationPerformanceRedesigned?.incidentMonitoring
          }
          appPerformanceProps={appPerformanceProps}
          showApdexScore={determineApdexScoreVisibility()}
          showIncidentMonitoringDetails={showIncidentMonitoringDetails}
          setLocalFilters={(filters) => setLocalFilters(filters)}
        />
      )}
    </>
  );
};

export default IncidentMonitoringHOC;
