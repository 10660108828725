// src/context/ThemeContextProvider.js
import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { getThemePreference, saveThemePreference } from '../graphql/queries';
import { useLazyQuery } from '@apollo/client';
import { getStyledTheme } from './styledTheme';

export const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState('light');
  const [fetchThemePreference, { data: themeData }] =
    useLazyQuery(getThemePreference);
  const [saveThemePreferences] = useLazyQuery(saveThemePreference);

  // Convert mode from API values to local theme and vice versa.
  const convertMode = useCallback((inputMode) => {
    const mapping = {
      L: 'light',
      D: 'dark',
      light: 'L',
      dark: 'D',
    };
    return mapping[inputMode];
  }, []);

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: { mode },
      }),
    [mode]
  );

  const styledTheme = useMemo(() => getStyledTheme(mode), [mode]);

  useEffect(() => {
    fetchThemePreference();
  }, [fetchThemePreference]);

  useEffect(() => {
    const apiMode = themeData?.getDashboardMode?.data?.mode;
    if (apiMode) {
      // Convert API mode to local mode (if conversion returns undefined, fallback to "light").
      setMode(convertMode(apiMode) || 'light');
    }
  }, [themeData, convertMode]);

  const toggleTheme = useCallback(() => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    // Save the theme preference with the converted value.
    saveThemePreferences({ variables: { mode: convertMode(newMode) } });
  }, [mode, saveThemePreferences, convertMode]);

  return (
    <ThemeContext.Provider
      value={{ mode, toggleTheme, setMode, colors: styledTheme.colors }}
    >
      <ThemeProvider theme={muiTheme}>
        <StyledThemeProvider theme={styledTheme}>
          {children}
        </StyledThemeProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
