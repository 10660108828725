import {
  formatValueWithMiddlewares,
  checkForNA,
  createKpiTileItem,
  removeTrailingZero,
  applySuffix,
  replaceNegativeSign,
} from '../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    // Destructure directly from the response data.
    const {
      valueOpportunityIdentified,
      valueOpportunityIdentifiedPeriodComparison,
    } = data.getArchletScoreCard;

    return createKpiTileItem(
      'Value Opportunity Identified',
      `$${formatValueWithMiddlewares(valueOpportunityIdentified, [checkForNA, removeTrailingZero, applySuffix])}`,
      {
        isPositiveValue: valueOpportunityIdentifiedPeriodComparison >= 0,
        text: `$${formatValueWithMiddlewares(
          valueOpportunityIdentifiedPeriodComparison,
          [checkForNA, replaceNegativeSign, removeTrailingZero, applySuffix]
        ).toString()}`,
        sideLabel: '',
        showArrow: valueOpportunityIdentifiedPeriodComparison !== 0,
      }
    );
  },
};
