import React, { useContext } from 'react';
import ReactEcharts from 'echarts-for-react';
import { color } from '../../theme/Color';
import { ThemeContext } from '../../context/ThemeContext';

export default function StackedMutiColumnBarChart({
  legend = {},
  tooltip = {},
  xAxis = {},
  yAxis = {},
  grid = {},
  seriesData = [],
  eChartHeight = 300,
}) {
  const { mode } = useContext(ThemeContext);

  const option = {
    legend: {
      icon: 'circle',
      textStyle: {
        color: mode === 'light' ? `${color._616161}` : `${color._efefef}`,
        fontFamily: 'Inter',
      },
      ...legend,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      ...tooltip,
    },
    xAxis: {
      show: true,
      axisLabel: {
        color: mode === 'light' ? `${color._616161}` : `${color._efefef} `,
      },
      axisTick: {
        alignWithLabel: true,
      },
      ...xAxis,
    },
    yAxis: {
      show: true,
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: color._616161,
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: mode === 'light' ? `${color._DFDFDF}` : `${color._272727} `,
        },
      },
      ...yAxis,
    },
    grid: {
      ...grid,
    },
    series: [...seriesData],
  };

  return (
    <div data-testid="stacked-multi-column-bar-chart">
      <ReactEcharts
        option={option}
        notMerge={true}
        style={{ height: eChartHeight, width: '100%' }}
      />
    </div>
  );
}
