import {
  formatValueWithMiddlewares,
  checkForNA,
  applySuffix,
  createKpiTileItem,
} from '../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    const { totalUser } = data.getUserAdoptionScorecard;

    return createKpiTileItem(
      'Total Users',
      formatValueWithMiddlewares(totalUser, [checkForNA, applySuffix]),
      null
    );
  },
};
