import React, { useContext } from 'react';
import { OverviewService } from '../../service/overviewService';

import { OverviewComponentType } from 'service/overviewService/types';
import { FilterContext } from 'components/FilterBar/FilterProvider';

const DefaultScorecards: React.FC = () => {
  const { selectedProduct, selectedProgram } = useContext(FilterContext);
  const filters = {
    product: selectedProduct?.productId,
    program: selectedProgram.programId,
  };
  const scoreCards: OverviewComponentType[] = OverviewService.resolveScoreCards(
    filters || undefined
  );

  if (!scoreCards || scoreCards.length === 0) {
    return null;
  }

  return (
    <>
      {scoreCards.map((ScoreCardComponent, index) => (
        <React.Fragment key={index}>
          <ScoreCardComponent />
        </React.Fragment>
      ))}
    </>
  );
};

export default DefaultScorecards;
