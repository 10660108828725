import React, { useContext, useEffect } from 'react';
import * as Styled from './style';
import DatePicker from 'react-datepicker';
import { color } from '../../theme/Color';
import { MdOutlineCalendarToday } from 'react-icons/md';
import { constants } from '../../constants/Constants';
import { ThemeContext } from '../../context/ThemeContext';
import { FilterContext } from './FilterProvider';

export function CustomDatePicker({
  setSelectedStartDate,
  setStartUTCDate,
  setSelectedEndDate,
  setEndUTCDate,
  getStartOfMonth,
  getEndOfMonth,
  getCurrentDayOfMonth,
  portalId,
  product,
  pickerType,
  minSelectableYear,
  maxSelectableYear,
  setSelectedYear,
  selectedYear,
  selectedDate,
  setSelectedDate,
  checked,
  goLiveMonth,
}) {
  const {
    selectedMarket,
    selectedCustomer,
    selectedProduct,
    selectedModel,
    selectedSector,
  } = useContext(FilterContext);

  useEffect(() => {
    // If YTD is Off, update selectedYear if it's less than the new minSelectableYear
    if (
      !checked &&
      !isNaN(minSelectableYear) &&
      !isNaN(goLiveMonth) &&
      selectedYear < minSelectableYear
    ) {
      setSelectedYear(minSelectableYear);
      setSelectedDate(new Date(minSelectableYear, goLiveMonth, 1));
    }

    if (!checked && selectedYear === minSelectableYear) {
      setSelectedYear(minSelectableYear);
      setSelectedDate(new Date(minSelectableYear, goLiveMonth, 1));
    }

    // If YTD is on, update the selected year to the minSelectableYear
    if (checked && selectedYear < minSelectableYear) {
      setSelectedYear(minSelectableYear);
      setSelectedDate(new Date(minSelectableYear, 11, 1));
    }
  }, [
    selectedYear,
    minSelectableYear,
    selectedMarket,
    selectedCustomer,
    selectedProduct,
    selectedModel,
    selectedSector,
    goLiveMonth,
  ]);

  const isCurrentDate = (date) => {
    const today = new Date();
    return (
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  const { mode } = useContext(ThemeContext);

  const getDateValue = (currentYear, year) => {
    if (currentYear === year) {
      return new Date();
    }
    // Update the min Selectable year in the date picker only if YTD is toggled off
    else if (year == minSelectableYear && !checked) {
      const currentMonth = new Date().getMonth();
      const goLocalLiveMonth = isNaN(goLiveMonth) ? currentMonth : goLiveMonth;
      return new Date(year, goLocalLiveMonth, 1);
    } else {
      return new Date(year, 11, 1);
    }
  };

  const handleDateChangeLogic = (date) => {
    const year = date.getFullYear();
    const currentYear = new Date().getFullYear();
    if (pickerType === 'year') {
      setSelectedYear(year);
      const dateValue = getDateValue(currentYear, year);
      setSelectedDate(dateValue);
      setSelectedStartDate(dateValue);
      const startUTCDate = checked
        ? getStartOfMonth(date)?.toISOString()
        : getStartOfMonth(dateValue)?.toISOString();
      setStartUTCDate(startUTCDate); // Assigning the date value selected from year picker
      if (
        isCurrentDate(date) &&
        product?.productId === constants?.products?.ShipmentVisibilty
      ) {
        const currentDate = getCurrentDayOfMonth();
        setSelectedEndDate(currentDate);
        setEndUTCDate(currentDate?.toISOString());
      } else {
        const endOfMonth = getEndOfMonth(date);
        setSelectedEndDate(endOfMonth);
        setEndUTCDate(endOfMonth?.toISOString());
      }
      const endOfMonth = getEndOfMonth(dateValue);
      setSelectedEndDate(endOfMonth);
      setEndUTCDate(endOfMonth?.toISOString());
    } else if (pickerType === 'month') {
      setSelectedDate(date);
      setSelectedStartDate(date);
      setStartUTCDate(getStartOfMonth(date)?.toISOString());
      if (
        isCurrentDate(date) &&
        product?.productId === constants?.products?.ShipmentVisibilty
      ) {
        const currentDate = getCurrentDayOfMonth();
        setSelectedEndDate(currentDate);
        setEndUTCDate(currentDate?.toISOString());
      } else {
        const endOfMonth = getEndOfMonth(date);
        setSelectedEndDate(endOfMonth);
        setEndUTCDate(endOfMonth?.toISOString());
      }
    }
  };

  const handleSelectedDate = () => {
    return pickerType === 'year' ? new Date(selectedYear, 0, 1) : selectedDate;
  };

  const getMinDateForDatePicker = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const minLocalSelectableYear = isNaN(minSelectableYear)
      ? currentYear
      : minSelectableYear;
    const goLocalLiveMonth = isNaN(goLiveMonth) ? currentMonth : goLiveMonth;

    if (pickerType === 'year') {
      return new Date(minLocalSelectableYear, 0, 1);
    } else {
      return new Date(minLocalSelectableYear, goLocalLiveMonth, 1);
    }
  };

  return (
    <Styled.DatePickerContainer mode={mode} style={{ position: 'relative' }}>
      <Styled.GlobalStyle
        mode={mode}
        disabled={pickerType === 'month' && checked}
        pickerType={pickerType}
      />
      <label>
        <DatePicker
          id="date-filter"
          data-testid="date-filter"
          portalId={portalId}
          className={
            pickerType === 'month' && checked
              ? 'disabledFilter filter'
              : 'filter'
          }
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onChange={(date) => {
            // Process the date selection
            handleDateChangeLogic(date);
            // Close the calendar if it’s open
            setTimeout(() => {
              const filterElements = document.getElementsByClassName('filter');
              Array.from(filterElements).forEach((element) => element.blur());
            }, 100);
          }}
          disabledKeyboardNavigation
          placeholderText={pickerType === 'year' ? 'Year' : 'Month'}
          enableTabLoop={false}
          showMonthYearPicker={pickerType === 'month'}
          showYearPicker={pickerType === 'year'}
          selected={handleSelectedDate()}
          dateFormat={pickerType === 'year' ? 'yyyy' : 'MMMM'}
          minDate={getMinDateForDatePicker()}
          maxDate={
            pickerType === 'year'
              ? new Date(maxSelectableYear, 11, 31)
              : new Date().getFullYear() === selectedYear
                ? new Date()
                : new Date(selectedYear, 11, 31)
          }
          showDisabledMonthNavigation={false}
          readOnly={pickerType === 'month' && checked}
          renderCustomHeader={
            pickerType === 'year'
              ? () => <div>2023-2028</div>
              : () => <div>{selectedYear}</div>
          }
        />
        <Styled.IconContainer>
          <MdOutlineCalendarToday
            data-accessible={true}
            style={{
              color:
                pickerType === 'month' && checked ? 'darkgray' : color._616161,
              cursor: 'pointer',
            }}
            data-testid="datepicker_calendar"
          />
        </Styled.IconContainer>
      </label>
    </Styled.DatePickerContainer>
  );
}
