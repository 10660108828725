import React, { useState, useRef, useEffect, MouseEvent } from 'react';
import { FiDownload } from 'react-icons/fi';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {
  subscribeToCompletion,
  subscribeToRequestStatus,
} from 'utils/apolloUtils';
import {
  DownloadButton,
  Tooltip,
  MenuContainer,
  MenuItem,
  Wrapper,
} from './styles';

interface Option {
  id: number;
  label: string;
  action: (event?: React.MouseEvent) => void;
}

const DownloadMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [tooltipText, setTooltipText] = useState(
    'Enabled on complete page load'
  );
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // Subscribe to ongoing status updates
    const statusUnsubscribe = subscribeToRequestStatus(
      ({ activeRequestCount }) => {
        if (activeRequestCount === 0) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      }
    );

    // Subscribe to completion events
    const completionUnsubscribe = subscribeToCompletion(() => {
      setIsDisabled(false);
    });

    return () => {
      statusUnsubscribe();
      completionUnsubscribe();
    };
  }, []);

  const modifyPdfElements = (clonedDoc: any) => {
    const titleElement = clonedDoc.getElementById('program-title');
    const productTabTitle = clonedDoc.getElementById('pdf-product-title');
    const filterBar = clonedDoc.getElementById('filters');
    const yearFilter = clonedDoc.getElementById('yearValue');
    const pepsiLogo = clonedDoc.querySelector('.pepsicoIcon');
    if (pepsiLogo) {
      pepsiLogo.style.margin = '0';
    }
    if (titleElement && productTabTitle) {
      titleElement.style.display = 'none';
      productTabTitle.style.display = 'block';
    }
    if (filterBar && yearFilter) {
      filterBar.style.display = 'none';
      yearFilter.style.display = 'block';
    }

    return clonedDoc;
  };

  const modifyImageElements = (clonedDoc: any) => {
    const pepsiLogo = clonedDoc.querySelector('.pepsicoIcon');
    if (pepsiLogo) {
      pepsiLogo.style.margin = '0';
    }
    return clonedDoc;
  };

  const handleExportPDF = async () => {
    setIsOpen(false);
    setIsDisabled(true);
    setTooltipText('Please wait while PDF is being generated');
    const previewContent = document.getElementById('root');
    if (!previewContent) {
      return;
    }
    // Adjust html2canvas to capture full scrollable content
    try {
      const nestedContent = previewContent.querySelector(
        'div[role="tabpanel"]:not([hidden])'
      );
      if (!nestedContent) {
        return;
      }
      const childDiv = nestedContent.querySelector(':scope > div');

      // Remove tabPanel class from both elements
      nestedContent.classList.remove('tabPanel');
      childDiv.classList.remove('tabPanel');

      const totalHeight = previewContent.scrollHeight;
      const viewportHeight = previewContent.clientHeight;
      // Create canvas with adjusted width
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const mainContent =
        document.querySelector('#dashboard') || previewContent;

      // Calculate actual content width excluding the ignored sidebar
      const sideNav = document.getElementById('sidebar');
      const sideNavWidth = sideNav ? sideNav.offsetWidth : 0;
      const actualWidth = previewContent.scrollWidth - sideNavWidth;

      canvas.width = previewContent.scrollWidth; // actualWidth;
      canvas.height = totalHeight;

      const chunkSize = 8000; // pixels
      let capturedHeight = 0;
      const mainHeight = mainContent.scrollHeight;
      while (capturedHeight < totalHeight) {
        const currentChunkHeight = Math.min(
          chunkSize,
          totalHeight - capturedHeight
        );
        previewContent.scrollTop = Math.min(capturedHeight, mainHeight);
        await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for the scroll to complete
        const partCanvas = await html2canvas(previewContent, {
          scrollX: 0,
          scrollY: capturedHeight,
          useCORS: true,
          scale: 2,
          height: currentChunkHeight,
          width: previewContent.scrollWidth, // actualWidth, // previewContent.scrollWidth,
          x: previewContent.offsetLeft, // Offset by sidebar width
          windowWidth: previewContent.scrollWidth, // Force width without sidebar
          onclone: modifyPdfElements,
          logging: false, // Enable logging for debugging
          removeContainer: true, // Remove temporary container after capture
          backgroundColor: null, // Preserve transparency
        });

        context.drawImage(
          partCanvas,
          0,
          capturedHeight,
          actualWidth, // previewContent.scrollWidth,
          currentChunkHeight
        );
        capturedHeight += currentChunkHeight;

        // Break the loop if we've reached the end
        if (
          previewContent.scrollTop + viewportHeight >=
          previewContent.scrollHeight
        ) {
          break;
        }
      }

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true,
      });

      // Get PDF page dimensions (A4: 210 x 297 mm, but this is more flexible)
      const pdfPageWidth = pdf.internal.pageSize.getWidth();
      const pdfPageHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio of your captured canvas
      const canvasAspectRatio = canvas.width / canvas.height;

      // Scale the image to fit the PDF width while keeping its aspect ratio
      let imgWidth = pdfPageWidth;
      let imgHeight = pdfPageWidth / canvasAspectRatio;

      // Optional: if the scaled image is taller than the PDF page, scale it further down.
      if (imgHeight > pdfPageHeight) {
        imgHeight = pdfPageHeight;
        imgWidth = pdfPageHeight * canvasAspectRatio;
      }

      // Optional: Center the image on the page (or adjust as needed)
      const marginX = (pdfPageWidth - imgWidth) / 2;
      const marginY = (pdfPageHeight - imgHeight) / 2;

      // Add the image to the PDF without cropping or distorting
      pdf.addImage(
        imgData,
        'PNG',
        marginX,
        marginY,
        imgWidth,
        imgHeight,
        undefined,
        'FAST'
      );

      pdf.save('screenshot.pdf');

      nestedContent.classList.add('tabPanel');
      childDiv.classList.add('tabPanel');
      setIsDisabled(false);
    } catch (error) {
      alert('Failed to generate pdf. Please try again.');
      setIsDisabled(false);
    }
  };

  const handleImageCopy = async (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen(false);
    setIsDisabled(true);
    setTooltipText('Please wait while image is being processed');
    const previewContent = document.getElementById('root');
    if (!previewContent) {
      return;
    }
    // Adjust html2canvas to capture full scrollable content
    try {
      const nestedContent = previewContent.querySelector(
        'div[role="tabpanel"]:not([hidden])'
      );
      if (!nestedContent) {
        return;
      }

      const childDiv = nestedContent.querySelector(':scope > div');

      // Remove tabPanel class from both elements
      nestedContent.classList.remove('tabPanel');
      childDiv.classList.remove('tabPanel');
      const programTitle = document.getElementById('program-title');
      const tabContainer = document.getElementById('tab-container');
      if (tabContainer) {
        tabContainer.removeAttribute('data-html2canvas-ignore');
      }
      const spanInsideStack = programTitle.querySelector(
        'span[data-html2canvas-ignore]'
      );
      if (spanInsideStack) {
        // Remove the attribute from the span
        spanInsideStack.removeAttribute('data-html2canvas-ignore');
      }

      const totalHeight = previewContent.scrollHeight;
      const viewportHeight = previewContent.clientHeight;
      // Create canvas with adjusted width
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const mainContent =
        document.querySelector('#dashboard') || previewContent;

      canvas.width = previewContent.scrollWidth; // actualWidth;
      canvas.height = totalHeight;

      const chunkSize = 7000; // pixels
      let capturedHeight = 0;
      const mainHeight = mainContent.scrollHeight;
      while (capturedHeight < totalHeight) {
        const currentChunkHeight = Math.min(
          chunkSize,
          totalHeight - capturedHeight
        );
        previewContent.scrollTop = Math.min(capturedHeight, mainHeight);
        await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for the scroll to complete
        const partCanvas = await html2canvas(previewContent, {
          scrollX: 0,
          scrollY: capturedHeight,
          useCORS: true,
          scale: 2,
          height: currentChunkHeight,
          width: previewContent.scrollWidth, // actualWidth, // previewContent.scrollWidth,
          x: previewContent.offsetLeft, // Offset by sidebar width
          windowWidth: previewContent.scrollWidth, // Force width without sidebar
          onclone: modifyImageElements,
          logging: false, // Enable logging for debugging
          removeContainer: true, // Remove temporary container after capture
          backgroundColor: null, // Preserve transparency
        });

        context.drawImage(
          partCanvas,
          0,
          capturedHeight,
          previewContent.scrollWidth, // previewContent.scrollWidth,
          currentChunkHeight
        );
        capturedHeight += currentChunkHeight;

        // Break the loop if we've reached the end
        if (
          previewContent.scrollTop + viewportHeight >=
          previewContent.scrollHeight
        ) {
          break;
        }
      }

      // const handle = await window.showSaveFilePicker({
      //   suggestedName: `screenshot_${new Date().getTime()}.png`,
      //   types: [
      //     {
      //       description: 'PNG Image',
      //       accept: {
      //         'image/png': ['.png'],
      //       },
      //     },
      //   ],
      // });

      // Convert to blob after we have the file handle
      const blob = await new Promise<Blob>((resolve) => {
        canvas.toBlob(
          (b) => {
            if (b) {
              resolve(b);
            }
          },
          'image/png',
          1.0
        );
      });
      // Create clipboard items with supported MIME types
      const clipboardItems = [
        new window.ClipboardItem({
          'image/png': blob,
        }),
      ];

      await navigator.clipboard.write(clipboardItems);

      // // Write the file
      // const writable = await handle.createWritable();
      // await writable.write(blob);
      // await writable.close();

      alert('Image copied successfully!');
      nestedContent.classList.add('tabPanel');
      childDiv.classList.add('tabPanel');
      if (tabContainer) {
        tabContainer.setAttribute('data-html2canvas-ignore', '');
      }
      setIsDisabled(false);
    } catch (error) {
      setIsDisabled(false);
      alert('Failed to copy image. Please try again.');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener(
      'mousedown',
      handleClickOutside as unknown as EventListener
    );
    return () =>
      document.removeEventListener(
        'mousedown',
        handleClickOutside as unknown as EventListener
      );
  }, []);

  const options: Option[] = [
    {
      id: 1,
      label: 'Download PDF',
      action: () => handleExportPDF(),
    },
    {
      id: 2,
      label: 'Copy Image (PNG)',
      action: (event) => handleImageCopy(event as React.MouseEvent),
    },
  ];

  return (
    <Wrapper>
      <DownloadButton
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        disabled={isDisabled}
      >
        <FiDownload size={20} />
        {isDisabled && <Tooltip>{tooltipText}</Tooltip>}
      </DownloadButton>

      {isOpen && (
        <MenuContainer ref={menuRef}>
          {options.map((option) => (
            <MenuItem
              key={option.id}
              onClick={(event: React.MouseEvent) => option.action(event)}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuContainer>
      )}
    </Wrapper>
  );
};

export default DownloadMenu;
