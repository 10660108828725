import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Typography,
  Box,
  Stack,
  Button,
  Modal,
  styled,
  Grid,
} from '@mui/material';
import * as Styled from './style';
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdCheckCircle,
  MdSchedule,
  MdCancel,
  MdHourglassEmpty,
} from 'react-icons/md';
import Select from 'react-select';
import { constants, screenSizes } from '../../constants/Constants';
import { helperFunctions } from '../../utils/helperFunctions';
import { ThemeContext } from '../../context/ThemeContext';
import { useLayoutNavigation } from '../../hooks/layout-navigation-hook';
import { color } from '../../theme/Color';
import { FilterContext } from '../../components/FilterBar/FilterProvider';
import useCustomMediaQuery from 'utils/useCustomMediaQuery';

const { getComparator, stableSort, filterByOptions } = helperFunctions();

const StyledTableCell = styled(TableCell)(({ mode }) => ({
  fontWeight: '700',
  fontSize: '10px',
  fontFamily: 'Inter',
  lineHeight: '14px',
  letterSpacing: '0px',
  textAlign: 'left',
  color:
    mode === 'light'
      ? `${color._616161} !important`
      : `${color._D0D0D0} !important`,
  background:
    mode === 'light'
      ? `${color._F3F7FD} !important`
      : `${color._1d1d1d} !important`,
  borderBottom: '1px solid #3A3A3A',
  textTransform: 'uppercase',
  '& .MuiTableSortLabel-root': {
    color:
      mode === 'light'
        ? `${color._616161} !important`
        : `${color._D0D0D0} !important`,
  },
}));

const StyledTableRow = styled(TableCell)(({ mode }) => ({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '17px',
  letterSpacing: '0px',
  textAlign: 'left',
  color:
    mode === 'light'
      ? `${color._616161} !important`
      : `${color._D0D0D0} !important`,
  borderBottom: '1px solid #3A3A3A',
  '& .MuiTableSortLabel-root': {
    color:
      mode === 'light'
        ? `${color._616161} !important`
        : `${color._D0D0D0} !important`,
  },
}));

const StyledMdArrowDropUp = styled(MdArrowDropUp)(() => ({
  marginBottom: '-18px',
  padding: '0px',
}));

const StyledMdArrowDropDown = styled(MdArrowDropDown)(() => ({
  padding: '0px',
}));

const headerCells = [
  {
    id: 'incidentNumber',
    numeric: false,
    disablePadding: true,
    label: 'Incident Number',
  },
  {
    id: 'category',
    numeric: true,
    disablePadding: false,
    label: 'Category',
  },
  {
    id: 'priority',
    numeric: true,
    disablePadding: false,
    label: 'Priority Level',
  },
  {
    id: 'openDateTime',
    numeric: true,
    disablePadding: false,
    label: 'Opened ON',
  },
  {
    id: 'assigned',
    numeric: true,
    disablePadding: false,
    label: 'Assignment Group',
  },
  {
    id: 'slaAnalysisType',
    numeric: true,
    disablePadding: false,
    label: 'SLA Analysis',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Current Status',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, onSetOrder, mode, selectedProduct } =
    props;

  const createSortHandler = (property, order) => (event) => {
    onSetOrder(order);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headerCells?.map((headCell) => {
          if (
            headCell.id === 'slaAnalysisType' &&
            !(
              selectedProduct?.productId === constants.products.DTC ||
              selectedProduct?.productId ===
                constants.products.PepsiTastyRewards
            )
          ) {
            return null;
          }
          return (
            <StyledTableCell
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={order}
              sx={{ paddingLeft: 2 }}
              mode={mode}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                sx={
                  headCell?.width
                    ? {
                        width: headCell?.width,
                      }
                    : {}
                }
                IconComponent={() => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <StyledMdArrowDropUp
                      fontSize="25px"
                      onClick={createSortHandler(headCell.id, 'asc')}
                    />
                    <StyledMdArrowDropDown
                      fontSize="25px"
                      onClick={createSortHandler(headCell.id, 'desc')}
                    />
                  </Box>
                )}
              >
                {headCell.label}
              </TableSortLabel>
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const TableView = ({ IncidentMonitoringTableData }) => {
  const [open, setOpen] = useState(false);
  const { mode } = React.useContext(ThemeContext);
  const { selectCustomStyles } = useLayoutNavigation();
  const {
    selectedProduct,
    // selectedSector,
    // selectedMarket,
    // startUTCDate,
    // endUTCDate,
    // categories,
  } = useContext(FilterContext);
  const ipadMini = useCustomMediaQuery(screenSizes.ipadMini);
  const ipadPro = useCustomMediaQuery(screenSizes.ipadPro);
  const ipadAir = useCustomMediaQuery(screenSizes.ipadAir);
  const categoryFilter = [
    'All',
    ...new Set(IncidentMonitoringTableData?.map((item) => item.category) ?? []),
  ];

  const categoryOptions = categoryFilter?.map((item) => {
    return { label: item, value: item };
  });

  const assignmentGroupFilter = [
    'All',
    ...new Set(IncidentMonitoringTableData?.map((item) => item.assigned)),
  ];

  const assignmentGroupOptions = assignmentGroupFilter?.map((item) => {
    return { label: item, value: item };
  });

  const priorityFilter = [
    'All',
    ...new Set(IncidentMonitoringTableData?.map((item) => item.priority)),
  ];
  const slaFilter = [
    'All',
    ...new Set(
      IncidentMonitoringTableData?.map((item) => item.slaAnalysisType)
    ),
  ];

  const priorityOptions = priorityFilter.map((item) => {
    return { label: item, value: item };
  });

  const slaOptions = slaFilter.map((item) => {
    return { label: item, value: item };
  });

  const statusFilter = [
    'All',
    ...new Set(IncidentMonitoringTableData?.map((item) => item.status)),
  ];

  const statusOptions = statusFilter.map((item) => {
    return { label: item, value: item };
  });

  const basicOption = { value: 'All', label: 'All' };

  const [selectedCategory, setSelectedCategory] = useState(basicOption);
  const [selectedAssignmentGroup, setSelectedAssignmentGroup] =
    useState(basicOption);
  const [selectedPriority, setSelectedPriority] = useState(basicOption);
  const [selectedStatus, setSelectedStatus] = useState(basicOption);
  const [selectedSLA, setselectedSLA] = useState(basicOption);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('incidentNumber');

  const [filteredData, setFilteredData] = useState(IncidentMonitoringTableData);

  const incidentFilters = [
    { type: 'category', filterOption: selectedCategory },
    { type: 'assigned', filterOption: selectedAssignmentGroup },
    { type: 'priority', filterOption: selectedPriority },
    { type: 'status', filterOption: selectedStatus },
    { type: 'slaAnalysisType', filterOption: selectedSLA },
  ];

  const filterData = useCallback(() => {
    const data = filterByOptions(IncidentMonitoringTableData, incidentFilters);
    setFilteredData(data);
  }, [
    selectedCategory,
    selectedAssignmentGroup,
    selectedPriority,
    selectedStatus,
    IncidentMonitoringTableData,
    selectedSLA,
  ]);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const handleAssignmentGroupChange = (selectedOption) => {
    setSelectedAssignmentGroup(selectedOption);
  };

  const handlePriorityChange = (selectedOption) => {
    setSelectedPriority(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };
  const handleSLAChange = (selectedOption) => {
    setselectedSLA(selectedOption);
  };

  useEffect(() => {
    filterData();
  }, [
    selectedCategory,
    selectedAssignmentGroup,
    selectedPriority,
    selectedStatus,
    selectedSLA,
    filterData,
  ]);

  const formattedData = [];
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (IncidentMonitoringTableData?.length > 0) {
    IncidentMonitoringTableData?.forEach((data) => {
      const {
        incidentNumber,
        category,
        priority,
        openDateTime,
        assigned,
        status,
      } = data;

      formattedData.push({
        incidentNumber,
        category,
        priority,
        openDateTime,
        assigned,
        status,
      });
    });
  }

  const visibleRows = useMemo(
    () => stableSort(filteredData, getComparator(order, orderBy)),
    [order, orderBy, filteredData]
  );

  return (
    <>
      <Styled.ButtonContainer
        variant="contained"
        onClick={handleOpen}
        sx={{
          color: '#3387DF',
          borderRadius: '24px',
          border: ' 1px solid #3387DF',
          background: 'transparent',
          textTransform: 'none',
          height: '32px',
        }}
      >
        Table View
      </Styled.ButtonContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Styled.MainContainer mode={mode}>
          <Stack direction={'column'} marginBottom={'10px'}>
            <Stack className="headerContainer">
              <Typography id="modal-title" variant="h6" component="h2">
                Incident Monitoring
              </Typography>
              <Button
                variant="contained"
                onClick={handleClose}
                className="backButton"
              >
                Back
              </Button>
            </Stack>
            <Grid
              container
              justifyContent="flex-end"
              sx={{
                zIndex: 999,
                flexWrap: ipadMini || ipadPro || ipadAir ? 'nowrap' : 'wrap',
              }}
            >
              <Grid
                item
                container
                xs={3}
                md={2}
                lg={1.8}
                xl={1.2}
                className="incidentFilterContainer"
              >
                <Grid xs={12} className="incidentFilterText">
                  {constants?.applicationPerformanceRedesigned?.category}
                </Grid>
                <Grid xs={12}>
                  <Select
                    options={categoryOptions}
                    isSearchable={false}
                    placeholder={'All'}
                    value={selectedCategory}
                    styles={selectCustomStyles}
                    onChange={handleCategoryChange}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={3}
                md={2}
                lg={1.8}
                xl={1.2}
                className="incidentFilterContainer"
              >
                <Grid xs={12} className="incidentFilterText">
                  {constants?.applicationPerformanceRedesigned?.assignmentGroup}
                </Grid>
                <Grid xs={12}>
                  <Select
                    options={assignmentGroupOptions}
                    isSearchable={false}
                    placeholder={'All'}
                    value={selectedAssignmentGroup}
                    styles={selectCustomStyles}
                    onChange={handleAssignmentGroupChange}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={3}
                md={2}
                lg={1.8}
                xl={1.2}
                className="incidentFilterContainer"
              >
                <Grid xs={12} className="incidentFilterText">
                  {constants?.applicationPerformanceRedesigned?.selectPriority}
                </Grid>
                <Grid xs={12}>
                  <Select
                    options={priorityOptions}
                    isSearchable={false}
                    placeholder={'All'}
                    value={selectedPriority}
                    styles={selectCustomStyles}
                    onChange={handlePriorityChange}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={3}
                md={2}
                lg={1.8}
                xl={1.2}
                className="incidentFilterContainer"
              >
                <Grid item xs={12} className="incidentFilterText">
                  {constants?.applicationPerformanceRedesigned?.selectStatus}
                </Grid>
                <Grid item xs={12}>
                  <Select
                    options={statusOptions}
                    isSearchable={false}
                    placeholder={'All'}
                    value={selectedStatus}
                    styles={selectCustomStyles}
                    onChange={handleStatusChange}
                  />
                </Grid>
              </Grid>
              {(selectedProduct?.productId === constants.products.DTC ||
                selectedProduct?.productId ===
                  constants.products.PepsiTastyRewards) && (
                <Grid
                  item
                  container
                  xs={3}
                  md={2}
                  lg={1.8}
                  xl={1.2}
                  className="incidentFilterContainer"
                >
                  <Grid item xs={12} className="incidentFilterText">
                    {constants?.applicationPerformanceRedesigned?.slaAnalysis}
                  </Grid>
                  <Grid item xs={12} data-testid="sla-analysis-filter">
                    <Select
                      options={slaOptions}
                      isSearchable={false}
                      placeholder={'All'}
                      value={selectedSLA}
                      styles={selectCustomStyles}
                      onChange={handleSLAChange}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Stack>

          <TableContainer
            sx={{
              maxHeight: '75vh',
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
            }}
          >
            <Table stickyHeader={true}>
              <EnhancedTableHead
                order={order}
                mode={mode}
                onSetOrder={setOrder}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                selectedProduct={selectedProduct}
              />

              <TableBody>
                {visibleRows?.map((row, index) => {
                  const incidentLink =
                    constants.applicationPerformance.serviceNowURL +
                    `${row.incidentNumber}`;
                  return (
                    <TableRow key={index}>
                      <StyledTableRow sx={{ color: '#3387DF !important ' }}>
                        <a
                          href={incidentLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="custom-link"
                        >
                          {row.incidentNumber}
                        </a>
                      </StyledTableRow>
                      <StyledTableRow mode={mode}>
                        {row.category}
                      </StyledTableRow>
                      <StyledTableRow>
                        <Box
                          sx={{
                            display: '-webkit-inline-box',
                            justifyContent: 'center',
                            background: '#BFE6F6',
                            padding: '7px',
                            color: '#000000',
                            borderRadius: '4px',
                          }}
                        >
                          {row.priority}
                        </Box>
                      </StyledTableRow>
                      <StyledTableRow mode={mode}>
                        {row.openDateTime}
                      </StyledTableRow>
                      <StyledTableRow mode={mode}>
                        {row.assigned}
                      </StyledTableRow>
                      {(selectedProduct?.productId === constants.products.DTC ||
                        selectedProduct?.productId ===
                          constants.products.PepsiTastyRewards) && (
                        <StyledTableRow mode={mode}>
                          {row.slaAnalysisType}
                        </StyledTableRow>
                      )}
                      <StyledTableRow mode={mode}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '0.5rem',
                          }}
                        >
                          {row.status === 'CLOSED' && (
                            <MdCheckCircle color="#339B74" size={16} />
                          )}
                          {row.status === 'RESOLVED' && (
                            <MdCheckCircle color="green" size={16} />
                          )}
                          {row.status === 'PENDING' && (
                            <MdSchedule color="orange" size={16} />
                          )}
                          {row.status === 'CANCELED' && (
                            <MdCancel color="red" size={16} />
                          )}
                          {row.status === 'IN PROGRESS' && (
                            <MdHourglassEmpty color="blue" size={16} />
                          )}
                          {row.status}
                        </Box>
                      </StyledTableRow>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Styled.MainContainer>
      </Modal>
    </>
  );
};

export default TableView;
