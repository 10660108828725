import styled from "styled-components";
import { color } from "../../theme/Color";

interface MainContainerProps {
  customProp?: string;
  titleTextStyle?: boolean;
  mode:any;
}

export const ApdexScoreContainer = styled.div<MainContainerProps>`
  & .apdexScoreHeader {
    font-family: "Inter";
    font-weight: 700;
    font-size: ${(props: MainContainerProps) => (props.titleTextStyle ? "18px" : "16px")};
    margin: ${(props: MainContainerProps) => (props.titleTextStyle ? "15px" : "")};
    color:${({ mode })=>mode === 'light' ? `${color._616161}` : `${color._efefef}`}; 
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;
