import styled from "styled-components";
import { Container, Grid } from "@mui/material";
import { color } from "theme/Color";

export const MainContainer = styled(Container)`
  width: 100%;
  background: ${({ mode }) =>
    mode === "light"
      ? `${color._FBFCFE} !important`
      : `${color._1d1d1d} !important`};
  border: ${({ mode, isAppPerform }) =>
    mode === "light" && isAppPerform ? `1px solid ${color._D7E5F8}` : " "};
  padding: 20px;
  border-radius: 8px;
  @media (max-width: 600px) {
    padding-bottom: 0px;
  }

  & .css-ahj2mt-MuiTypography-root {
    font-family: Inter;
  }

  & .middleContainer {
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161}` : `${color._ffffff}`};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margintop: 18.5px;
  }

  & .firstNumContainer {
    font-weight: 700;
  }

  & .secondNumContainer {
    font-weight: 700;
    flex-direction: row;
    text-align: right;
  }

  & .horizontalBarContainer {
    margin-top: 7px;
  }
`;

export const TopContainer = styled(Grid)`
  align-items: "center";
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const BottomContainer = styled(Grid)`
  justify-content: space-between;
  align-items: "center";
  margin-top: -20px;
  width: 100%;
`;
