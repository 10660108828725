import { Grid } from "@mui/material";
import styled from "styled-components";
import { color } from "../../theme/Color";

export const MainContainer = styled.div``;

export const FlexContainer = styled(Grid)`
  color: ${color._efefef};

  & .header {
    font-family: "Inter";
    color: ${({ mode }) => (mode === "light" ? color._616161 : color._efefef)};
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    margin-top: 20px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  & .placeholder {
    width: 100%;
    margin-top: 20px;
  }

  & .card-panel {
    background: ${({ mode }) =>
      mode === "light"
        ? `${color._FBFCFE} !important`
        : `${color._1d1d1d} !important`};
    border: ${({ mode }) =>
      mode === "light" ? `1px solid ${color._D7E5F8}` : "none"};
    border-radius: 8px;
    padding: 24px;
  }

  & .posRelative {
    position: relative;
    margin-top: 20px;
    justifycontent: space-between;
  }
  & .infoIcon {
    position: absolute;
    top: 10px;
    right: 2px;
  }

  & .minHeight {
    min-height: 332px;
  }
  & .minHeight365 {
    min-height: 365px !important;
  }

  & .loaderHeight {
    min-height: 280px;
  }
`;
export const ToggleButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ApdexScoreContainer = styled.div`
  & .apdexScoreHeader {
    font-family: "Inter";
    font-weight: 700;
    font-size: "16px";
    width: 100%;
  }
`;
export const ScoreCardMetricContainer = styled(Grid)`
  &.circularBar {
    width: 110px;
    height: 100px;
  }
  &.largetext: {
  }
  &.smalltext: {
  }
`;

export const AssignmentGroupContainer = styled.div`
  & .filterContainerText {
    color: ${({ mode }) =>
      mode === "light" ? `${color._616161}` : `${color._d0d0d0}`};
    font-size: 12px;
    margin-bottom: 8px;
  }

  & .filterContainer {
  padding-right:10px;
  font-size:12px;
}
`;

