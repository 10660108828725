import styled from 'styled-components';

export const GlobalOverviewStyles = {
  Container: styled.div`
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    gap: 24px;
  `,
};
