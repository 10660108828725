import { gql } from "@apollo/client";

const getRetailOpScoreCardMetrics = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRetailOPScoreCard(psdCriteria: $psdCriteria) {
      data {
        phaseUsageRate
        callVolume
        totalNoOfVisitsKAM
        totalNoOfVisitsNAM
        noOfDocumentUploaded
        conversionGrowth
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getActiveAndTotalUsersChart = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getActiveAndTotalUsersChart(psdCriteria: $psdCriteria) {
      data {
        activeUser
        totalUser
        dateTime
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAdoptionRateTrend = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getAdoptionRateTrend(psdCriteria: $psdCriteria) {
      data {
        dateTime
        adoptionRate
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAdoptionLoginTrend = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getAdoptionLoginTrend(psdCriteria: $psdCriteria) {
      data {
        dateTime
        noOflogin
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAdoptionFrequencyTrend = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getAdoptionFrequencyTrend(psdCriteria: $psdCriteria) {
      data {
        dateTime
        adoptionFrequency
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getAdoptionScoreCard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getAdoptionScoreCard(psdCriteria: $psdCriteria) {
      data {
        totalUsers
        activeUsers
        endUserAccuracy
        adoptionRate
        avgNoOfLogins
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getUBOverallActivity = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getUBOverallActivity(psdCriteria: $psdCriteria) {
      data {
        pageActivityInSec
        avgNoOfPages
      }
      errors
      responseCode
      responseMessage
    }
    getMostVisitedPage(psdCriteria: $psdCriteria) {
      data {
        pageName
        pageCount
        visitedPercentage
      }
      errors
      responseCode
      responseMessage
    }
    getMostPopularPage(psdCriteria: $psdCriteria) {
      data {
        pageName
        pageCount
        visitedPercentage
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRetailOPEquipmentCompliance = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRetailOPEquipmentCompliance(
      psdCriteria: $psdCriteria
    ) {
      data {
        carouselEngagement
        conversionGrowth {
          dateTime
          value
        }
        conversionRate {
          dateTime
          value
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRetailOPJBPScoreCard = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRetailOPJBPScoreCard(psdCriteria: $psdCriteria) {
      data {
        contentEntryRate
        noOfInnovationItemsEntered
        noOfMeetingMinutesUploaded
        noOfDocumentsUploaded
        actionItemsConversionRate
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRetailOPPhaseUsageRateTrend = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getRetailOPPhaseUsageRateTrend(psdCriteria: $psdCriteria) {
      data {
        planningYear
        phaseDetails {
          name
          value
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getSubCustomerNameFilter = gql`
  query getSubCustomerNameFilter($marketId: Int!, $customerId: String!) {
    getSubCustomerNameFilter(marketId: $marketId, customerId: $customerId) {
      data {
        marketId
        customerId
        customerName
        subCustomerName
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getRageClick = gql`
  query ($overviewMetricCriteria: OverviewMetricesRequestDTOInput!) {
    getRageClicks(overviewMetricCriteria: $overviewMetricCriteria) {
      data {
        noOfDeadClicks
        topRageClicks{
            pageName
            rageClickPercentage
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

const getDeadClicks = gql`
  query ($psdCriteria: PsdMetricsRequestDTOInput!) {
    getDeadClicks(psdCriteria: $psdCriteria) {
      data {
        noOfDeadClicksPerSession
        topDeadClicks{
          pageName
          deadClickPercentage
        }
      }
      errors
      responseCode
      responseMessage
    }
  }
`;

export {
  getRetailOpScoreCardMetrics,
  getActiveAndTotalUsersChart,
  getAdoptionRateTrend,
  getAdoptionLoginTrend,
  getAdoptionFrequencyTrend,
  getAdoptionScoreCard,
  getUBOverallActivity,
  getRetailOPEquipmentCompliance,
  getRetailOPJBPScoreCard,
  getRetailOPPhaseUsageRateTrend,
  getSubCustomerNameFilter,
  getRageClick,
  getDeadClicks
};
