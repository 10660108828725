import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  getActiveAndTotalUsersChart,
  getTopAPIRequest,
  getTopErrorAPI,
  getNRMPricingCoreWebVitals,
  getScenarios,
  getCalendarGenerations,
  getValuableActionsTakenChart,
  getTimeSpentByWorkFlow,
  getRGMAPPValueCreationScorecard,
} from '../graphql/nrmQueries';

export const useNrm = () => {
  const [activeAndTotalUsersChartData, setActiveAndTotalUsersChartData] =
    useState([]);

  const [topAPIRequestData, setTopAPIRequestData] = useState({});
  const [topErrorAPIData, setTopErrorAPIData] = useState({});
  const [nrmPricingCoreWebVitalsData, setNRMPricingCoreWebVitalsData] =
    useState(null);
  const [scenariosData, setScenariosData] = useState({});
  const [calendarGenerationsData, setCalendarGenerationsData] = useState({});
  const [valuableActionsTakenChartData, setValuableActionsTakenChartData] =
    useState({});
  const [timeSpentByWorkFlowData, setTimeSpentByWorkFlowData] = useState([]);
  const [
    rgmAPPValueCreationScorecardData,
    setRgmAPPValueCreationScorecardData,
  ] = useState({});

  const [
    getActiveAndTotalUsersChartData,
    { loading: activeAndTotalUsersChartLoading },
  ] = useLazyQuery(getActiveAndTotalUsersChart, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res) {
        const {
          getActiveAndTotalUsersChart: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setActiveAndTotalUsersChartData(data);
        } else {
          setActiveAndTotalUsersChartData([]);
        }
      }
    },
    onError: () => {
      setActiveAndTotalUsersChartData([]);
    },
  });

  const [getTopAPIRequestData, { loading: topAPIRequestDataLoading }] =
    useLazyQuery(getTopAPIRequest, {
      onCompleted: (res) => {
        if (res) {
          const {
            getTopAPIRequest: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setTopAPIRequestData(data);
          } else {
            setTopAPIRequestData({});
          }
        }
      },
      onError: (error) => {
        console.log('getTopAPIRequestData error : ', error);
        if (
          error.networkError &&
          error.networkError.statusCode >= 500 &&
          error.networkError.statusCode < 600
        ) {
          setTopErrorAPIData({});
        }
      },
    });

  const [getTopErrorAPIData, { loading: topErrorAPIDataLoading }] =
    useLazyQuery(getTopErrorAPI, {
      onCompleted: (res) => {
        if (res) {
          const {
            getTopErrorAPI: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setTopErrorAPIData(data);
          } else {
            setTopErrorAPIData({});
          }
        }
      },
    });

  const [
    getNRMPricingCoreWebVitalsData,
    { loading: nrmPricingCoreWebVitalsDataLoading },
  ] = useLazyQuery(getNRMPricingCoreWebVitals, {
    onCompleted: (res) => {
      if (res) {
        const {
          getNRMPricingCoreWebVitals: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setNRMPricingCoreWebVitalsData(data);
        } else {
          setNRMPricingCoreWebVitalsData({});
        }
      }
    },
  });

  const [getScenariosData, { loading: scenariosLoading }] = useLazyQuery(
    getScenarios,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getScenarios: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setScenariosData(data);
          } else {
            setScenariosData({});
          }
        }
      },
    }
  );

  const [getCalendarGenerationsData, { loading: calendarGenerationsLoading }] =
    useLazyQuery(getCalendarGenerations, {
      onCompleted: (res) => {
        if (res) {
          const {
            getCalendarGenerations: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setCalendarGenerationsData(data);
          } else {
            setCalendarGenerationsData({});
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    });

  function transformData(apiResponse) {
    const actionNames = new Set();
    apiResponse.actionTakenData.forEach(({ actions }) => {
      actions.forEach(({ actionName }) => {
        actionNames.add(actionName);
      });
    });

    const finalData = {
      totalActions: apiResponse.totalActions, // Add this line to include totalActions
      dateTime: apiResponse.actionTakenData.map(({ dateTime }) => dateTime),
      actionDetails: [],
    };

    actionNames.forEach((actionName) => {
      const percentageValues = [];
      apiResponse.actionTakenData.forEach(({ actions }) => {
        const actionDetail = actions.find(
          (detail) => detail.actionName === actionName
        );
        percentageValues.push(
          actionDetail ? actionDetail.percentageValue : null
        );
      });

      finalData.actionDetails.push({
        actionName: actionName,
        percentageValues: percentageValues,
      });
    });

    return finalData;
  }

  const [
    getValuableActionsTakenChartData,
    { loading: valuableActionsTakenChartLoading },
  ] = useLazyQuery(getValuableActionsTakenChart, {
    onCompleted: (res) => {
      if (res) {
        const {
          getValuableActionsTakenChart: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          const finalData = transformData(data);
          setValuableActionsTakenChartData(finalData);
        } else {
          setValuableActionsTakenChartData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError', err);
    },
  });

  const [getTimeSpentByWorkFlowData, { loading: timeSpentByWorkFlowLoading }] =
    useLazyQuery(getTimeSpentByWorkFlow, {
      onCompleted: (res) => {
        if (res) {
          const {
            getTimeSpentByWorkFlow: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setTimeSpentByWorkFlowData(data);
          } else {
            setTimeSpentByWorkFlowData([]);
          }
        }
      },
      onError: (err) => {
        console.log('onError', err);
      },
    });

  const [
    getRGMAPPValueCreationScorecardData,
    { loading: getRGMAPPValueCreationScorecardLoading },
  ] = useLazyQuery(getRGMAPPValueCreationScorecard, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getRGMAPPValueCreationScorecard: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setRgmAPPValueCreationScorecardData(data);
        } else {
          setRgmAPPValueCreationScorecardData({});
        }
      }
    },
  });

  return {
    getActiveAndTotalUsersChartData,
    activeAndTotalUsersChartData,
    activeAndTotalUsersChartLoading,
    getTopAPIRequestData,
    topAPIRequestData,
    topAPIRequestDataLoading,
    getTopErrorAPIData,
    topErrorAPIData,
    topErrorAPIDataLoading,
    getNRMPricingCoreWebVitalsData,
    nrmPricingCoreWebVitalsData,
    nrmPricingCoreWebVitalsDataLoading,
    getScenariosData,
    scenariosData,
    scenariosLoading,
    getCalendarGenerationsData,
    calendarGenerationsData,
    calendarGenerationsLoading,
    getValuableActionsTakenChartData,
    valuableActionsTakenChartData,
    valuableActionsTakenChartLoading,
    getTimeSpentByWorkFlowData,
    timeSpentByWorkFlowData,
    timeSpentByWorkFlowLoading,
    getRGMAPPValueCreationScorecardData,
    rgmAPPValueCreationScorecardData,
    getRGMAPPValueCreationScorecardLoading,
  };
};
