import styled from "styled-components";
import { Container } from "@mui/system";
import { color } from "../../theme/Color";

export const DrawerMainContainer = styled(Container)`
  background: ${({ themeMode }) =>
    themeMode === "light" ? color._F3F7FD : color._1d1d1d};
  color: ${({ themeMode }) =>
    themeMode === "light" ? color._616161 : color._efefef};
  padding: 0 !important;
  height: 100%;
  max-width: 600px !important;
  z-index: 999;
`;

export const DrawerHeaderContainer = styled(Container)`
  padding: 0 !important;
  min-height: 13%;
`;

export const KpiDetailContainer = styled(Container)`
  min-height: 86.5%;
  background: ${({ themeMode }) =>
    themeMode === "light" ? color._FBFCFE : color._272727};
  & .kpiSection {
    margin-bottom: 25px;
  }

  & .definitionSectionTitle {
    font-size: 24px;
    font-weight: 700;
    color: ${({ themeMode }) =>
      themeMode === "light" ? color._616161 : color._C0C0C0};
    margin: 20px 0px;
  }

  & .sectionName {
    font-size: 18px;
    color: ${({ themeMode }) =>
      themeMode === "light" ? color._616161 : color._C0C0C0};
    font-weight: 700;
  }

  & .kpiName {
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
  }

  & .kpiDefinition {
    font-size: 12px;
    font-weight: 400;
    margin-top: 3px;
  }

  & .kpiDivider {
    margin-top: 10px;
    border-width: 1px;
    background: ${({ themeMode }) =>
      themeMode === "light" ? color._B2CAEA : color._3a3a3a};
  }
`;
