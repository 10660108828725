import React, { useContext } from "react";
import ProgressBar from "../../components/CircularProgressbar/ProgressBar";
import { color } from "../../theme/Color";
import * as Styled from "./style";
import { helperFunctions } from "../../utils/helperFunctions";
import { Grid, Typography } from "@mui/material";
import { constants } from "../../constants/Constants";
import { ThemeContext } from "../../context/ThemeContext";
import Loader from "components/Loader/Loader";
interface Props {
  apdexScoreData: {
    chartTitleInPercentage?: number;
  };
  titleTextStyle?: boolean;
  apdexScoreLoading: boolean;
}

const ApdexScoreCard: React.FC<Props> = ({
  apdexScoreData,
  titleTextStyle,
  apdexScoreLoading
}) => {
  const { mode } = useContext(ThemeContext);
  const {
    getValueAbbreviationParser,
    getZeroValueForInvalidValue,
    getTextPostfix,
  } = helperFunctions();
  return (
     
        <Styled.ApdexScoreContainer
          data-testid="incident-monitoring-view-container"
          titleTextStyle={titleTextStyle}
          mode={mode}
        >
          <Grid
            container
            justifyContent="space-between"
            style={{ minHeight: "365px" }}
          >
            <Grid item xs={12}>
              <Typography className="apdexScoreHeader">
                {constants?.applicationPerformanceRedesigned?.apdexScore}
              </Typography>
            </Grid>
            {apdexScoreLoading ? (
        <Grid item xs={12}>
          <div style={{ margin: "10%" }}>
            <Loader />
          </div>
        </Grid>
      ) : (
            <Grid item xs={12} container justifyContent="center">
              <ProgressBar
                color={color._007EB5}
                value={
                  getZeroValueForInvalidValue(
                    apdexScoreData?.chartTitleInPercentage
                  ) * 100
                }
                valueText={getValueAbbreviationParser(
                  apdexScoreData?.chartTitleInPercentage
                )}
                infoLabel={""}
                textPostfix={getTextPostfix()}
                flag={true}
              />
            </Grid> 
          )}
          </Grid>
        </Styled.ApdexScoreContainer>
     
  );
};

export default ApdexScoreCard;
