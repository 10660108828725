import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import './App.css';
import AppOktaRouter from './routes/AppWithRouter';
import { FullstoryWrapper } from './components/FullStory/Fullstory';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { oktaLogin } from './utils/okta_auth';
import { createApolloClient } from './utils/apolloUtils';
import ThemeContextProvider from 'context/ThemeContext';
import { Provider } from 'react-redux';
import store from './store/store';
import { growthbook } from './utils/growthbook';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { GrowthbookWrapper } from './utils/GrowthbookWrapper';

const oktaAuth = new OktaAuth({
  issuer: window.REACT_APP_ISSUER,
  clientId: window.REACT_APP_CLIENT_ID,
  redirectUri: window.REACT_APP_REDIRECT_URI,
  postLogoutRedirectUri: window.REACT_APP_LOGOUT_URI,
  scopes: ['openid', 'profile', 'email', 'offline_access', 'pepapppsdashboard'],
  pkce: window.REACT_APP_PKCE,
});

function App() {
  const triggerLogin = async () => {
    oktaLogin(oktaAuth);
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(
      toRelativeUrl(originalUri || '/', window.location.origin)
    );
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      await triggerLogin();
    } else {
      // Optionally: Ask the user to trigger the login process during token autoRenew process
    }
  };

  useEffect(() => {
    const initGrowthBook = async () => {
      // Fetch any required data or configurations before initializing (e.g., access tokens)
      const accessToken = await oktaAuth.tokenManager.get('accessToken');
      if (!accessToken) {
        console.warn("No access token available. Ensure the user is authenticated.");
        return; // Optionally return early if authentication data is missing
      }
      // Initialize GrowthBook only after ensuring the token and any other necessary data is available
      try {
        await growthbook.init({ streaming: true });
        console.log("GrowthBook initialized successfully");
      } catch (error) {
        console.error("Error initializing GrowthBook:", error);
      }
      
    };
  
    initGrowthBook(); // Call the async function to initialize GrowthBook
  }, []);

  return (
    <Provider store={store}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <ApolloProvider client={createApolloClient(oktaAuth)}>
          <ThemeContextProvider>
            <FullstoryWrapper organization={window.REACT_APP_FULLSTORY_ORG}>
            <GrowthBookProvider growthbook={growthbook}>
              <GrowthbookWrapper>
              <AppOktaRouter />
              </GrowthbookWrapper>
              </GrowthBookProvider>
            </FullstoryWrapper>
          </ThemeContextProvider>
        </ApolloProvider>
      </Security>
    </Provider>
  );
}

export default App;
