import React, { useContext } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/system";
import * as Styled from "./style";
import { ThemeContext } from "../../context/ThemeContext";
import { color } from "../../theme/Color";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ isMobileView, incidentToggle, mode }) => ({
    background: mode === "light" ? color._FBFCFE : color._272727,
    border:mode==="light"? `1px solid ${color._D7E5F8}`:" ",
    borderRadius: "8px",
    height: "35px",
    color: "#EFEFEF",
    textTransform: "capitalize",
    width: isMobileView && incidentToggle ? "100%" : "auto",
    display: "flex",
    justifyContent: "space-between",
  })
);

const StyledToggleButton = styled(ToggleButton)(
  ({ isMobileView, incidentToggle,mode }) => ({
    color: mode === 'light' ? `${color._616161}` : `${color._efefef}`,
    textTransform: "capitalize",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: isMobileView && incidentToggle ? "center" : "left",
    flex: 1,
    "&.Mui-selected": {
      backgroundColor: "#0069D7",
      borderRadius: "0px !important",
      borderTopLeftRadius: "8px !important",
      borderTopRightRadius: "8px !important",
      borderBottomLeftRadius: "8px !important",
      borderBottomRightRadius: "8px !important",
      fontWeight: "700",
      "&:hover": {
        backgroundColor: "#0069D7", // Keep the same color as selected state
      },
    },
    "&:hover": {
      backgroundColor: "transparent", // Remove the background color on hover
    },
  })
);

const ToggleButtonContainer = ({
  value,
  onChange,
  names,
  label,
  isMobileView = false,
  incidentToggle = false,
}) => {
  const { mode } = useContext(ThemeContext);

  return (
    <Styled.ToggleButtonContainer data-testid="toggle-button-container">
      <StyledToggleButtonGroup
        value={value}
        exclusive
        onChange={onChange}
        aria-label={label}
        isMobileView={isMobileView}
        incidentToggle={incidentToggle}
        mode={mode}
      >
        {names?.map((name, index) => (
          <StyledToggleButton
            mode={mode}
            key={index}
            value={name}
            sx={{ textTransform: "none" }}
            isMobileView={isMobileView}
            incidentToggle={incidentToggle}
          >
            {name}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Styled.ToggleButtonContainer>
  );
};

export default ToggleButtonContainer;
