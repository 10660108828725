import React, { useContext, useEffect, useState } from 'react';
import CardContainer from 'components/redesign/CardContainer';
import KpiGrid from 'components/redesign/KpiGrid';
import KpiTile from 'components/redesign/KpiTile';
import { FilterContext } from 'components/FilterBar/FilterProvider';
import { ScoreCardItemData } from 'components/redesign/ScoreCard/ScoreCardItem/types';
import KpiDefinitionData from 'service/KpiDefinitionData';
import { useMetricsCriteria } from 'hooks/useMetricsCriteria';
import { useOperationalMetrics } from 'hooks/operational-metrics-hook';
import totalBaselineSpend from '../../../../v2/kpis/archlet/ArchletScorecard/totalBaselineSpend';
import valueOpportunityIdentified from '../../../../v2/kpis/archlet/ArchletScorecard/valueOpportunityIdentified';
import totalProjectsCreated from '../../../../v2/kpis/archlet/ArchletScorecard/totalProjectsCreated';
import totalHoursSaved from '../../../../v2/kpis/archlet/ArchletScorecard/totalHoursSaved';

interface ScorecardHOCProps {
  title: string;
  localFilterData?: any;
  slug?: string;
}
const ValueCreationScorecard: React.FC<ScorecardHOCProps> = ({
  localFilterData,
  title,
}) => {
  const [row1Data, setRow1Data] = useState<ScoreCardItemData[]>([]);
  const {
    archletValueCreationScorecard,
    getArchletValueCreationScorecard,
    getArchletValueCreationScorecardLoading,
  } = useOperationalMetrics();
  const { operationalMetricCriteria } = useMetricsCriteria();
  const { selectedProduct } = useContext(FilterContext);

  useEffect(() => {
    if (archletValueCreationScorecard) {
      const data = {
        getArchletScoreCard: archletValueCreationScorecard,
      };
      setRow1Data([
        totalBaselineSpend.handler(data),
        valueOpportunityIdentified.handler(data),
        totalProjectsCreated.handler(data),
        totalHoursSaved.handler(data),
      ]);
    }
  }, [archletValueCreationScorecard]);

  useEffect(() => {
    const procurementCriteria = { ...operationalMetricCriteria };
    (procurementCriteria as any).category =
      localFilterData?.selectedCategory?.value || null;
    (procurementCriteria as any).subCategory =
      localFilterData?.selectedSubCategory?.value || null;
    (procurementCriteria as any).owner =
      localFilterData?.selectedOwner?.value || null;
    getArchletValueCreationScorecard({
      variables: { procurementCriteria },
    });
  }, [
    getArchletValueCreationScorecard,
    operationalMetricCriteria,
    localFilterData,
  ]);

  return (
    <CardContainer
      data-testid="archlet-overview-card-redesign"
      isAccordion={false}
      title={title}
      showInfoIcon={true}
      dataDefinitions={{
        sections:
          KpiDefinitionData[selectedProduct?.productId]?.valueCreation
            ?.summary ?? [],
      }}
      isLoading={getArchletValueCreationScorecardLoading}
    >
      <KpiGrid showHDividerLastRow={false} columnsDesktop={4} columnsTablet={4}>
        {row1Data.map((item, index) => (
          <KpiTile key={index} data={item} />
        ))}
      </KpiGrid>
    </CardContainer>
  );
};

export default ValueCreationScorecard;
