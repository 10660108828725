import React from 'react';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import { KpiTileProps } from './types';
import {
  Container,
  Title,
  Value,
  SecondTitle,
  Subtitle,
  TagContainer,
  Label,
  Tag,
  ArrowIcon,
} from './style';

const KpiTile: React.FC<KpiTileProps> = ({ data }) => {
  const { title, secondTitle, value, tag, subTitle } = data;

  // Determine if the arrow direction should be reversed (default: false)
  const arrowReversed = tag?.arrowReversed ?? false;

  // Select the appropriate arrow component based on isPositiveValue and arrowReversed
  const ArrowComponent = tag?.isPositiveValue
    ? arrowReversed
      ? MdArrowDownward
      : MdArrowUpward
    : arrowReversed
      ? MdArrowUpward
      : MdArrowDownward;

  // Build the tag value with the arrow always next to the text
  const tagValue =
    (tag?.showArrow ?? true) ? (
      <span>
        {tag?.text}{' '}
        <ArrowIcon>
          <ArrowComponent />
        </ArrowIcon>
      </span>
    ) : (
      tag?.text
    );

  return (
    <Container data-testid="tile-container">
      <Value data-testid="tile-value">{value}</Value>
      <Title data-testid="tile-title">{title}</Title>
      {secondTitle && (
        <SecondTitle data-testid="tile-second-title">{secondTitle}</SecondTitle>
      )}
      {tag && (
        <TagContainer data-testid="tile-tag-container">
          <Tag
            className={tag.isPositiveValue ? 'positive' : 'negative'}
            data-testid="tile-tag"
          >
            {tagValue}
          </Tag>
          <Label data-testid="tile-tag-label">{tag.sideLabel}</Label>
        </TagContainer>
      )}
      {subTitle && <Subtitle data-testid="tile-subtitle">{subTitle}</Subtitle>}
    </Container>
  );
};

export default KpiTile;
