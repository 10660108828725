import styled from "styled-components";
import { color } from "../../theme/Color";


export const MultiBarChartContainer = styled.div`
  & .noDataFound {
    border: ${({ mode }) =>
      mode === "light"
        ? `1px solid ${color._D7E5F8}`
        : `1px solid rgba(255, 255, 255, 0.2)`};
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    margin: 5%;
  }
`;
