import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { theme } from '../theme/Theme';
import { FilterProvider } from '../components/FilterBar/FilterProvider';

const AppOktaRouter = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <FilterProvider>
          <Routes />
        </FilterProvider>
      </ThemeProvider>
    </Router>
  );
};

export default AppOktaRouter;
