import {
  formatValueWithMiddlewares,
  checkForNA,
  createKpiTileItem,
  removeTrailingZero,
  applySuffix,
  roundToTwoDecimalPlaces,
  replaceNegativeSign,
} from '../../../utils/helper';

export default {
  type: 'KpiTile',
  handler: (data) => {
    // Destructure directly from the response data.
    const { totalHoursSaved, totalHoursSavedPeriodComparison } =
      data.getArchletScoreCard;

    const formattedTotalHoursSaved = formatValueWithMiddlewares(
      totalHoursSaved,
      [checkForNA, removeTrailingZero, roundToTwoDecimalPlaces, applySuffix]
    );
    const formattedComparison = formatValueWithMiddlewares(
      totalHoursSavedPeriodComparison,
      [
        checkForNA,
        removeTrailingZero,
        roundToTwoDecimalPlaces,
        replaceNegativeSign,
        applySuffix,
      ]
    );

    return createKpiTileItem(
      'Total Hours Saved',
      `${formattedTotalHoursSaved} h`,
      {
        isPositiveValue: totalHoursSavedPeriodComparison >= 0,
        text: `${formattedComparison} h`,
        sideLabel: '',
        showArrow: totalHoursSavedPeriodComparison !== 0,
      }
    );
  },
};
