import { constants } from "../constants/Constants";

export const getRealTimeOnboardingConfigProps = (
  realTimeOnboardingConfigData
) => {
  const appPerfProps = {};
  const userAdoptionProps = {};
  realTimeOnboardingConfigData?.productDetails?.forEach((productDetail) => {
      productDetail?.sourceDetails?.forEach((sourceDetail) => {

        // Service id 1 : SNOW
        if (sourceDetail?.sourceId === 1) {
          sourceDetail?.methodDetails?.forEach((methodDetail) => {
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs
                .getIncidentMonitoringSlaAnalysis
            ) {
              appPerfProps.getIncidentMonitoringSlaAnalysis =
                methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs.getIncidentTrend
            ) {
              appPerfProps.getIncidentTrend = methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs
                .getIncidentMonitoringOpenAndClose
            ) {
              appPerfProps.getIncidentMonitoringOpenAndClose =
                methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs
                .getIncidentMonitoringPriorityLevelGraph
            ) {
              appPerfProps.getIncidentMonitoringPriorityLevelGraph =
                methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs
                .getIncidentMonitoringTableView
            ) {
              appPerfProps.getIncidentMonitoringTableView =
                methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs
                .getIncidentMonitoringOverview
            ) {
              appPerfProps.getIncidentMonitoringOverview = methodDetail?.status;
            }
 
          });
        } 
        
        // Service id 2 : AppD
        else if (sourceDetail?.sourceId === 2) {
          sourceDetail?.methodDetails?.forEach((methodDetail) => {
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs.getApdexScore
            ) {
              appPerfProps.getApdexScore = methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs
                .getTopSlowestAPI
            ) {
              appPerfProps.getTopSlowestAPI =
                methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs.getPageResponseTrend
            ) {
              appPerfProps.getPageResponseTrend = methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs.getErrorRateTrend
            ) {
              appPerfProps.getErrorRateTrend = methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs.getBrowserErrorTrend
            ) {
              appPerfProps.getBrowserErrorTrend = methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs.getAPIResponseTrend
            ) {
              appPerfProps.getAPIResponseTrend = methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs.getApiCallsTrend
            ) {
              appPerfProps.getApiCallsTrend = methodDetail?.status;
            }

            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs.getTopErrorAPI
            ) {
              appPerfProps.getTopErrorAPI = methodDetail?.status;
            }

            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.appPerformanceKPIs.getTopAPIRequest
            ) {
              appPerfProps.getTopAPIRequest = methodDetail?.status;
            }
          });
        }

          // Service id 3 : FS
        else if (sourceDetail?.sourceId === 3) {
          sourceDetail?.methodDetails?.forEach((methodDetail) => {
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.userAdoptionKPIs.getTopEntryPage
            ) {
              userAdoptionProps.getTopEntryPage = methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.userAdoptionKPIs.getRageClick
            ) {
              userAdoptionProps.getRageClick = methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.userAdoptionKPIs
                .getUserAdoptionScorecard
            ) {
              userAdoptionProps.getUserAdoptionScorecard =
                methodDetail?.status;
            }  
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.userAdoptionKPIs.getActiveAndTotalUsersChart
            ) {
              userAdoptionProps.getActiveAndTotalUsersChart = methodDetail?.status;
            }
            if (
              methodDetail?.methodName ===
              constants.dynamicKPIs.userAdoptionKPIs.getAdoptionRateTrend
            ) {
              userAdoptionProps.getAdoptionRateTrend = methodDetail?.status;
            }
          });
        }
      });
  });

  return { appPerfProps, userAdoptionProps };
};
