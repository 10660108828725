import React, { useContext } from 'react';
import { GlobalOverviewStyles as Styled } from './style';
import DefaultScorecards from 'containers/DefaultScorecards';
import { FilterContext } from 'components/FilterBar/FilterProvider';
import { OverviewService } from 'service/overviewService';
import ValueComparisonHOC from 'components/ValueComparisonHOC';

interface GlobalOverviewProps {
  /**
   * Optional prop to override (or supplement) the custom overview
   * from the registry. If provided, it takes precedence.
   */
  customOverview?: React.ReactElement;
}

const GlobalOverview: React.FC<GlobalOverviewProps> = ({ customOverview }) => {
  const { selectedProduct, selectedProgram } = useContext(FilterContext);
  const filters = {
    product: selectedProduct?.productId,
    program: selectedProgram?.programId,
  };

  // Check if default scorecards exist
  const defaultScoreCards = OverviewService.resolveScoreCards(filters);
  const defaultScoreCardsExist = defaultScoreCards.length > 0;

  // Get a possible custom overview from the service
  const CustomOverviewComponent =
    OverviewService.resolveCustomOverview(filters);

  // If neither default scorecards nor any custom overview exist, return null
  // (i.e., show nothing)
  if (!defaultScoreCardsExist && !customOverview && !CustomOverviewComponent) {
    return null;
  }

  return (
    <Styled.Container>
      {/* Render the default scorecards if they exist */}
      {defaultScoreCardsExist && <DefaultScorecards />}

      {/* If a customOverview prop is passed in, it takes precedence */}
      {customOverview && customOverview}

      {/* Otherwise, attempt to render the registry-based custom overview */}
      {!customOverview && CustomOverviewComponent && (
        <CustomOverviewComponent />
      )}

      {
        <ValueComparisonHOC
          key="value-comparison-hoc"
          slug="value-comparison"
          title={'Value Comparison'}
        />
      }
    </Styled.Container>
  );
};

export default GlobalOverview;
