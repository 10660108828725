import React, { useContext } from 'react';
import * as Styled from './style';
import { Grid } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { helperFunctions } from '../../utils/helperFunctions';
import { color } from 'theme/Color';
import { ThemeContext } from '../../context/ThemeContext';
import NoDataFound from '../../components/ErrorHandling/NoDataFound';
import useCustomMediaQuery from '../../utils/useCustomMediaQuery';
import { screenSizes } from '../../constants/Constants';

const MixLineBarChart = ({
  prioprityLevelCategoryChartData,
  width,
  height,
  legendBottom,
  legendItemGap,
  legendData,
  seriesData,
}) => {
  const { getNAForInvalidValue } = helperFunctions();
  const isTablet = useCustomMediaQuery(screenSizes.tablet);
  const chartData = prioprityLevelCategoryChartData;
  const { mode } = useContext(ThemeContext);
  const option = {
    tooltip: {
      show: 'true',
      valueFormatter: function (value) {
        return getNAForInvalidValue(value);
      },
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    toolbox: {},
    grid: {
      height: '45%',
      right: 10,
    },
    legend: {
      data: legendData,
      right: '2%',
      itemGap: legendItemGap,
      bottom: legendBottom,
      itemWidth: 15,
      itemHeight: 10,
      textStyle: {
        color: mode === 'light' ? color._616161 : color._efefef,
        fontSize: isTablet ? 13 : 10,
        fontFamily: 'Inter',
      },
    },
    xAxis: {
      type: 'category',
      data: chartData?.months,
      axisLine: {
        show: true,
        lineStyle: {
          color: mode === 'light' ? color._616161 : color._4E4E4E,
        },
      },
      axisLabel: {
        color: mode === 'light' ? color._616161 : color._efefef,
      },
      axisPointer: {
        type: 'shadow',
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: [
      {
        //interval: chartData?.Sum?.length < 5 ? 10 : null,
        type: 'value',
        axisLabel: {
          formatter: '{value}',
          color: mode === 'light' ? color._616161 : color._efefef,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: mode === 'light' ? color._616161 : color._4E4E4E,
          },
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: seriesData,
  };

  const style = {
    height: height,
    width: width,
  };

  return (
    <Styled.MultiBarChartContainer mode={mode}>
      {option.xAxis?.data?.length > 0 ? (
        <ReactEcharts option={option} style={style} />
      ) : (
        <Grid item xs={12} className="noDataFound">
          <NoDataFound />
        </Grid>
      )}
    </Styled.MultiBarChartContainer>
  );
};

export default MixLineBarChart;
