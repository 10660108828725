import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useMetricsCriteria } from 'hooks/useMetricsCriteria';
import { useApplicationPerformance } from 'hooks/application-performance-hook';
import { ThemeContext } from '../../context/ThemeContext';
import SummaryMetrics from './SummaryMetrics';
import * as Styled from './style';
import { constants } from 'constants/Constants';
import KpiDefinitionDrawer from 'components/KpiDefinitionDrawer/KpiDefinitionDrawer';
import KpiDefinitionData from 'service/KpiDefinitionData';
import { FilterContext } from 'components/FilterBar/FilterProvider';

const SummaryMetricsWrapper = () => {
  const { mode } = useContext(ThemeContext);
  const { selectedProduct } = useContext(FilterContext);
  const { operationalMetricCriteria } = useMetricsCriteria();

  const {
    nrmAppPerfScoreCardData,
    nrmAppPerformanceScoreCardLoading,
    getNRMAppPerformanceScoreCardData,
  } = useApplicationPerformance();

  useEffect(() => {
    const scoreCardParams = {
      ...operationalMetricCriteria,
    };
    getNRMAppPerformanceScoreCardData({
      variables: {
        applicationMetricCriteria: scoreCardParams,
      },
    });
  }, [getNRMAppPerformanceScoreCardData, operationalMetricCriteria]);

  return (
    <Styled.MainConfigContainer mode={mode}>
      <Typography className="header">
        {constants?.overview?.appPerformance}
        <KpiDefinitionDrawer
          title=""
          KpiDefinitionList={
            KpiDefinitionData[selectedProduct?.productId]
              ?.applicationPerformanceRedesigned?.scoreCard ?? []
          }
        />
      </Typography>
      <Grid className="card posRelative">
        <SummaryMetrics
          summaryData={nrmAppPerfScoreCardData}
          isLoading={nrmAppPerformanceScoreCardLoading}
          showInfoIcon={false}
          istopkpi={true}
        />
      </Grid>
    </Styled.MainConfigContainer>
  );
};

export default SummaryMetricsWrapper;
