import styled from "styled-components";
import { color } from "../../theme/Color";

export const ProgressBar = styled.div`
  position: relative;
  filter: ${({ mode }) =>
    mode === "light"
      ? `drop-shadow(2px 0 0 ${color._D7E5F8}) drop-shadow(-2px 0 0 ${color._D7E5F8}) drop-shadow(0 2px 0 ${color._D7E5F8}) drop-shadow(0 -2px 0 ${color._D7E5F8}) drop-shadow(1px 0 0 ${color._D7E5F8}) drop-shadow(-1px 0 0 ${color._D7E5F8}) drop-shadow(0 1px 0 ${color._D7E5F8}) drop-shadow(0 -1px 0 ${color._D7E5F8})`
      : `drop-shadow(2px 0 0 ${color._1d1d1d}) drop-shadow(-2px 0 0 ${color._1d1d1d}) drop-shadow(0 2px 0 ${color._1d1d1d}) drop-shadow(0 -2px 0 ${color._272727}) drop-shadow(1px 0 0 ${color._272727}) drop-shadow(-1px 0 0 ${color._272727}) drop-shadow(0 1px 0 ${color._272727}) drop-shadow(0 -1px 0 ${color._272727})`};
`;

export const ProgressBarContainer = styled.div`
  margin-left: ${(props) => props.flag ? "0px" : "20px"};
  margin-top: 0px;
  position: relative;
  width: 130px;
  height: 130px;
  line-height: 1;
  @media ((min-width: 768px) and (max-width: 1024px)) {
    width: 140px;
    margin: -10px auto 0px;
  }
  @media (max-width: 768px) {
    width: 140px;
    margin: -10px auto 0px;
  }
`;

export const H1 = styled.h1`
  font-size: 11px;
  color: ${({ mode }) => (mode === "light" ? color._616161 : color._d0d0d0)};
  padding-bottom: 10px;
  margin-right: 10px;
  margin-top: -10px;
  margin-left: -11px;
  text-align: left;
  width: 150px;
  text-align: center;

  @media (max-width: 768px) {
    margin: -20px auto 0;
    font-size: 10px;
    text-align: center;
  }
`;

export const ValueText = styled.h2`
  position: absolute;
  top: 35%;
  font-size: 32px;
  line-height: 140%;
  font-family: Inter;
  display: flex;
  color: ${({ mode }) =>
    mode === "light" ? `${color._616161}` : `${color._efefef}`};
  transform: translate(-50%, -50%);
  left: 50%;

  & .textPostFixTypography {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 3;
  }
`;

export const ValueSubText = styled.h2`
  position: absolute;
  top: 44%;
  font-size: 14px;
  line-height: 140%;
  font-family: Inter;
  font-weight: 400;
  display: flex;
  color: ${({ mode }) => (mode === "light" ? color._616161 : color._efefef)};
  transform: translate(-50%, 80%);
  left: 50%;
`;