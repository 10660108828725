import styled from "styled-components";
import { Container, Grid } from "@mui/material";
import { color } from "../../theme/Color";

export const MainContainer = styled(Container)`
  width: 100%;
  padding-bottom: "-20px";
  background: ${({ mode }) =>
    mode === "light"
      ? `${color._FBFCFE} !important`
      : `${color._1d1d1d} !important`};
  border-radius: 8px;
  border: ${({ mode, isAppPerform }) =>
    mode === "light" && isAppPerform ? `1px solid ${color._D7E5F8}` : " "};
`;

export const InnerContainer = styled(Grid)`
  justify-content: space-between;
  align-items: "center";
  padding-bottom: ${props => props.paddingBottomInnerContainer};
  background: ${({ mode }) => mode === 'light' ? `${color._FBFCFE}` : `${color._1d1d1d}`};
  height: 20%;
  color: ${({ mode }) => mode === 'light' ? `${color._616161}` : `${color._ffffff} `};;;
  & .verticalBarContainer {
    border-width: 1px;
    background: #666666;
  }

  @media (max-width: 768px) {
    align-items: "left";
    justify-content: "left" !important;
  }
`;
