import styled from 'styled-components';

export const StyledCard = styled.div<{
  withBorder: boolean;
  withPadding: boolean;
  height: any;
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  height: ${(props) => (props.height ? props.height : '100%')};
  box-sizing: border-box; // Ensure height includes padding and border
  border: ${(props) =>
    props.withBorder ? `1px solid ${props.theme?.colors?.border}` : 'none'};
  padding: ${({ withPadding }) => (withPadding ? '24px' : '0')};
  width: 100%;
`;

export const Header = styled.h2`
  ${(props) => props.theme?.mergedTypography?.fontBodyBold};
  color: ${(props) => props.theme?.colors?.textSecondary};
  margin: 0;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;
`;
