import React, { useContext } from "react";
import * as Styled from "./style";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { color as themeColor } from "../../theme/Color";
import { Stack, Typography } from "@mui/material";
import { ThemeContext } from "../../context/ThemeContext";

const ProgressBar = (props) => {
  const {
    color,
    valueText,
    value,
    infoLabel,
    flag,
    textPostfix,
    valueSubText,
  } = props;
  const { mode } = useContext(ThemeContext);

  return (
    <Styled.ProgressBarContainer
      flag={flag}
      accessible={true}
      testID={"circular_progress_bar"}
      accessibilityLabel={"circular_progress_bar"}
      mode={mode}
    >
      <Styled.ProgressBar
        flag={flag}
        data-accessible={true}
        data-testid="circular_progress_bar_progressBar"
        mode={mode}
      >
        <CircularProgressbar
          styles={buildStyles({
            rotation: 0.67,
            strokeLinecap: "round",
            pathColor: color,
            textColor:
              mode === "light" ? themeColor._B2CAEA : themeColor._d0d0d0,
            textSize: "16px",
            trailColor:
              mode === "light" ? themeColor._B2CAEA : themeColor._272727,
          })}
          value={value}
          strokeWidth={10}
          circleRatio={0.66}
          backgroundPadding={5}
          data-accessible={true}
          data-testid="circular_progress_bar_circularprogressBar"
        />
      </Styled.ProgressBar>
      <Stack sx={{ display: "flex", flexDirection: "row" }}>
        <Styled.ValueText
          flag={flag}
          data-accessible={true}
          data-testid="circular_progress_bar_ValueText"
          sx={{ display: "flex", flexDirection: "row" }}
          mode={mode}
        >
          {valueText}
          <Typography className="textPostFixTypography">
            {textPostfix}
          </Typography>
        </Styled.ValueText>
        {valueSubText !== "NA" && (
          <Styled.ValueSubText
            mode={mode}
            flag={flag}
            data-accessible={true}
            data-testid="circular_progress_bar_SubText"
            sx={{ display: "flex", flexDirection: "row" }}
          >
            {valueSubText}
          </Styled.ValueSubText>
        )}
      </Stack>
      <Styled.H1
        mode={mode}
        data-accessible={true}
        data-testid="circular_progress_bar_H1"
      >
        {infoLabel}
      </Styled.H1>
    </Styled.ProgressBarContainer>
  );
};

export default ProgressBar;
