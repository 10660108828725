import React, { useContext, useEffect, useState } from 'react';
import { helperFunctions } from 'utils/helperFunctions';
import CardContainer from '../../../../components/redesign/CardContainer';
import { ScoreCardItemData } from '../../../../components/redesign/ScoreCard/ScoreCardItem/types';
import KpiGrid from '../../../../components/redesign/KpiGrid';
import KpiDefinitionData from 'service/KpiDefinitionData';
import { FilterContext } from 'components/FilterBar/FilterProvider';
import { useNrm } from 'hooks/nrm-hook';
import { useMetricsCriteria } from 'hooks/useMetricsCriteria';
import { constants } from 'constants/Constants';
import KpiTile from 'components/redesign/KpiTile';

const ScoreCard: React.FC = () => {
  const { getValueAbbreviationParser, getNAForPercentageValue } =
    helperFunctions();
  const [overviewConfig, setOverviewConfig] = useState<ScoreCardItemData[]>([]);
  const { selectedProduct } = useContext(FilterContext);
  const { operationalMetricCriteria } = useMetricsCriteria();
  const {
    getRGMAPPValueCreationScorecardData,
    rgmAPPValueCreationScorecardData,
    getRGMAPPValueCreationScorecardLoading,
  } = useNrm();

  const generateScoreCardItems = (data: any) => {
    return [
      {
        title: constants?.operationalMetrics?.promotionsSaved,
        value: getValueAbbreviationParser(data?.promotionsSaved),
        unit: '',
        valueSuffix: null,
        tag:
          data?.promotionsSavedDelta !== null
            ? {
                isPositiveValue: parseInt(data?.promotionsSavedDelta) >= 0,
                text: getNAForPercentageValue(data?.promotionsSavedDelta),
                sideLabel: '',
              }
            : null,
        checkPositive: true,
      },
      {
        title: constants?.operationalMetrics?.scenariosEdited,
        value: getValueAbbreviationParser(data?.scenariosEdited),
        unit: '',
        valueSuffix: null,
        tag:
          data?.scenariosEditedDelta !== null
            ? {
                isPositiveValue: parseInt(data?.scenariosEditedDelta) >= 0,
                text: getNAForPercentageValue(data?.scenariosEditedDelta),
                sideLabel: '',
              }
            : null,
        checkPositive: true,
      },
    ];
  };

  useEffect(() => {
    const psdCriteria = { ...operationalMetricCriteria };
    getRGMAPPValueCreationScorecardData({
      variables: { psdCriteria },
    });
  }, [getRGMAPPValueCreationScorecardData, operationalMetricCriteria]);

  useEffect(() => {
    if (rgmAPPValueCreationScorecardData) {
      setOverviewConfig(
        generateScoreCardItems({ ...rgmAPPValueCreationScorecardData })
      );
    }
  }, [rgmAPPValueCreationScorecardData]);

  return (
    <CardContainer
      title={'Summary'}
      showInfoIcon={true}
      dataDefinitions={{
        sections:
          KpiDefinitionData[selectedProduct?.productId]?.valueCreation
            ?.summary ?? [],
      }}
      isAccordion={false}
      isLoading={getRGMAPPValueCreationScorecardLoading}
    >
      <KpiGrid columnsDesktop={2} columnsTablet={2} columnsMobile={2}>
        {overviewConfig.map((item, index) => (
          <KpiTile key={index} data={item} />
        ))}
      </KpiGrid>
    </CardContainer>
  );
};

export default ScoreCard;
