import styled from "styled-components";
import { Typography } from "@mui/material";
import { color } from "../../theme/Color";

export const NumberContainer = styled(Typography)`
  font-size: ${(props) =>
    props.isFullStory ? "32px !important" : "24px !important"};
  font-family: "Inter !important";
  font-style: normal !important;
  font-weight: 700 !important;
  color: ${({ mode }) => (mode === "light" ? color._616161 : color._ffffff)};

  @media (max-width: 600px) {
    font-size: 24px !important;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    font-size: 24px !important;
  }

  & span {
    font-family: "Inter";
    font-weight: 700;
    color: ${({ mode }) => (mode === "light" ? color._616161 : color._ffffff)};
  }

  &.css-ag7rrr-MuiTypography-root {
    font-weight: 700;
    font-family: "Inter";
  }

  & .abbreviation {
    font-size: 24px;
    color: ${({ mode }) =>
      mode === "light"
        ? `${color._616161} !important`
        : `${color._ffffff} !important`};
  }
`;

export const DescriptionContainer = styled(Typography)`
  font-size: ${(props) => (props.isMCT ? "12px" : "10px")};
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  color: ${({ mode }) => (mode === "light" ? color._616161 : color._C0C0C0)};
  text-transform: uppercase;
`;

export const SubTextContainer = styled(Typography)`
  display: block;
  font-size: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  color: ${({ mode }) => (mode === "light" ? color._616161 : color._C0C0C0)};
  text-transform: uppercase;
`;
