import React, { useContext } from 'react';
import { Typography, Stack } from '@mui/material';
import * as Styled from './style';
import MixLineOverlapBarChart from '../MixLineOverlapBarChart';
import { constants, screenSizes } from '../../constants/Constants';
import { color as themeColor } from '../../../src/theme/Color';
import { helperFunctions } from '../../utils/helperFunctions';
import useCustomMediaQuery from '../../utils/useCustomMediaQuery';
import { ThemeContext } from '../../context/ThemeContext';
import { FilterContext } from '../FilterBar/FilterProvider';

const IncidentTrend = ({
  incidentTrendData,
  isAppPerform,
  selectedFrequency,
}) => {
  const { selectedProgram } = useContext(FilterContext);
  const { mode } = useContext(ThemeContext);
  const { getMonthFromDate, getDateAndMonth } = helperFunctions();

  const isMobile = useCustomMediaQuery(screenSizes.mobile);
  const isTablet = useCustomMediaQuery(screenSizes.tablet);

  const incidentTrendChartData = incidentTrendData;

  const legend = {
    right: getRightGap(),
  };

  function getRightGap() {
    if (isMobile) {
      return '3%';
    } else if (isTablet) {
      return '8%';
    } else {
      return '9%';
    }
  }

  function getItemGap() {
    if (isMobile) {
      return 5;
    } else if (isTablet) {
      return 10;
    } else {
      return 15;
    }
  }

  // Transform the data in the format required by graph to render
  const dates = [];
  const resolvedWithInSla = [];
  const incidentCount = [];
  const responseWithInSla = [];

  function createDataList(chartData) {
    const arr = Array.from(chartData || '');

    arr.forEach((data) => {
      if (
        selectedProgram?.programId === constants.programs.DTC &&
        selectedFrequency?.value === 28
      ) {
        dates.push(getDateAndMonth(data.dateTime));
      } else {
        dates.push(getMonthFromDate(data.dateTime));
      }
      resolvedWithInSla.push(data.resolvedWithInSla);
      incidentCount.push(data.incidentCount);
      responseWithInSla.push(data.responseWithInSla);
    });

    return {
      dates: dates,
      slaResolution: resolvedWithInSla,
      countOpenClose: incidentCount,
      slaRespond: responseWithInSla,
      legendTop: isMobile ? '87.5%' : '90%',
      slaResolutionColor: themeColor._007EB5,
      countOpenCloseColor: themeColor._824061,
      isMobileView: isMobile,
      isTabletView: isTablet,
      legend: legend,
      itemGap: getItemGap(),
    };
  }

  return (
    <Styled.MainContainer
      data-accessible={true}
      data-testid="incident-trend-main-container"
      mode={mode}
      isAppPerform={isAppPerform}
    >
      <Stack direction="row">
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            paddingBottom: '10px',
            paddingTop: '20px',
            color:
              mode === 'light'
                ? `${themeColor._616161}`
                : `${themeColor._efefef}`,
          }}
        >
          {constants.applicationPerformance.incidentTrend}
        </Typography>
      </Stack>
      <Stack>
        <MixLineOverlapBarChart data={createDataList(incidentTrendChartData)} />
      </Stack>
    </Styled.MainContainer>
  );
};

export default IncidentTrend;
