import styled from "styled-components";
import { color } from "../../theme/Color";
import { Container, Grid, Box, Typography } from "@mui/material";

export const RenderItemText = styled(Typography)`
  font-size: 10px !important;
  font-family: inter !important;
  font-weight: 700 !important;
  color:${({ mode }) => mode === 'light' ? `${color._616161} !important` : `${color._C0C0C0} !important`};
  text-transform: uppercase !important;
`;

export const MainContainer = styled(Container)`
  margin: 0 !important;
  width: 100%;
  background: ${({ mode }) => mode === 'light' ? `${color._FBFCFE} !important` : `${color._1d1d1d} !important`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: auto;
`;

export const InnerContainer = styled(Grid)`
  justify-content: space-between;
  align-items: "center";
  padding-bottom: 20px;
  background: ${({ mode }) => mode === 'light' ? `${color._FBFCFE} !important` : `${color._1d1d1d} !important`};
  color: ${({ mode }) => mode === 'light' ? `${color._616161} !important` : `${color._efefef}`};

  & .firstVerticalBarContainer {
    border-width: 1px;
    background: ${({ mode }) => mode === "light" ? color._B2CAEA : color._666666};
  }

  & .secondVerticalBarContainer {
    border-width: 1px;
    background: ${({ mode }) => mode === "light" ? color._B2CAEA : color._666666};
    @media (max-width: 600px) {
      display: none;
    }
  }

  & .header-items {
    @media (max-width: 600px) {
      text-align: center !important;
      padding-bottom: 20px !important;
    }
  }

  & .check2 {
    @media (max-width: 600px) {
      text-align: center !important;
    }
  }

  @media (max-width: 600px) {
    & .css-pf6zqi-MuiStack-root {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    & .css-dui8bx {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  width: 150px;
  flex-direction: row;
  text-align: center;
`;

export const Header = styled.div`
  background: ${color._272727};
  position: fixed;
  width: 100%;
  z-index: 2;
`;

export const H1 = styled.h1`
  height: 17px;
  font-size: 10px;
  width: 153px;
  color: ${color._efefef};
  padding-bottom: 5px;
  text-align: left;
  @media (max-width: 61px) {
    font-size: 10px;
    line-height: 1;
  }
`;

export const H2 = styled.h1`
  width: 60%;
  font-size: 12px;
  color: ${color._efefef};
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  border-radius: 4px;
  align-items: center;
  padding-right: 1px;
  padding-left: 3px;
  padding-top: 2px;
  padding-bottom: 4px;
  @media (max-width: 61px) {
    font-size: 10px;
    line-height: 1;
  }
`;

export const H3 = styled.h1`
  width: 50%;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  padding-left: 4px;
  color: ${color._a0a0a0};
  text-align: left;

  @media (max-width: 600px) {
    font-size: 15px !important;
    line-height: 1;
  }
`;

export const ValueText = styled(Typography)`
  font-size: 24px !important;
  font-family: inter !important;
  font-weight: 700 !important;
  color: ${({ mode }) => mode === 'light' ? `${color._616161} !important` : `${color._efefef}`};

  @media (max-width: 600px) {
    font-size: 24px !important;
    padding-left: 10px;
  }
`;

export const SubValueText = styled(Typography)`
  font-size: 18px !important;
  font-family: inter !important;
  font-weight: 700 !important;
  color: ${({ mode }) => mode === 'light' ? `${color._616161} !important` : `${color._efefef}`};
  padding-top: 6px;
`;

export const UnitText = styled(Typography)`
  font-size: 18px !important;
  font-family: inter !important;
  font-weight: 700 !important;
  color: ${({ mode }) => mode === 'light' ? `${color._616161} !important` : `${color._efefef}`};
  padding-top: 6px;
  padding-left: 5px;

  @media (max-width: 600px) {
    padding-top: 8px;
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    padding-top: 2px;
  }
`;
